import React from 'react';
import { Text, Image, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';
import CTAArea from '../../lib/CTAArea';
import Picture from '../../lib/Picture';

const PartnerHeroWithCTAComponent = ({ sitecoreContext, fields, rendering }) => {
  const pricing = sitecoreContext.pricing.sitecore.route;
  if (!fields) {
    return null;
  }
  let basePrice = pricing.fields.BasePrice;
  let perEmployeePrice = pricing.fields.PerEmployeePrice;
  let pricePer = pricing.fields.PricePer;
  let termsLink = pricing.fields.StandardTermsLink;
  if (fields.CustomPricingItem) {
    basePrice = fields.CustomPricingItem.fields.BasePrice;
    perEmployeePrice = fields.CustomPricingItem.fields.PerEmployeePrice;
    pricePer = fields.CustomPricingItem.fields.PricePer;
    termsLink = fields.CustomPricingItem.fields.CustomTermsLink;
  }

  const currencyValue = sitecoreContext.pricing.sitecore.route.fields.Currency?.value ?? '$';

  const pricingPromoBoxLayout =
    sitecoreContext.pricing.sitecore.route.fields.PricingPromoBoxLayout.value;
  let pricingPromoBoxLayoutContent;
  switch (pricingPromoBoxLayout) {
    case 'SingleCurrencyLayout':
      pricingPromoBoxLayoutContent = (
        <p className="pricing-splash mb-0">
          <strong className="h1">
            {currencyValue}
            <Text field={basePrice} />
          </strong>
          <br />
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PerEmployeeText} />
          &nbsp;/&nbsp;
          <Text field={pricePer} />
        </p>
      );
      break;
    default:
      pricingPromoBoxLayoutContent = (
        <p className="pricing-splash pt-10 mb-0">
          <strong className="h1">
            {currencyValue}
            <Text field={basePrice} />{' '}
          </strong>
          /<Text field={pricePer} />
          <br />+ {currencyValue}
          <Text field={perEmployeePrice} /> per employee
        </p>
      );
  }

  return (
    <>
      <style>{`@media (min-width: 768px) { #${rendering.componentName}-${rendering.uid} { background: url(${fields.Image.value.src}) top right 20% no-repeat;background-size:50%; } }`}</style>
      <Container
        fluid
        className="pt-0 pb-100 pb-xs-0 bg-purple-4300D5 sem-hero"
        data-component={rendering.componentName}
        data-component-uid={rendering.uid}
        id={`${rendering.componentName}-${rendering.uid}`}
        key="content"
      >
        <div className="bg-white visible-xs py-10 -mx-15px">
          {fields.LogoImage?.value?.src ? (
            <Picture className="width80 mx-auto" field={fields.LogoImage} loading="lazy" />
          ) : (
            <Link field={pricing.fields.RollLogo}>
              <Picture
                className="width80 mx-auto"
                field={sitecoreContext.pricing.sitecore.route.fields.RollLogo}
                loading="lazy"
              />
            </Link>
          )}
        </div>
        <Row as="section" className="hero-wrap center-block d-flex flex-wrap pt-40 pt-xs-0">
          <Col xs={12} sm={6} className="d-flex justify-content-center flex-column text-xs-center">
            {fields.LogoImage?.value?.src ? (
              <Picture className="w-90px hidden-xs" field={fields.LogoImage} loading="lazy" />
            ) : (
              <Link field={pricing.fields.RollLogo}>
                <Picture
                  className="w-90px hidden-xs"
                  field={sitecoreContext.pricing.sitecore.route.fields.RollLogo}
                  loading="lazy"
                />
              </Link>
            )}
            <h1 className="text-xs-center white">
              <RichText field={fields.Title} />
            </h1>
            <div className="lead mb-36 text-xs-center white">
              <RichText field={fields.SubTitle} />
            </div>
            <p className="mb-xs-40 px-xs-30">
              <CTAArea
                pricingData={sitecoreContext.pricing.sitecore.route.fields}
                fields={fields}
              />
            </p>
          </Col>
          <Col sm={6} xs={12} className="text-right mb-xs-0 px-xs-0 relative">
            <div className="card-box p-20 text-center mb-xs-80">
              <div className="mb-0">
                <RichText field={fields.PromoText} />
              </div>
              {pricingPromoBoxLayoutContent}
              {/* <p className="pricing-splash pt-10 mb-0">
                <strong className="h1">
                  {currencyValue}
                  <Text field={basePrice} />{' '}
                </strong>
                /<Text field={pricePer} />
                <br />+ {currencyValue}
                <Text field={perEmployeePrice} /> per employee
              </p> */}

              <small className="small__terms">
                <Link className="popup-offer terms white" field={termsLink} />
              </small>
            </div>
          </Col>
          <div className="-mx-15px">
            <Picture
              className="img-responsive width510 visible-xs no-lazy"
              field={fields.MobileImage}
              loading="lazy"
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withSitecoreContext()(PartnerHeroWithCTAComponent);
