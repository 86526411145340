import React from 'react';
import { Text, RichText, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Container } from 'react-bootstrap';
import appStoreLogo from '../../assets/images/ui/icon-apple-app-store.svg';
import playStoreLogo from '../../assets/images/ui/icon-google-play-store.svg';
import checkMarkBlue from '../../assets/images/ui/check_mark_blue.svg';
import checkMarkPurple from '../../assets/images/ui/check_mark_purple.svg';
import Schema from './schema/schema';
import HeroFormComponent from './components/Form';

const HomepageHeroWithFormComponent = ({ sitecoreContext, fields }) => {
  if (!fields) {
    return null;
  }

  const { BackgroundColor, Title, SubTitle, FormTitle, FormSubTitle } = fields;

  let bgColor = {};
  let whiteText = {};
  let currencyColor = {};
  let checkMark = {};

  if (BackgroundColor.value === 'Purple') {
    bgColor = {
      backgroundColor: '#4300D5',
    };
    whiteText = {
      color: '#ffffff',
    };
    currencyColor = {
      color: '#41D4FF',
    };
    checkMark = checkMarkBlue;
  } else {
    currencyColor = {
      color: '#4300D5',
    };
    checkMark = checkMarkPurple;
  }

  const currencyValue = sitecoreContext.pricing.sitecore.route.fields.Currency?.value ?? '$';

  return (
    <>
      <Schema sitecoreContext={sitecoreContext} fields={fields}></Schema>
      <style>{'.home {margin-top: 0;} .hero-wrap .hero-bg {right: -21%;}'}</style>
      <Container fluid className="pt-sm-50 pb-xs-0" style={bgColor}>
        <Row as="section" className="hero-wrap center-block pt-sm-40">
          <div
            className="hero-bg hidden-xs"
            style={
              BackgroundColor.value === 'Purple'
                ? {
                    filter:
                      'brightness(0) invert(9%) sepia(87%) saturate(1857%) hue-rotate(219deg) brightness(87%) contrast(98%)',
                  }
                : null
            }
          >
            &nbsp;
          </div>
          <Col
            xs={12}
            sm={6}
            className="col-xs-12 d-flex justify-content-center flex-column px-xs-0 pt-sm-20 pl-0"
            style={whiteText}
          >
            <Row className="center-block">
              <Col xs={12} sm={12}>
                <h1 className="text-xs-center">
                  <RichText field={Title}></RichText>
                </h1>
              </Col>
            </Row>
            <Row className="center-block">
              <Col xs={12} sm={12}>
                <div className="lead mb-40 text-xs-center">
                  <RichText field={SubTitle}></RichText>
                  <div
                    className="text-xs-left center-block ml-sm-30 mt-30 mb-50"
                    style={{ maxWidth: '250px' }}
                  >
                    <p>
                      <img alt="checkmark" src={checkMark} style={{ marginRight: '10px' }} />
                      No setup costs
                    </p>
                    <p>
                      <img alt="checkmark" src={checkMark} style={{ marginRight: '10px' }} />
                      No long-term contracts
                    </p>
                    <p>
                      <img alt="checkmark" src={checkMark} style={{ marginRight: '10px' }} />
                      No hidden fees
                    </p>
                  </div>
                  <h3>
                    Just&nbsp;
                    <span style={currencyColor}>
                      {currencyValue}
                      <Text
                        field={sitecoreContext.pricing.sitecore.route.fields.PerEmployeePrice}
                      />
                    </span>
                    <br className="visible-xs" />
                    <span className="hidden-xs">&nbsp;</span>per employee / mo.
                  </h3>
                </div>
                <div className="text-xs-center mb-xs-40">
                  {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
                    <>
                      <Link
                        target="_blank"
                        field={sitecoreContext.pricing.sitecore.route.fields.AppleAppStoreLink}
                      >
                        <img className="mr-10" alt="Apple App Store Logo" src={appStoreLogo} />
                      </Link>
                      <Link
                        target="_blank"
                        field={sitecoreContext.pricing.sitecore.route.fields.GoogleAppStoreLink}
                      >
                        <img alt="Google Play Store Logo" src={playStoreLogo} />
                      </Link>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <HeroFormComponent sitecoreContext={sitecoreContext} fields={fields}></HeroFormComponent>
        </Row>
      </Container>
      {/* TESTING */}
      <div style={{ marginBottom: '200px' }}>&nbsp;</div>
      {/* END TESTING */}
    </>
  );
};

export default withSitecoreContext()(HomepageHeroWithFormComponent);
