import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const BlogBreadcrumbs = (props) => {
  var category;
  const hostname = process.env.JSS_HOST_NAME ?? '';
  if (props.fields && props.fields.data && props.fields.data.item.categoryName) {
    // its a category page
    category = {
      categoryName: props.fields.data.item.categoryName.jss.value,
      url: props.fields.data.item.url,
    };
  } else if (props.fields && props.fields.data && props.fields.data.item.parent.categoryName) {
    category = {
      categoryName: props.fields.data.item.parent.categoryName.jss.value,
      url: props.fields.data.item.parent.url,
    };
  } else {
    category = {
      categoryName: 'not a category',
      url: 'props.fields.data.item.parent.url',
    };
  }
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Blog Home',
        item: hostname + category.url,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: category.categoryName,
      },
    ],
  };
  const schemaString = JSON.stringify(schema);

  // console.log(props, 'from crumbs');
  if (category.categoryName !== 'not a category') {
    return (
      <div className="breadcrumbs">
        <script
          key="schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: schemaString }}
        />
        <a href="/blog"> Blog Home </a>
        &gt;
        <a href={category.url} className="breadcrumbs__category" aria-label={category.categoryName}>
          {/* <a href="#!" disabled className="breadcrumbs__category">*/}
          {category.categoryName}
        </a>
      </div>
    );
  }
  return null;
};

export default withSitecoreContext()(BlogBreadcrumbs);
