import React from 'react';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './css/style.css';
import loadable from '@loadable/component';
import Picture from '../../lib/Picture';
const InitializeSlick = loadable(() => import('./client'), { ssr: false });

const TwoColumnCubeComponent = (props) => {
  if (!props.fields) {
    return null;
  }
  const alternateBg = props?.rendering.params?.UseAlternateBackground === '1' ? 'bg-white' : '';
  const alternateCardBg =
    props?.rendering.params?.UseAlternateBackground === '1' ? 'bg-grey-F7F6F4' : '';
  const useCarousel = props?.rendering?.params?.UseCarouselOnMobile === '1';
  const components = [];
  if (useCarousel) {
    components.push(<InitializeSlick key="initslick" />);
  }

  components.push(
    <Container
      fluid
      className={`pb-10 ${props.rendering.componentName} ${alternateBg}`}
      data-source-id={props.rendering.dataSource}
      data-source-title={props.rendering.componentName}
      key="content"
    >
      <Row as="section" className="center-block text-center">
        <div
          className={`center-block text-center d-flex flex-wrap ${useCarousel ? 'hidden-xs' : ''}`}
        >
          <Cards cards={props.fields.CardList} alternateCardBg={alternateCardBg} />
        </div>
        {useCarousel && (
          <div className="js-carousel-mb-cards visible-xs">
            <CarouselCards cards={props.fields.CardList} alternateCardBg={alternateCardBg} />
          </div>
        )}
      </Row>
    </Container>
  );

  return components;
};

const Cards = ({ cards, alternateCardBg }) => {
  return cards?.map((c) => {
    const addPadding = c.fields.Button?.value?.href !== '' ? '' : 'pb-sm-70';

    return (
      <Col xs={12} sm={6} className="col-xs-12 mb-40" key={c.id}>
        <div className={`card-box ${addPadding} ${alternateCardBg}`}>
          <Image field={c.fields.Image} loading="lazy" className="fixed-height-84" />
          {c.fields.Title?.value && (
            <h3 className="h4">
              <RichText field={c.fields.Title} />
            </h3>
          )}
          {c.fields.SubTitle?.value && (
            <div>
              <RichText field={c.fields.SubTitle} />
            </div>
          )}
          {c.fields.Button?.value?.href && (
            <div className="pb-md-50">
              <Link
                field={c.fields.Button}
                className="btn btn-default btn-bottom-card-absolute cta-click"
              />
            </div>
          )}
        </div>
      </Col>
    );
  });
};
const CarouselCards = ({ cards, alternateCardBg }) => {
  return cards?.map((c) => (
    <Col className="col-xs-12 col-sm-6 mb-sm-40" key={c.id}>
      <div
        className={`card-box p-xs-20 d-flex flex-column justify-content-center ${alternateCardBg}`}
      >
        <Image field={c.fields.Image} className="mx-auto" loading="lazy" />
        {c.fields.Title?.value && (
          <h3 className="h4">
            <RichText field={c.fields.Title} />
          </h3>
        )}
        {c.fields.SubTitle?.value && (
          <div>
            <RichText field={c.fields.SubTitle} />
          </div>
        )}
        {c.fields.Button?.value?.href && (
          <div className="pb-md-50">
            <Link
              field={c.fields.Button}
              className="btn btn-default btn-bottom-card-absolute cta-click"
            />
          </div>
        )}
      </div>
    </Col>
  ));
};

export default TwoColumnCubeComponent;
