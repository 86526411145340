import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row } from '../../../node_modules/react-bootstrap';
import { Col } from '../../../node_modules/react-bootstrap';
import { Container } from '../../../node_modules/react-bootstrap';

const HeroHomePage = ({ sitecoreContext, fields }) => {
  const {
    SchemaOrgName,
    SchemaOrgUrl,
    SchemaOrgLogo,
    SchemaOrgDescription,
    SchemaOrgSameAsFacebook,
    SchemaOrgSameAsTwitter,
    SchemaOrgSameAsInstagram,
    SchemaOrgSameAsYoutube,
    SchemaOrgAddressLocality,
    SchemaOrgAddressCountry,
    SchemaOrgPostalCode,
    SchemaOrgStreetAddress,
  } = sitecoreContext.pricing.sitecore.route.fields;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    '@id': SchemaOrgUrl?.value + '#corporation' || '',
    name: SchemaOrgName?.value || '',
    url: SchemaOrgUrl?.value || '',
    logo: SchemaOrgLogo?.value || '',
    description: SchemaOrgDescription?.value || '',
    address: {
      '@type': 'PostalAddress',
      addressLocality: SchemaOrgAddressLocality?.value || '',
      addressCountry: SchemaOrgAddressCountry?.value || '',
      postalCode: SchemaOrgPostalCode?.value || '',
      streetAddress: SchemaOrgStreetAddress?.value || '',
    },
    sameAs: [
      SchemaOrgSameAsFacebook?.value || '',
      SchemaOrgSameAsTwitter?.value || '',
      SchemaOrgSameAsInstagram?.value || '',
      SchemaOrgSameAsYoutube?.value || '',
    ],
  };

  const schemaString = JSON.stringify(schema);
  return (
    <Container fluid className="hero__container__fluid">
      <script
        key="schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: schemaString }}
      />
      <div className="hero">
        <Image className="heroImageLeft img-fluid float-start flex-end" media={fields.leftimage} />
        <Image className="heroImageRight img-fluid float-end" media={fields.rightimage} />
        <Image className="heroImagetop img-fluid" media={fields.mobileTopImage} />
        <Row className="heroHomePage">
          <Col className="heroContent">
            <div className="text-center heroHomePageHeader">
              <Text field={fields.heading} aria-label={fields.heading.value} />
            </div>
            <div className="text-center subHeading fs-6" fluid>
              <Text Decoder field={fields.subheading} aria-label={fields.subheading.value} />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default withSitecoreContext()(HeroHomePage);
