import React from 'react';

import { Image, Text, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { Container, Row, Col } from 'react-bootstrap';

const BlogCategoryHeader = (props) => {
  // console.log(fields);

  return (
    <section className="blog-category__header blog__roll">
      <Container className="blog__roll__breadcrumbs__container">
        <Row>
          <Col md={12} aria-label="Breadcrumbs">
            <Placeholder name="blogrollbyadp_breadcrumbs" rendering={props.rendering} />
          </Col>
        </Row>
      </Container>
      <Image
        field={props.sitecoreContext.route.fields.CategoryImage}
        className="header-image"
        alt="Header image"
      />
      <Container className="blog-category__header__content">
        <Row>
          <Col
            md={12}
            aria-label={props.sitecoreContext.route.fields.CategoryName.value}
            role="textbox"
          >
            <Text
              tag="h1"
              field={props.sitecoreContext.route.fields.CategoryName}
              aria-label={props.sitecoreContext.route.fields.CategoryName.value}
              role="textbox"
            />
          </Col>
        </Row>
      </Container>
      <Image
        field={props.sitecoreContext.route.fields.CategoryImageMobile}
        className="header-image-mobile"
        alt="Category Image"
      />
    </section>
  );
};

export default withSitecoreContext()(BlogCategoryHeader);
