import React from 'react';
import { withSitecoreContext, Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import PostBodyContent from './Components/PostBodyContentRegex';
import Tags from './Components/Tags';

const BlogPostDetail = ({
  props,
  sitecoreContext: {
    route: { fields },
  },
}) => {
  const postValue = fields.PostBody;
  const fullWidthPromoStart = postValue.value.indexOf('fullwidthpromo ');
  const fullWidthPromoEnd = postValue.value.indexOf('/fullwidthpromo');
  // console.log(fullWidthPromoStart, fullWidthPromoEnd);
  const fullWidthPromoStr = fields.PostBody.value.substring(fullWidthPromoStart, fullWidthPromoEnd);
  const FullWidthPromo2 = '<' + fullWidthPromoStr + '/fullwidthpromo>';
  const testingBody = fields.PostBody.value.replace(FullWidthPromo2, '');

  return (
    <section className="post__body">
      {/* <RichText field={fields.PostBody} /> */}
      <div fluid className="post__body__main__container">
        <PostBodyContent />
      </div>
      <Tags />
    </section>
  );
};

// const Tags = (props) => {
//   var tagcontents = '';

//   props.tags.map((tag, idx) => {
//     if (tagcontents.length > 0) {
//       tagcontents += ', ';
//     }
//     tagcontents += tag.fields.tagName.value;
//   });

//   return <div className="tags">{tagcontents}</div>;
// };

// withSitecoreContext() is the magical glue that gives you route-level context access
// see the context examples in the styleguide for more details.
export default withSitecoreContext()(BlogPostDetail);
