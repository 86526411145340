import { useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const InitializeUTMOverrideComponent = ({ sitecoreContext }) => {
  const replaceImages = (bestMatch) => {
    const imgs = document.querySelectorAll('img.qr-code, picture.qr-code img');
    if (imgs) {
      imgs.forEach((img) => {
        img.src = bestMatch.Image.value.src;
      });
    }
  };

  /**
   * Check if UTMSettings has more values sets than URL query parameters.
   *   Return false if UTMSettings more specific than URL query parameters.
   * @param UTMSetting
   * @returns {boolean}
   */
  const settingsCountAllowed = (UTMSetting) => {
    const searchParams = new URLSearchParams(window.location.search);
    const source = searchParams.get('utm_source');
    const medium = searchParams.get('utm_medium');
    const campaign = searchParams.get('utm_campaign');
    const term = searchParams.get('utm_term');
    const content = searchParams.get('utm_content');

    let paramsCount = 0;
    // Count params.
    source && paramsCount++;
    medium && paramsCount++;
    campaign && paramsCount++;
    term && paramsCount++;
    content && paramsCount++;

    const { fields } = UTMSetting;
    let utmSettingCount = 0;
    // Settings params.
    fields.utm_source.value && utmSettingCount++;
    fields.utm_medium.value && utmSettingCount++;
    fields.utm_campaign.value && utmSettingCount++;
    fields.utm_term.value && utmSettingCount++;
    fields.utm_content.value && utmSettingCount++;

    return utmSettingCount <= paramsCount;
  };

  useEffect(() => {
    let topScore = 0;
    let bestMatch = null;

    const searchParams = new URLSearchParams(window.location.search);
    const source = searchParams.get('utm_source');
    const medium = searchParams.get('utm_medium');
    const campaign = searchParams.get('utm_campaign');
    const term = searchParams.get('utm_term');
    const content = searchParams.get('utm_content');

    const sitecoreSettings = sitecoreContext.route.fields?.UTMSettings?.length
      ? sitecoreContext.route.fields?.UTMSettings
      : sitecoreContext.pricing.sitecore.route.fields.UTMSettings;
    window.sc = sitecoreContext;
    console.info(sitecoreSettings);

    // Check if we have UTM query params.
    if (source || medium || campaign || term || content) {
      // Loop through sitecoore settings & find match
      for (const index in sitecoreSettings) {
        if (sitecoreSettings.hasOwnProperty(index)) {
          const { fields } = sitecoreSettings[index];
          let score = 0;

          // Check if UTMSetting is more specific than URL query parameters
          if (settingsCountAllowed(sitecoreSettings[index])) {
            if (source && source === fields.utm_source.value) {
              score++;
            }
            if (medium && medium === fields.utm_medium.value) {
              score++;
            }
            if (campaign && campaign === fields.utm_campaign.value) {
              score++;
            }
            if (term && term === fields.utm_term.value) {
              score++;
            }
            if (content && content === fields.utm_content.value) {
              score++;
            }
          }

          if (score > topScore) {
            topScore = score;
            bestMatch = sitecoreSettings[index].fields;
          }
        }
      }

      // Check if UTM Setting override found.
      if (bestMatch) {
        // Replace Firebase link if has value.
        if (bestMatch.FirebaseLink.value.href) {
          const links = document.querySelectorAll('[data-label="start_free_trial"]');
          links.forEach((el) => {
            el.href = bestMatch.FirebaseLink.value.href;
          });
        }

        // Replace QR code if has value.
        if (bestMatch.Image.value.src) {
          // In page QR code.
          replaceImages(bestMatch);

          // For QR code in modal.
          document.addEventListener('modal-open', (e) => {
            replaceImages(bestMatch);
          });
        }
      }
    }
  });
  return null;
};
export default withSitecoreContext()(InitializeUTMOverrideComponent);
