import React, { Fragment, useEffect } from 'react';
import { Text, Image, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';

import blueStar from '../../assets/images/home-global/blue-star.png';
import Picture from '../../lib/Picture';

const InitializeSlick = loadable(() => import('./client'), { ssr: false });

const TestimonialsFilmStripComponent = ({ fields, rendering, sitecoreContext }) => {
  if (!fields) {
    return null;
  }

  let desktopMaxHeight;
  let mobileMaxHeight;

  fields.Testimonials.length <= 7 && fields.Testimonials.length > 3
    ? (desktopMaxHeight = '70rem')
    : fields.Testimonials.length <= 3
    ? (desktopMaxHeight = '50rem')
    : (desktopMaxHeight = '105rem');

  fields.Testimonials.length <= 7 && fields.Testimonials.length > 3
    ? (mobileMaxHeight = '110rem')
    : fields.Testimonials.length <= 3
    ? (mobileMaxHeight = '50rem')
    : fields.Testimonials.length >= 8 && fields.Testimonials.length <= 10
    ? (mobileMaxHeight = '133rem')
    : (mobileMaxHeight = '155rem');

  return [
    <InitializeSlick key="init-testimonials-client" />,
    <Fragment key="testimonials-film-strip-component">
      <style>
        {`
          .${rendering.componentName}-${rendering.uid} .card-box{
              padding:2rem;
              border-radius:1rem;
              width:21.66rem;
              height:auto;
            }
            
            .${rendering.componentName}-${rendering.uid} .testimonial-image{
               max-width:3rem;
            }
            
            .${rendering.componentName}-${rendering.uid} #testimonial-slider img{
              margin-bottom:0;
            }

            .${rendering.componentName}-${rendering.uid} .blue-star{
              max-width:1.5rem;
              margin:.65rem .15rem 0rem .15rem;

            }

            
            .${rendering.componentName}-${rendering.uid} .testimonial-column{
              margin:1rem auto;
              height:auto;
            }

            @media (min-width:768px){
              .${rendering.componentName}-${rendering.uid} .testimonial-column{
                width:50%;
              } 
            }
            
            @media (min-width:1082px){
              .${rendering.componentName}-${rendering.uid} .testimonial-column{
                width:33.3333%;
              } 
            }
            
            .${rendering.componentName}-${rendering.uid} .testimonial-columns{
              .${rendering.componentName}-${rendering.uid} .testimonial-columns{
                  margin:0 auto;
                  overflow:hidden;
                }
            }



            @media (min-width:768px){
            .${rendering.componentName}-${rendering.uid} .testimonial-columns{
              max-height:${mobileMaxHeight};
            }
          }    

          @media(min-width:1082px){
            .${rendering.componentName}-${rendering.uid} .testimonial-columns{
              max-height:${desktopMaxHeight};
            }
          }
          
          @media(min-width:1500px){
            .${rendering.componentName}-${rendering.uid} .testimonial-columns{
              max-height:90rem;
            }
          }
      `}
      </style>
      <Container
        fluid
        className={`${rendering.componentName}-${rendering.uid}`}
        data-component={rendering.componentName}
      >
        <Container className="text-center">
          <RichText tag="h3" field={fields.TestimonialHeader} />
        </Container>
        <div className="testimonial-columns mx-auto  d-xs-none d-sm-flex flex-column flex-wrap justify-content-center align-items-start">
          <Testimonials testimonials={fields.Testimonials} />
        </div>
        <Container
          className="mx-auto d-xs-block d-sm-none js-carousel-testimonials js-carousel"
          id="testimonial-slider"
        >
          <Testimonials testimonials={fields.Testimonials} />
        </Container>
      </Container>
    </Fragment>,
  ];
};

// if there is more than 1 testimonial, show a carousel. if there is no image, use blue quote image as default (see components sheet)
const Testimonials = ({ testimonials }) => {
  return testimonials?.map((t, k) => {
    const testimonialImg = t.fields.Image?.value?.src && t.fields.Image;

    return (
      <div key={k} className="testimonial-column mx-auto">
        <div className="card-box mx-auto">
          <Row>
            {testimonialImg && (
              <Col sm={3} className="col-xs-4">
                <Picture
                  className="mx-auto img-responsive testimonial-image"
                  field={testimonialImg}
                  loading="lazy"
                />
              </Col>
            )}
            <Col
              sm={testimonialImg ? 9 : 12}
              className={`text-left ${testimonialImg ? 'col-xs-8' : 'col-xs-12 pb-15'}`}
            >
              <img className="blue-star d-inline-block" src={blueStar} />
              <img className="blue-star d-inline-block" src={blueStar} />
              <img className="blue-star d-inline-block" src={blueStar} />
              <img className="blue-star d-inline-block" src={blueStar} />
              <img className="blue-star d-inline-block" src={blueStar} />
            </Col>
          </Row>
          <div className="text-left">
            <RichText tag="h4" className="my-10 pt-xs-5" field={t.fields.TestimonialShortText} />
            <div>
              <RichText field={t.fields.TestimonialLongText} />
              <br />
              <strong>
                <Text field={t.fields.Name} />
              </strong>
              <br />
              {t.fields.Role && ' '}
              {t.fields.Role && <Text field={t.fields.Role} />}{' '}
              <Text field={t.fields.CompanyName} />
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default withSitecoreContext()(TestimonialsFilmStripComponent);
