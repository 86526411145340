import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Col, Container, Row } from 'react-bootstrap';
import loadable from '@loadable/component';
const ClientSideSelect = loadable(() => import('./clientSide'), { ssr: false });

const CategoryNavigation = ({ fields }) => (
  <Container className="category-nav">
    <Row>
      <Col>
        <h6 className="title">
          <span className="hidden-xs">
            <Text field={fields.data.datasource.label.jss} /> :
          </span>
          <span className="visible-xs">
            <Text field={fields.data.datasource.mobileLabel.jss} /> :
          </span>
        </h6>

        <CatLinks links={fields.data.datasource.blogCategories.targetItems}></CatLinks>
        <ClientSideSelect
          defaultText={fields.data.datasource.defaultDropdownText?.jss?.value}
          links={fields.data.datasource.blogCategories.targetItems}
        ></ClientSideSelect>
      </Col>
    </Row>
  </Container>
);

const CatLinks = ({ links }) => {
  const listitems = links?.map((link, idx) => {
    return (
      <a key={idx} className="category-link" href={link?.url}>
        {link.categoryName?.jss?.value}
      </a>
    );
  });
  return listitems;
};

export default CategoryNavigation;
