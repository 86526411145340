import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';

const InitializeLottie = loadable(() => import(/* webpackPrefetch: true */ './client'), {
  ssr: false,
});

const LottieComponent = ({ fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const paddingParam = rendering?.params && rendering?.params?.Padding;
  const hideOnMobile = rendering?.params?.HideOnMobile ? 'd-xs-none' : '';
  const hideOnDesktop = rendering?.params?.HideOnDesktop ? 'd-md-none' : '';

  // console.log(hideOnMobile, hideOnDesktop, 'lottie');

  let spacingClasses;

  if (rendering.params) {
    switch (paddingParam) {
      case 'None':
        spacingClasses = 'pb-0 mb-0 ';
        break;
      case 'Small':
        spacingClasses = 'mb-60 ';
        break;
      case 'Medium':
        spacingClasses = 'pb-50 pt-xs-0 mx-auto';
        break;
      case 'Large':
        spacingClasses = 'center-block my-100 mb-xs-10 mx-auto';
        break;
      default:
        spacingClasses = 'container-fluid';
    }
  }

  return [
    <InitializeLottie key="init-lottie-client" />,
    <Row
      as="section"
      className={`center-block ${spacingClasses} ${hideOnMobile} ${hideOnDesktop}`}
      key="lottie"
      data-component={rendering.componentName}
    >
      <Col xs={12} className="col-xs-12 text-center">
        <RichText field={fields.LottieHtml} />
        <RichText className="lead mb-40 hidden-xs" field={fields.SubText} />
      </Col>
    </Row>,
  ];
};

export default LottieComponent;
