import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';

const Features = (props) => {
  return (
    <section>
      <Row className="center-block text-center">
        <Col xs={12} className="col-xs-12 center-block">
          <Col xs={8} className="col-xs-8 pb-xs-20 px-xs-0 center-block">
            <h2 className="">An effortless one-stop-shop for small business payroll</h2>
            <p className="lead mb-xs-0">
              Your business tools shouldnt add to the stress and chaos. They should help solve it.
              Roll does just that with payroll, and so much more.
            </p>
          </Col>
          <Col xs={12} className="col-xs-12 center-block d-flex flex-wrap">
            <Col
              xs={12}
              sm={3}
              className="col-xs-12 pb-xs-20 px-xs-0 px-sm-30 d-flex flex-column align-content-center"
            >
              <Link
                href="~/link.aspx?_id=E2B9DADA2BC7467CB1C2E9A2413C03B3&amp;_z=z#payroll-taxes"
                style="color: #121c4e !important; text-decoration: none !important"
              >
                <img
                  className="mx-auto cursor-pointer"
                  src="-/media/E58AAEC06FCE41BD95300E3708AFE82F.ashx"
                  alt=""
                  data-heightmissing=""
                  data-widthmissing=""
                />
                <p className="h4 mt-xs-0 cursor-pointer">
                  <strong>Payroll &amp; Tax Filing</strong>
                </p>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={3}
              className="col-xs-12 pb-xs-20 px-xs-0 px-sm-30 d-flex flex-column align-content-center"
            >
              <Link
                href="~/link.aspx?_id=E2B9DADA2BC7467CB1C2E9A2413C03B3&amp;_z=z#raises-bonuses"
                style="color: #121c4e !important; text-decoration: none !important"
              >
                <img
                  className="mx-auto cursor-pointer"
                  src="-/media/D16C40CA78F44C269F39B68B8BCA221E.ashx"
                  alt=""
                  data-heightmissing=""
                  data-widthmissing=""
                />
                <p className="h4 mt-xs-0 cursor-pointer">
                  <strong>Raises, Bonuses, &amp; Garnishments</strong>
                </p>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={3}
              className="col-xs-12 pb-xs-20 col-xs-12 col-sm-3 px-xs-0 px-sm-30 d-flex flex-column align-content-center"
            >
              <Link
                href="~/link.aspx?_id=E2B9DADA2BC7467CB1C2E9A2413C03B3&amp;_z=z#self-service"
                style="color: #121c4e !important; text-decoration: none !important"
              >
                <img
                  className="mx-auto cursor-pointer"
                  src="-/media/8B8CAAA9DC8243948EA4A3CF90E8DF54.ashx"
                  alt=""
                  data-heightmissing=""
                  data-widthmissing=""
                />
                <p className="h4 mt-xs-0 cursor-pointer">
                  <strong>Employee Self Service</strong>
                </p>
              </Link>
            </Col>
            <Col
              xs={12}
              sm={3}
              className="col-xs-12 pb-xs-20 px-xs-0 px-sm-30 d-flex flex-column align-content-center"
            >
              <Link
                href="~/link.aspx?_id=E2B9DADA2BC7467CB1C2E9A2413C03B3&amp;_z=z#intelligent-assistane"
                style="color: #121c4e !important; text-decoration: none !important"
              >
                <img
                  className="mx-auto cursor-pointer"
                  src="-/media/46E9B4A900EF4AC486B1F516F6D55C68.ashx"
                  alt=""
                  data-heightmissing=""
                  data-widthmissing=""
                />
                <p className="h4 mt-xs-0 cursor-pointer">
                  <strong>Intelligent Assistance</strong>
                </p>
              </Link>
            </Col>
          </Col>
        </Col>
        <Link
          href="#!"
          //   onclick="ctaClickEvent(this);"
          data-label="learn_more"
          data-section="home_features_blade"
          data-header="home_features"
          className="btn btn-default my-30 btn-redone"
        >
          LEARN MORE
        </Link>
      </Row>
    </section>
  );
};

export default Features;
