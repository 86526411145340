import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Tags = ({
  props,
  sitecoreContext: {
    route: { fields },
  },
}) => {
  const { Tags } = fields;

  var tagContents = '';

  Tags.map((tag, idx) => {
    if (tag.length > 0) {
      tagContents += ', ';
    } else {
      tagContents += ' • ' + tag.fields.tagName.value;
    }
  });
  // console.log('TAG CONTENTS', tagContents);

  return (
    <div className="tagsContainer" aria-label="Tags" role="combobox">
      <hr />
      <div className="tags">{tagContents.charAt(3).toUpperCase() + tagContents.slice(4)}</div>
    </div>
  );
};

export default withSitecoreContext()(Tags);
