import React from 'react';
import { Text, Image, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import CTAArea from '../../lib/CTAArea';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import appStoreLogo from '../../assets/images/ui/logo_app_store_mini.svg';
import playStoreLogo from '../../assets/images/ui/logo_google_play_mini.svg';
import Picture from '../../lib/Picture';

const BenefitCTAComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const pricing = sitecoreContext.pricing.sitecore.route;
  return (
    <Container fluid data-component={rendering.componentName}>
      <Row className="center-block mb-60">
        <Col xs={12} className="col-xs-12 text-center">
          <h1>
            <RichText field={fields.Title} />
          </h1>
          <RichText className="lead" field={fields.SubTitle} />
        </Col>
      </Row>
      <CTAItems items={fields.CTAItems} pricing={pricing} />
    </Container>
  );
};

const CTAItems = ({ items, pricing }) => {
  return items.map((item, idx) => {
    if (item.fields.CTAType?.value === 'Pricing') {
      return <PricingCTA key={idx} fields={item.fields} pricing={pricing} />;
    } else if (item.fields.CTAType?.value === 'Image') {
      return <ImageCTA key={idx} fields={item.fields} />;
    } else if (item.fields.CTAType?.value === 'Start For Free') {
      return <StartForFreeCTA key={idx} fields={item.fields} pricing={pricing} />;
    }
  });
};

const PricingCTA = ({ fields, pricing, sitecoreContext }) => {
  const currencyValue = pricing.fields.Currency?.value ?? '$';

  return (
    <Row className="center-block card-box px-xs-24 mb-40 d-flex flex-wrap">
      <CTAColumn1 fields={fields} />
      <CTAColumn2 fields={fields} />
      <CTAColumn3>
        <p className="pricing-splash pt-10 text-center">
          <strong className="h1 size80 pb-5">
            {currencyValue}
            <Text field={pricing.fields.BasePrice} />
            &nbsp;
          </strong>
          {'/'}
          <Text field={pricing.fields.PricePer} />
          <br />+ {currencyValue}
          <Text field={pricing.fields.PerEmployeePrice} /> per employee
        </p>
      </CTAColumn3>
    </Row>
  );
};

const StartForFreeCTA = ({ fields, pricing }) => {
  return (
    <Row className="center-block card-box px-xs-24 mb-40 d-flex flex-wrap">
      <CTAColumn1 fields={fields} />
      <CTAColumn2 fields={fields}>
        {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
          <ul className="app-links mt-0 mb-5 hidden-xs">
            <li>
              <img alt="" src={appStoreLogo} />
              <Link className={'apple-app-store-link'} field={pricing.fields.AppleAppStoreLink} />
            </li>
            <li>
              <img alt="" src={playStoreLogo} />
              <Link
                className={'android-app-store-link'}
                field={pricing.fields.GoogleAppStoreLink}
              />
            </li>
          </ul>
        )}
      </CTAColumn2>
      <CTAColumn3StartForFree>
        <CTAArea fields={fields} pricing={pricing} />
      </CTAColumn3StartForFree>
    </Row>
  );
};

const ImageCTA = ({ fields }) => {
  return (
    <Row className="center-block card-box px-xs-24 pr-0 mb-40 d-flex flex-wrap">
      <CTAColumn1 fields={fields} />
      <CTAColumn2 fields={fields} className={'benefits-list'} />
      <CTAColumn3>
        <Picture className="img-responsive card-img-business" field={fields.Image} loading="lazy" />
      </CTAColumn3>
    </Row>
  );
};

const CTAColumn1 = ({ fields }) => (
  <Col
    xs={12}
    sm={2}
    className="col-xs-12 px-xs-0 mb-xs-24 text-center d-flex flex-column justify-content-center"
  >
    <Picture field={fields.CTAIcon} className="mx-auto" loading="lazy" />
    <p className="lead mb-0">
      <strong>
        <Text field={fields.CTAIconText} className="lead mb-0" />
      </strong>
    </p>
  </Col>
);

const CTAColumn2 = ({ fields, children, className }) => {
  className = className ?? '';
  return (
    <Col
      xs={12}
      sm={7}
      className={`col-xs-12 px-xs-0 mb-xs-24 d-flex flex-column justify-content-center ${className}`}
    >
      <h4 className="mt-0 mb-5 mb-xs-10">
        <Text field={fields.Title} />
      </h4>
      <div className="mt-0 mb-5 lead">
        <RichText field={fields.SubTitle} />
      </div>
      {children}
    </Col>
  );
};

const CTAColumn3 = ({ children }) => (
  <Col
    xs={12}
    sm={3}
    className="col-xs-12 px-0 text-xs-center d-flex flex-column justify-content-center"
  >
    {children}
  </Col>
);
const CTAColumn3StartForFree = ({ children }) => (
  <Col
    xs={12}
    sm={3}
    className="col-xs-12 px-0 text-xs-center d-flex flex-column justify-content-center text-center"
  >
    {children}
  </Col>
);
export default withSitecoreContext()(BenefitCTAComponent);
