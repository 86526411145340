import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { Container, Row, Col, Button } from '../../../node_modules/react-bootstrap';
import loadable from '@loadable/component';

const LeadGenForm = loadable(() => import('../LeadGenForm'), { ssr: false });
const PromoFreeTrial = (props) => {
  let firstCol = props.fields.alignLeft ? 'promorightside' : 'promoleftside order-2';
  let secondCol = props.fields.alignLeft ? 'promoleftside' : 'promorightside order-1';

  return (
    <Container fluid className="promoFreeTrialContianer" aria-label="Free trial" role="combobox">
      <Row className="StartFreeTrial" aria-label="Free trial container" role="combobox">
        {/* <Col md={1}></Col> */}
        <Col className={firstCol} md={5}>
          <img
            src={props.fields.image.value.src}
            alt={props.fields.image.value.alt}
            className="promoimage2 img-fluid"
          />
        </Col>
        <Col className={secondCol} md={7}>
          <h4 className="headline2" aria-label={props.fields.headline.value}>
            <Text field={props.fields.headline} />
          </h4>
          <div className="button-area">
            <LeadGenForm buttonText={props.fields.buttonText.value} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PromoFreeTrial;
