import React from 'react';
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Button from 'react-bootstrap/esm/Button';
import ModalButton from '../components/LeadGenForm/components/ModalButton';
import loadable from '@loadable/component';
const InitializeLity = loadable(() => import('./InitLity'), { ssr: false });

const CTAArea = ({ pricingData, fields, suppressMargin, routeFields = null }) => {
  const elts = [];
  const buttonClass = fields.ButtonColor?.value?.toLowerCase() ?? 'navy';
  const isVideoButton = fields.IsVideoButton ? fields.IsVideoButton : fields.isVideoButton?.jss;
  const buttonText = fields.ButtonText ? fields.ButtonText : fields.buttonText;
  const buttonSubText = fields.ButtonSubText ? fields.ButtonSubText : fields.buttonSubText;
  const videoURL = fields.VideoURL ? fields.VideoURL : fields.videoURL?.jss;
  const usesStartForFreeForm = fields.UsesStartForFreeForm
    ? fields.UsesStartForFreeForm
    : fields.usesStartForFreeForm;
  const useGlobalPromoMessaging = fields.UseGlobalPromoMessaging
    ? fields.UseGlobalPromoMessaging
    : fields.useGlobalPromoMessaging;
  const buttonType = isVideoButton?.value ? 'video-btn' : '';
  const buttonLink = fields.ButtonLink ? fields.ButtonLink : fields.buttonLink?.jss;
  const defaultMobileStartForFreeUrl = 'https://qrco.de/bbo54b';
  const mobileStartForFreeField = fields.MobileStartForFree
    ? fields.MobileStartForFree
    : fields.mobileStartForFree?.jss;
  let mobileStartForFreeUrl = mobileStartForFreeField
    ? mobileStartForFreeField.value?.url
      ? mobileStartForFreeField.value?.url
      : defaultMobileStartForFreeUrl
    : defaultMobileStartForFreeUrl;
  let startForFree;
  if (usesStartForFreeForm?.value) {
    startForFree = 'free-trial-button';
  } else {
    startForFree = '';
  }

  // Page level override.
  if (routeFields?.FirebaseLink?.value?.length > 0) {
    mobileStartForFreeUrl = routeFields.FirebaseLink.value;
  }

  const hideButtonOnDesktopField = fields.HideButtonOnDesktop
    ? fields.HideButtonOnDesktop
    : fields.hideButtonOnDesktop?.jss;
  const hideButtonOnMobileField = fields.HideButtonOnMobile
    ? fields.HideButtonOnMobile
    : fields.hideButtonOnMobile?.jss;

  const hideButtonOnDesktopClass = hideButtonOnDesktopField?.value ? 'hidden-md hidden-sm' : '';
  const hideButtonOnMobileClass = hideButtonOnMobileField?.value ? 'hidden-xs' : '';

  // console.log(mobileStartForFreeField, mobileStartForFreeUrl);

  if (useGlobalPromoMessaging?.value) {
    if (useGlobalPromoMessaging.value === 'Standard Pricing Message' && !isVideoButton?.value) {
      elts.push(
        <p className={!suppressMargin ? 'mb-xs-10' : ''}>
          <ModalButton
            btnText={buttonText?.value}
            color="primary"
            size=""
            modalSource="free-trial-button"
            key="free-trial-modal-cta-area"
            className={hideButtonOnDesktopClass}
          />
          <a
            rel="noopener noreferrer"
            target="_blank"
            data-label="start_free_trial"
            className={
              'btn btn-primary visible-xs mobileStartForFreeUrl ' + hideButtonOnMobileClass
            }
            href={mobileStartForFreeUrl}
          >
            <Text field={buttonText} />
          </a>
        </p>
      );
    } else if (
      useGlobalPromoMessaging.value === 'Standard Pricing Message' &&
      isVideoButton?.value
    ) {
      const btn = buttonClass === 'pink' ? 'btn-primary' : 'btn-secondary';
      elts.push(<InitializeLity key="initialize-cta-area-client" />);
      elts.push(
        <Link field={videoURL} data-lity className={`btn ${btn}`} key="vid-url-and-btn">
          <span>
            <Text field={buttonText} />
            <span className="play-btn-icon"></span>
          </span>
        </Link>
      );
    }
  } else if (usesStartForFreeForm?.value) {
    elts.push(
      <p className={!suppressMargin ? 'mb-xs-10' : ''}>
        <Button
          className={buttonClass + ' ' + buttonType + ' hidden-xs ' + hideButtonOnDesktopClass}
          key="btn-text"
          data-modalsource={startForFree}
        >
          <Text field={buttonText} />
        </Button>
        <a
          rel="noopener noreferrer"
          target="_blank"
          data-label="start_free_trial"
          className={'btn btn-primary visible-xs ' + hideButtonOnMobileClass}
          href={mobileStartForFreeUrl}
        >
          <Text field={buttonText} />
        </a>
      </p>
    );
  } else if (isVideoButton?.value) {
    const btn = buttonClass === 'pink' ? 'btn-primary' : 'btn-secondary';
    elts.push(<InitializeLity key="initialize-cta-area-client" />);
    elts.push(
      <Link field={videoURL} data-lity className={`btn ${btn}`} key="vid-url-and-btn">
        <span>
          <Text field={buttonText} />
          <span className="play-btn-icon"></span>
        </span>
      </Link>
    );
  } else if (
    buttonLink &&
    ((buttonLink?.value.url && buttonLink?.value?.url !== '') ||
      (buttonLink?.value?.href && buttonLink?.value?.href !== ''))
  ) {
    elts.push(
      <Link field={buttonLink} className={'btn btn-primary ' + buttonClass + ' ' + buttonType}>
        <Text field={buttonText} />
      </Link>
    );
  }

  if (useGlobalPromoMessaging?.value) {
    if (useGlobalPromoMessaging.value === 'Standard Pricing Message') {
      elts.push(
        <p className="mt-10 my-xs-0" key="standard-pricing-msg">
          <RichText field={pricingData.StandardPricingPromoMessage} />
        </p>
      );
    } else if (useGlobalPromoMessaging.value === 'Alternative Pricing Message') {
      elts.push(
        <div key="alt-pricing-promo-msg">
          <RichText field={pricingData.AlternativePricingPromoMessage} />
        </div>
      );
    }
  }
  elts.push(
    <div className="cta__sub-text" key="cta-sub-text">
      <RichText field={buttonSubText}></RichText>
    </div>
  );

  return elts;
};

export default CTAArea;
