import React from 'react';

const ModalButton = (props) => {
  const { btnText, dataSection, dataLabel, size, color, modalSource } = props;
  let btnSize = size;
  let colr = color;

  if (btnSize !== undefined && btnSize !== null) {
    btnSize = btnSize.toLowerCase();

    switch (btnSize) {
      case 'sm':
        btnSize = 'btn-sm';
        break;
      case 'md':
        btnSize = 'btn-md';
        break;
      case 'lg':
        btnSize = 'btn-lg';
        break;
      default:
    }
  }

  if (colr) {
    colr = colr.toLowerCase();
    switch (colr) {
      case 'primary':
        colr = 'btn-primary';
        break;
      default:
    }
  }

  return (
    <>
      <button
        data-label={dataLabel}
        className={`btn ${colr} ${btnSize} trigger-modal trigger-info trialButton cta-click hidden-xs`}
        data-section={dataSection}
        data-modalsource={modalSource}
      >
        {btnText}
      </button>
    </>
  );
};

export default ModalButton;
