import React from 'react';
import { Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CTAArea from '../../lib/CTAArea';

const FAQsComponent = ({ sitecoreContext, fields, rendering }) => {
  const hideOnDesktop = rendering.params?.HideOnDesktop ? 'visible-xs' : '';
  if (!fields) {
    return null;
  }

  return [
    <style key="style">{`.${rendering.componentName} .${rendering.componentName}-${rendering.uid} .btn{color:#de0a65;background-color:#fce3df;border-color:#fce3df;transition: all .3s ease;} .${rendering.componentName} .${rendering.componentName}-${rendering.uid} .btn:hover{color: #de0a65;background-color: #fbd7d1;border-color: #fce3df;} .terms{text-decoration: underline;}`}</style>,
    <Row
      as="section"
      className={`center-block px-xs-30 ${hideOnDesktop} ${rendering.componentName}`}
      key="faqs"
      data-component={rendering.componentName}
      data-source-id={rendering.uid}
    >
      <Row className="center-block card-box mb-60 px-xs-0 py-xs-24 pb-xs-30 pb-xs-30">
        {fields.Title.value && (
          <Col xs={12} sm={4} className="col-xs-12 col-sm-6 text-xs-center">
            <h2 className="h4 mt-0">
              <RichText field={fields.Title} />
            </h2>
            {fields.SubTitle?.value && <RichText className="mb-20" field={fields.SubTitle} />}
          </Col>
        )}
        <Col xs={12} sm={8} className="col-xs-12 col-sm-8 px-xs-0">
          <dl className="faq-accordion accordion faq">
            <FAQs faqs={fields.FAQItems} bold={!!rendering.params?.HideOnDesktop} />
          </dl>
        </Col>

        {fields.ButtonText?.value && (
          <Col className={`col-xs-12 text-left pt-30 ${rendering.componentName}-${rendering.uid}`}>
            <CTAArea
              pricingData={sitecoreContext.pricing.sitecore.route.fields}
              fields={fields}
              style={{ alignItems: 'center' }}
            ></CTAArea>
          </Col>
        )}
      </Row>
    </Row>,
  ];
};

const FAQs = ({ faqs, bold }) => {
  return faqs?.map((t) => (
    <div key={t.id}>
      <dt className="lead mb-0">
        {bold && (
          <strong>
            <Text field={t.fields.Title} />
          </strong>
        )}
        {!bold && <Text field={t.fields.Title} />}
      </dt>
      <dd>
        <RichText field={t.fields.SubTitle} />
      </dd>
    </div>
  ));
};

export default withSitecoreContext()(FAQsComponent);
