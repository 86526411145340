import React from 'react';
import { Image, Link, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import CTAArea from '../../lib/CTAArea';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';
import Picture from '../../lib/Picture';
const InitializeLity = loadable(() => import('../../lib/InitLity'), { ssr: false });

const VideoComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields) {
    return null;
  }
  let spacingClasses = '';

  if (rendering.params) {
    if (rendering.params.Padding) {
      const paddingParam = rendering.params.Padding;

      if (rendering.params) {
        switch (paddingParam) {
          case 'None':
            spacingClasses = 'py-0';
            break;
          case 'Small':
            spacingClasses = 'pt-0';
            break;
          case 'Medium':
            spacingClasses = 'py-25';
            break;
          case 'Large':
            spacingClasses = 'py-50';
            break;
          default:
            spacingClasses = '';
        }
      }
    }
  }
  return [
    <InitializeLity key="init-video-client" />,
    <Container
      className={`${spacingClasses} mb-xs-0`}
      data-source-title={rendering.componentName}
      data-source-id={rendering.dataSource}
      data-uid={rendering.uid}
      fluid
      key="video-component"
    >
      <Row as="section" className={`center-block ${spacingClasses} mb-xs-20`}>
        {(fields.MobileText?.value !== '' || null) && (
          <Col className="col-xs-12 text-center visible-xs">
            <strong>
              <RichText className="lead mb-20 visible-xs" field={fields.MobileText} />
            </strong>
          </Col>
        )}
        <Col xs={12} className="col-xs-12 position-relative">
          <Link field={fields.VideoURL} aria-label="Play video" data-lity>
            <span className="video-play-btn"></span>
            <Picture
              className="mx-auto img-responsive hidden-xs"
              field={fields.BackgroundImage}
              loading="lazy"
            />
            <Picture
              className="mx-auto img-responsive visible-xs"
              field={fields.BackgroundImageMobile}
              loading="lazy"
            />
          </Link>
        </Col>
        {(fields.ButtonText.value !== '' || null) && (
          <Col xs={12} className="col-xs-12 text-center mt-48">
            <CTAArea pricingData={sitecoreContext.pricing.sitecore.route.fields} fields={fields} />
          </Col>
        )}
      </Row>
    </Container>,
  ];
};

export default withSitecoreContext()(VideoComponent);
