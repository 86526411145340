import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import loadable from '@loadable/component';

const ClientSideSocialSharing = loadable(() => import('./clientside'), { ssr: false });

const SocialSharing = ({
  props,
  sitecoreContext: {
    route: { fields },
  },
}) => <ClientSideSocialSharing fields={fields} />;
export default withSitecoreContext()(SocialSharing);
