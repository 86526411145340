import { useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import qrCodeLogo from '../../../assets/adp_logo.svg';

const InitAppsFlyerComponent = ({ sitecoreContext }) => {
  useEffect(() => {
    var initAppsTimer;

    const replaceThings = (result_url) => {
      const qrOptions = {
        logo:
          'https://www.adp.com/-/media/microsites/rollbyadp/images/home-global/roll--rounded-2.png?rev=70a80951618a4056957cd027a04e171b&hash=D5D32A6B22BF14A53810EC9D9163C864',
      };
      [
        ...document.querySelectorAll(
          'a.apple-app-store-link, a.android-app-store-link, a[data-label="start_free_trial"]'
        ),
      ].map((el) => el.setAttribute('href', result_url));

      // Optionally - Create QR code from the generated OneLink URL
      [...document.querySelectorAll('.qr-code-wrapper')].map((el) => {
        const id = Math.random().toString(36).substring(2, 8);
        el.setAttribute('id', id);
        window.AF_SMART_SCRIPT.displayQrCode(id, qrOptions);
      });
    };
    const initAppsFlyer = () => {
      if (window.AF_SMART_SCRIPT) {
        clearInterval(initAppsTimer);
        // Initializing Smart Script arguments
        var oneLinkURL = 'https://roll.onelink.me/a3ly';
        var mediaSource = {
          keys: ['utm_source'],
          overrideValues: { cj: 'cj_int' },
          defaultValue: 'direct',
        };
        var campaign = { keys: ['utm_campaign'] };
        var content = { paramKey: 'content', keys: ['utm_content'] };
        var medium = { paramKey: 'medium', keys: ['utm_medium'] };
        var clickid = { paramKey: 'clickid', keys: ['clickid'] };
        var custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };
        var custom_ss_gtm_ui = { paramKey: 'af_ss_gtm_ui', defaultValue: 'true' };

        window.AF_SMART_SCRIPT_RESULT = window.AF_SMART_SCRIPT.generateOneLinkURL({
          oneLinkURL: oneLinkURL,
          afParameters: {
            mediaSource: mediaSource,
            campaign: campaign,
            afCustom: [content, medium, clickid, custom_ss_ui, custom_ss_gtm_ui],
          },
        });
        window.AF_LOADED = true;
        console.log('AF LOADED');
        // See an example of Smart Script implementation via Google Tag Manager: https://appsflyersdk.github.io/appsflyer-onelink-smart-script/examples/google_tag_manager.html?my_source=email&app_dest=planes&typeid=b787&msg_id=f7h8

        var result_url = window.AF_SMART_SCRIPT_RESULT.clickURL;
        if (result_url) {
          replaceThings(result_url);

          document.addEventListener('modal-open', (e) => {
            replaceThings(result_url);
          });
        }
      }
    };

    initAppsTimer = setInterval(initAppsFlyer, 100);
  });
  return null;
};
export default withSitecoreContext()(InitAppsFlyerComponent);
