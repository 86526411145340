import React, { Fragment } from 'react';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CTAArea from '../../lib/CTAArea';
import loadable from '@loadable/component';
const InitializeListingComponent = loadable(() => import('./client'), { ssr: false });

const ListingComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const altBg = rendering?.params?.UseAlternateBackground === '1' ? true : false;
  const hideOnMobile = rendering.params?.HideOnMobile ? 'd-xs-none' : '';
  const hideOnDesktop = rendering.params?.HideOnDesktop ? 'd-md-none' : '';
  const useAltBg = rendering?.params?.UseAlternateBackground === '1' && 'bg-transparent';
  const useAltColSize = rendering?.params?.UseAlternateBackground === '1' ? 'col-sm-11' : '';
  const listPaddingTop = fields.IsCollapsible?.value === true ? 'pt-30' : '';

  return [
    <InitializeListingComponent key="initialize-listing-client" fields={fields} />,
    <Fragment key="listingComponent">
      {altBg === true && (
        <style>{`
      @media (max-width:767px){
        dd{
          margin-inline-start:0px !important;
        }
      }`}</style>
      )}
      {(fields.Title?.value || fields.SubTitle?.value) && (
        <Container className={` ${altBg === true ? 'pt-40' : ''}`}>
          <Col className="text-center mb-15 p-0">
            {fields.Title?.value && (
              <h2>
                <RichText field={fields.Title} />
              </h2>
            )}

            {fields.SubTitle?.value && (
              <RichText
                field={fields.SubTitle}
                className={`lead ${altBg === true && 'mb-xs-33'}`}
              />
            )}
          </Col>
        </Container>
      )}
      <Container
        fluid
        className={`${hideOnMobile} ${hideOnDesktop} ${
          rendering?.params?.UseAlternateBackground === '1' ? 'py-0' : ''
        } `}
        data-component={rendering.componentName}
        data-source-id={rendering.dataSource}
      >
        <Row as="section" className={`center-block card-box py-20 px-xs-0 px-60 ${useAltBg}`}>
          <Col className={`col-xs-12 ${useAltColSize} mx-auto center-block`}>
            {rendering?.params?.UseAlternateBackground === '1' && <hr className="blue my-0 py-0" />}
            <dl className="feature-accordion accordion">
              {fields?.IsCollapsible?.value && (
                <dt
                  className="pink-DE0A65 text-center lead my-0 open cta-click uppercase"
                  data-label="see_features_list"
                  data-section="full_features_list"
                  data-header="see_features_list"
                >
                  <ListingHeader fields={fields} /> <span className="arrow"></span>
                </dt>
              )}

              <dd
                className={`${listPaddingTop} pt-xs-0 ${
                  altBg === true && 'px-xs-0  mr-xs-0 mt-xs-5'
                } row`}
                style={{ display: 'block' }}
              >
                <Col xs={12} sm={5} className={`col-xs-12 pr-sm-0  ${altBg === true && 'px-xs-0'}`}>
                  {fields.LeftColumnListTitle?.value && (
                    <h2 className={`h4 ${fields.LeftColumnListContent?.value && 'hr'}`}>
                      <Text field={fields.LeftColumnListTitle} />
                    </h2>
                  )}
                  {fields.LeftColumnListContent?.value && (
                    <div>
                      <RichText field={fields.LeftColumnListContent} />
                    </div>
                  )}
                </Col>
                {rendering?.params?.UseAlternateBackground !== '1' && <Col sm={2} />}
                <Col
                  xs={12}
                  sm={rendering?.params?.UseAlternateBackground === '1' ? 7 : 5}
                  className={`col-xs-12 pr-sm-0 ${altBg === true && 'px-xs-0'}`}
                >
                  {fields.RightColumnListTitle?.value && (
                    <h2 className={`h4 ${fields.RightColumnListContent?.value && 'hr'}`}>
                      <Text field={fields.RightColumnListTitle} />
                    </h2>
                  )}
                  {fields.RightColumnListContent?.value && (
                    <RichText
                      className={`${
                        fields.RightColumnListContent?.value
                          ? `${altBg === true && 'pt-xs-0'} pt-25`
                          : ''
                      }`}
                      field={fields.RightColumnListContent}
                    />
                  )}
                </Col>

                {fields?.ButtonText?.value && (
                  <div className="col-xs-12 text-center pb-40 pt-30">
                    <CTAArea
                      pricingData={sitecoreContext.pricing.sitecore.route.fields}
                      fields={fields}
                    ></CTAArea>
                  </div>
                )}
              </dd>
            </dl>
          </Col>
        </Row>
      </Container>
    </Fragment>,
  ];
};

const ListingHeader = ({ fields }) => {
  if (!fields.IsCollapsible?.value) {
    return (
      <Fragment>
        <strong className="showOrClose">{fields.ShowListTitle.value}&nbsp;</strong>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <strong>{fields.HideListTitle.value}&nbsp;</strong>
      </Fragment>
    );
  }
};

export default withSitecoreContext()(ListingComponent);
