import React from 'react';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const TermsComponent = ({ rendering, sitecoreContext }) => {
  return (
    <div
      className="container-fluid p-20"
      id={'ADP_' + rendering.uid}
      data-source-title={rendering.renderingName}
    >
      <section className="row center-block max-w-970px terms-font">
        <p className="text-right">
          <a href="javascript:self.close();">
            <img
              src="/-/media/adp/evo/ui/icn-close-black.svg?rev=4b1a2908f2ec4f0c823668db9e5394a5&amp;hash=EAF557B068690AA6E613144F06D688C8"
              style={{ width: 19 + 'px', height: 19 + 'px' }}
              alt=""
            />
          </a>
        </p>
        <RichText field={sitecoreContext.route.fields.HtmlContent} />
      </section>
    </div>
  );
};

export default withSitecoreContext()(TermsComponent);
