import React, { useState } from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import loadable from '@loadable/component';
const InitializeLeadGenFormComponent = loadable(
  () => import(/* webpackPrefetch: true */ './client'),
  { ssr: false }
);
const ModalForm = loadable(() => import(/* webpackPrefetch: true */ './components/ModalForm'), {
  ssr: false,
});
const ModalFormInternational = loadable(
  () => import(/* webpackPrefetch: true */ './components/ModalFormInternational'),
  {
    ssr: false,
  }
);

const LeadGenForm = ({ sitecoreContext }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return [
    <InitializeLeadGenFormComponent
      handleClose={handleClose}
      setShow={setShow}
      lang={sitecoreContext.language}
      key="init-lead-gen-client"
    />,
    <>
      {sitecoreContext.language === 'en' && (
        <ModalForm
          show={show}
          handleClose={handleClose}
          key="modal"
          appName={sitecoreContext.site.name}
          QRCode={
            sitecoreContext.route.fields.QRCodeImage?.value?.src ??
            sitecoreContext.pricing.sitecore.route.fields.QRCodeImage?.value?.src
          }
          termsLink={
            sitecoreContext.route.fields.CustomPricingItem?.fields?.CustomTermsLink ??
            sitecoreContext.pricing.sitecore.route.fields?.StandardTermsLink
          }
          sitecoreContext={sitecoreContext}
        />
      )}
    </>,
    <>
      {sitecoreContext.language !== 'en' && (
        <ModalFormInternational
          show={show}
          handleClose={handleClose}
          key="modal"
          appName={
            sitecoreContext.pricing.sitecore.route.fields?.FormAppID?.value ??
            sitecoreContext.site.name
          }
          QRCode={
            sitecoreContext.route.fields.QRCodeImage?.value?.src ??
            sitecoreContext.pricing.sitecore.route.fields.QRCodeImage?.value?.src
          }
          termsLink={
            sitecoreContext.route.fields.CustomPricingItem?.fields?.CustomTermsLink ??
            sitecoreContext.pricing.sitecore.route.fields?.StandardTermsLink
          }
          sitecoreContext={sitecoreContext}
        />
      )}
    </>,
  ];
};

export default withSitecoreContext()(LeadGenForm);
