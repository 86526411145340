import React, { useState } from 'react';
import loadable from '@loadable/component';
const ModalForm = loadable(() => import(/* webpackPrefetch: true */ './components/ModalForm'), {
  ssr: false,
});
const InitializeTalkToSalesFormComponent = loadable(
  () => import(/* webpackPrefetch: true */ './client.js'),
  { ssr: false }
);

const TalkToSalesForm = () => {
  const [show, setShow] = useState(false);

  return [
    <InitializeTalkToSalesFormComponent
      setShow={setShow}
      show={show}
      key="talk-to-sales-client-side"
    />,
    <ModalForm show={show} key="talk-to-sales-modal-index" />,
  ];
};

export default TalkToSalesForm;
