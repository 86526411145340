import React, { Fragment } from 'react';
import { Text, Image, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';
const PartnerHeroComponentClient = loadable(() => import('./client'), { ssr: false });
import './css/style.css';
import Picture from '../../lib/Picture';

const PartnerHeroComponent = ({ sitecoreContext, fields, rendering }) => {
  const pricing = sitecoreContext.pricing.sitecore.route;
  if (!fields) {
    return null;
  }
  const mobileButtonLink = {
    value: {
      href: null,
    },
  };
  mobileButtonLink.value.href = sitecoreContext.route.fields?.FirebaseLink?.value
    ? sitecoreContext.route.fields.FirebaseLink.value
    : fields.MobileButtonLink.value.href;

  return [
    <PartnerHeroComponentClient
      fields={fields}
      rendering={rendering}
      bodyClass={sitecoreContext.route.fields?.BodyClass?.value}
      key="partner-hero-client"
    />,
    <header className="bg-white visible-xs py-25" key="header">
      <ul className="app-links text-center">
        {fields.LogoImage?.value?.src ? (
          <li>
            <Link field={pricing.fields.RollLogo}>
              <Picture field={fields.MobileLogoImage} loading="lazy" />
            </Link>
          </li>
        ) : (
          <li>
            <Link field={pricing.fields.RollLogo}>
              <Picture field={pricing.fields.RollLogo} loading="lazy" />
            </Link>
          </li>
        )}

        {fields.MobileParterLogoLockupImage?.value?.src && (
          <li>
            <Picture
              className="img-fluid "
              field={fields.MobileParterLogoLockupImage}
              loading="lazy"
            />
          </li>
        )}
      </ul>
    </header>,
    <Fragment key="PartnerHeroComponent">
      <style>
        {`@media (min-width: 768px) { #${rendering.componentName}-${rendering.uid} { background: url(${fields.Image.value.src}) top right 20% no-repeat;} } @media (max-width: 1565px) {#${rendering.componentName}-${rendering.uid} { background-size: 50%;}} @media (min-width: 1566px) {#${rendering.componentName}-${rendering.uid} { background-size: 44%;}}`}
      </style>

      <Container
        fluid
        className="container-fluid pt-0 pb-80 pb-xs-0 pl-xs-0 pr-xs-0 amz-hero sem-hero "
        data-uid={rendering.uid}
        data-component-name={rendering.componentName}
        id={`${rendering.componentName}-${rendering.uid}`}
      >
        <Row
          as="section"
          className="hero-wrap center-block d-flex flex-wrap pt-40 pt-xs-0 px-xs-10"
        >
          <Col
            sm={5}
            className="pl-xs-0 pr-xs-0 d-flex justify-content-center flex-column text-xs-center col-xs-12"
          >
            <ul className="logo-links hidden-xs">
              {fields.LogoImage?.value?.src ? (
                <li>
                  <Link field={pricing.fields.RollLogo}>
                    <Picture field={fields.LogoImage} loading="lazy" />
                  </Link>
                </li>
              ) : (
                <li>
                  <Link field={pricing.fields.RollLogo}>
                    <Picture field={pricing.fields.RollLogo} loading="lazy" />
                  </Link>
                </li>
              )}

              {fields.PartnerLogoLockupImage?.value?.src && (
                <li>
                  <Picture field={fields.PartnerLogoLockupImage} loading="lazy" />
                </li>
              )}
            </ul>
            <h1 id="partnerHeroTitle" className="text-xs-center mt-50">
              <RichText tag="span" className="font48" field={fields.Title} />
            </h1>
            <div
              id="partnerHeroSubtitle"
              className="lead mb-36 text-xs-center mt-30 mt-xs-15 px-xs-10"
            >
              <RichText field={fields.SubTitle} />
            </div>
            <div className="scan-button hidden-xs">
              <div className="mb-xs-20 px-xs-20 text-center">
                <div className="hero-qr">
                  <Picture className="img-responsive" field={fields.QRCodeImage} loading="lazy" />
                  <p className="mb-0 size12 mt-10">
                    {fields.TermsLink?.value?.href ? (
                      <Link
                        id="partnerHeroPopupOffer"
                        className="popup-offer"
                        field={fields.TermsLink}
                      />
                    ) : (
                      <Link
                        id="partnerHeroPopupOffer"
                        className="popup-offer"
                        field={pricing.fields.StandardTermsLink}
                      />
                    )}
                  </p>
                </div>
              </div>
              <div className="btn-wrap mb-20 hidden-xs">
                <Picture
                  alt="App Store"
                  className="img-responsive hidden-xs"
                  field={pricing.fields.AppleStoreButtonImage}
                  loading="lazy"
                />

                <Picture
                  className="img-responsive hidden-xs float-right"
                  alt="Play Store"
                  field={pricing.fields.GooglePlayStoreButtonImage}
                  loading="lazy"
                />
              </div>
            </div>
          </Col>
          <Col sm={6} className="text-right mb-xs-0 px-xs-0 relative col-xs-12">
            <div className="card-box p-20 text-center mb-xs-80">
              <p className="mb-0">
                <em>
                  <Text field={fields.ExclusivelyForText} />
                </em>
                <br />
                <strong>
                  <Text field={fields.ExclusivePartnerText} />
                </strong>
              </p>
              <div className="purple-text mt-10 mb-10 font25">
                <strong>
                  <RichText field={fields.PromoText} />
                </strong>
              </div>

              <RichText className="pricing-splash pt-10 mb-0" field={fields.PromoSubText} />

              <p className="visible-xs p-10">
                <Link field={mobileButtonLink} className="btn btn-primary">
                  <Text field={fields.MobileButtonText} />
                </Link>
              </p>
              <div className="mb-0 mt-20 size12">
                {fields.TermsLink?.value?.href ? (
                  <Link className="popup-offer terms" field={fields.TermsLink} />
                ) : (
                  <Link className="popup-offer terms" field={pricing.fields.StandardTermsLink} />
                )}
              </div>
            </div>
          </Col>
          <Col className="visible-xs col-xs-12 mb-20">
            <div className="btn-wrap mb-20 visible-xs">
              <div className="col-xs-6">
                <Picture
                  alt="App Store"
                  className="img-responsive "
                  field={pricing.fields.AppleStoreButtonMobileImage}
                  loading="lazy"
                />
              </div>
              <div className="col-xs-6">
                <Picture
                  className="img-responsive  float-right"
                  alt="Play Store"
                  field={pricing.fields.GooglePlayStoreButtonMobileImage}
                  loading="lazy"
                />
              </div>
            </div>
          </Col>
          <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
            <Picture
              className="img-responsive width510 visible-xs no-lazy"
              field={fields.MobileImage}
              loading="lazy"
            />
          </div>
        </Row>
      </Container>
    </Fragment>,
  ];
};

export default withSitecoreContext()(PartnerHeroComponent);
