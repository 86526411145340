import React from 'react';
import { withSitecoreContext, Placeholder, Text, Image } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import readTime from '../../assets/icons/roll-blog-icon-read-time.svg';
import moment from 'moment';
import authorAvatar from '../../assets/icons/author_avatar_2x.png';
// import BlogCategoryHeader from '../BlogCategoryHeader';

const BlogPostHeader = (props) => {
  const {
    PostImage,
    Headline,
    Teaser,
    HowLongToReadInMinutes,
    PostDate,
    Author,
  } = props.sitecoreContext.route.fields;
  const ORG_SCHEMA = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
    },
    headline: Headline.value,
    image: PostImage.value.src,
    author: {
      '@type': 'Organization',
      name: Author.value,
    },
    publisher: {
      '@type': 'Organization',
      name: '',
      logo: {
        '@type': 'ImageObject',
        url: '',
      },
    },
    datePublished: PostDate.value.split('T')[0],
  });

  const postDate = moment.utc(PostDate.value).format('MMMM DD, YYYY');
  return (
    <>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ORG_SCHEMA }} />
      <section className="post-header blog__roll" aria-label="Header">
        <Container
          fluid
          className="bg-image"
          style={{
            background:
              'linear-gradient(90deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)),url(' +
              PostImage.value.src +
              ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        ></Container>

        <Container className="blog__roll__hero">
          <Placeholder name="blogrollbyadp_breadcrumbs" rendering={props.rendering} />
          <Row className="blog__roll__hero__row">
            <Col md={6} className="blog__roll__hero__row__col col__one">
              <div>
                <Text tag="h1" field={Headline} aria-label={Headline.value} />
                <p aria-label={Teaser.value}>
                  <Text field={Teaser} />
                </p>
              </div>
            </Col>
            <Col md={6} className="blog__roll__hero__row__col col__two">
              <div>
                <Image className="right__col__img" alt={PostImage.value.alt} field={PostImage} />

                {/* <img src={PostImage.value.src} alt={Teaser.value} className="right__col__img" /> */}
              </div>
            </Col>
          </Row>
          <Row className="blog__roll__hero__row">
            <Col xs={6} className="blog__roll__hero__row__col__author__time col__one">
              <span>
                <img src={authorAvatar} alt="Author" />
              </span>{' '}
              By <Text field={Author} aria-label="Author" role="textbox" /> on{' '}
              <span aria-label="Date posted" role="textbox">
                {postDate}
              </span>
            </Col>
            <Col className="blog__roll__hero__row__col__author__time col__two">
              <span>
                <img src={readTime} alt="Reading Time" />
              </span>{' '}
              {`${HowLongToReadInMinutes.value} ${
                HowLongToReadInMinutes.value > 1 ? 'min read' : 'min read'
              }`}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withSitecoreContext()(BlogPostHeader);
