import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import ModalButton from '../LeadGenForm/components/ModalButton';

const RightColPromo = (props) => {
  // console.log(props, 'from right side promo');
  return (
    <Container fluid className="right__side__promo" aria-label="Promotion" tabIndex="0">
      <div className="right__side__promo__container__one" aria-label="Promotion image">
        <Image
          field={props.fields.image}
          alt={props.fields.subhead.value}
          className="right__side__promo__img"
        />
      </div>
      <div className="right__side__promo__container__two">
        <p tabIndex="0">
          <Text field={props.fields.subhead} aria-label={props.fields.subhead.value} />
        </p>
        <Text
          field={props.fields.headline}
          tag="h4"
          aria-label={props.fields.headline.value}
          tabIndex="0"
        />

        {/* <Link
          field={props.fields.PromoLink}
          aria-label="Promotion button"
          className="btn"
          tabIndex="0"
        /> */}
        {console.warn(props.fields.PromoLink.value, 'promolink')}
        <ModalButton
          dataLabel="try_for_free"
          dataSource="nav"
          color="primary"
          size="sm"
          btnText={props.fields.PromoLink.value.text}
          modalSource="free-trial-button"
        />
      </div>
    </Container>
  );
};

export default RightColPromo;
