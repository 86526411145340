import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import './css/style.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ThreeColumnTestimonialsComponent = ({ fields, rendering }) => {
  if (!fields) {
    return null;
  }
  return (
    <div className="container-fluid py-60 px-0" data-component={rendering.componentName}>
      <Row as="section" className="sem-testimonials row center-block">
        <Row as="div" className="row center-block">
          <Col className="col-xs-12 text-center center-block">
            <RichText className="font48" field={fields.TestimonialHeader} />
            <div className="sem-quote-bubble mb-xs-60">
              <span className="h3 white text-center px-10">
                <RichText field={fields.BubbleText} />
              </span>
            </div>
            <Row id="sem-testimonials" className="pt-25">
              <Testimonials testimonials={fields.Testimonials} />
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

const Testimonials = ({ testimonials }) => {
  return testimonials?.slice(0, 3).map((t) => (
    <Col lg={4} className="px-sm-30 pb-xs-60" key={t.id}>
      <blockquote>
        <RichText field={t.fields.TestimonialShortText} />
        <footer>
          <p>
            <Text field={t.fields.Name} />
          </p>
          <cite>
            {(t.fields.Role.value !== '' || null) && <Text field={t.fields.Role} />}
            {(t.fields.Role.value !== '' || null) &&
              (t.fields.CompanyName.value !== '' || null) &&
              ' of '}
            {(t.fields.CompanyName.value !== '' || null) && <Text field={t.fields.CompanyName} />}
          </cite>
        </footer>
      </blockquote>
    </Col>
  ));

  // return cards || null;
};

export default ThreeColumnTestimonialsComponent;
