import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

import { Card, Container, Link, Col, Row } from 'react-bootstrap';
import moment from 'moment';

let SmallCard = (props) => {
  // props.post.item.postDate.jss.value
  let date;
  if (props?.post?.item) {
    date = moment.utc(props.post.item.postDate.jss.value).format('MMM DD, YYYY'); // props.post.item.postDate.jss.value
  }
  return (
    <Col xs={12} sm={12} md={4} lg={4} xl={4} className="small__card__col">
      <Card className="smallcard small__card__col__card mt-4 mb-4">
        <Card.Body className="topicsmallcardBody">
          <Card.Img
            fluid
            className="topicsmallCardPic"
            variant="top"
            src={props.post.item.postImage.jss.value.src}
            alt={props.post.item.postImage.jss.value.alt}
            role="img"
          />
          <Card.Title className="p-4 smallCardHeadline" aria-label="Card title" role="textbox">
            <a
              href={props.post.item.url}
              aria-label={props.post.item.headline.jss.value}
              role="button"
            >
              {props.post.item.headline.jss.value}
            </a>
          </Card.Title>
          <Card.Text className=" pb-4 " aria-label="Card text" role="textbox">
            <span className="category" aria-label="Category" role="textbox">
              {props.post.item.category.categoryName.jss.value}
            </span>
            <span className="px-2"> • </span>
            <span aria-label="Date posted" role="textbox">
              {date}
            </span>
            {/* {'Category'} . {props.post.item.postDate.jss.value} */}
          </Card.Text>
          {/* category */}
          {/* date */}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default SmallCard = SmallCard;
