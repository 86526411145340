import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Picture from '../../lib/Picture';

const IconListComponent = ({ fields }) => {
  if (!fields) {
    return null;
  }
  return (
    <div className="container-fluid">
      <span className="anchor anchor-item" id="anchor4"></span>
      <Row as="section" className="center-block py-xs-0 px-xs-15 width860 mb-xs-50">
        <div>
          <Col xs={12} className="col-xs-12 text-center px-xs-0">
            <h3>
              <RichText field={fields.Title} />
            </h3>
            <div className="lead">
              <RichText field={fields.SubTitle} />
            </div>
          </Col>
          <Col
            xs={12}
            sm={11}
            className="col-xs-12 center-block d-flex flex-wrap p-sm-0 px-xs-0 pb-xs-0 text-xs-center"
          >
            <div className="col-xs-12 count-list">
              <Cards cards={fields.CardList} />
            </div>
          </Col>
        </div>
      </Row>
    </div>
  );
};

const Cards = ({ cards }) => {
  return cards?.map((c) => (
    <Col sm={12} className="p-0 pb-15" key={c.id}>
      <Col
        xs={12}
        sm={2}
        className="d-xs-inline-block col-sm-2 px-15 text-right text-xs-left h4 mb-0"
      >
        <Picture className="img-responsive" field={c.fields.Image} loading="lazy" />
      </Col>
      <Col sm={10} className="d-xs-inline col-sm-10 pl-10 pr-0 mb-xs-30">
        <h4 className="mb-12">
          <strong>
            <RichText field={c.fields.Title} />
          </strong>
        </h4>
        <div className="d-xs-inline pt-xs-20 mb-xs-40 lead text-xs-center">
          <div className="d-xs-inline-block lead">
            <RichText field={c.fields.SubTitle} />
          </div>
        </div>
      </Col>
    </Col>
  ));

  return cards || null;
};

export default IconListComponent;
