import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { Container, Row, Col, Button } from '../../../node_modules/react-bootstrap';

const FullWidthPromo = (props) => {
  let firstCol = props.fields.alignLeft ? 'promoleftside' : 'promorightside order-2';
  let secondCol = props.fields.alignLeft ? 'promorightside' : 'promoleftside order-1';

  return (
    <Container fluid className="fullWidthPromo" aria-label="Promotion">
      <Row className="PromoGetTheGuide" aria-label="Promotion container">
        <Col className={firstCol} md={6}>
          <h5 className="subhead" aria-label={props.fields.subhead.value}>
            <Text field={props.fields.subhead} />
          </h5>
          <h4 className="headline" aria-label={props.fields.headline.value}>
            <Text field={props.fields.headline} />
          </h4>
          <Link
            className="btn btn-primary"
            aria-label={props.fields.PromoLink.value}
            size="lg"
            role="button"
            field={props.fields.PromoLink}
          />
        </Col>
        <Col className={secondCol} md={6}>
          <Image
            field={props.fields.image}
            aria-label="Promotional image"
            className="promoimage1 img-fluid"
            alt="Promotional image"
          />
        </Col>
      </Row>
    </Container>
  );
};
export default FullWidthPromo;
