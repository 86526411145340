import React from 'react';
import { Text, Image, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './css/style.css';
import Picture from '../../lib/Picture';

const CampaignPromoWithQRCodeComponent = ({ sitecoreContext, fields }) => {
  const pricing = sitecoreContext.pricing.sitecore.route;
  if (!fields) {
    return null;
  }

  const mobileButtonLink = {
    value: {
      href: null,
    },
  };
  mobileButtonLink.value.href = sitecoreContext.route.fields?.FirebaseLink?.value
    ? sitecoreContext.route.fields.FirebaseLink.value
    : fields.MobileButtonLink.value.href;

  return (
    <Container className="sem-bg-footer pt-xs-0" fluid>
      <Row as="section" className="row center-block relative">
        <Col xs={12} className="col-xs-12 ">
          <div className="card-box text-center pt-60 pt-xs-40 pl-xs-15 pr-xs-15">
            <h2 className="font42 mx-auto">
              <RichText field={fields.Title} />
            </h2>
            <div className="purple-text lead mb-5 font30 ">
              <RichText className="promo_bold_text" field={fields.SubTitle} />
            </div>

            <RichText className="pricing-splash pt-10" field={fields.SubText} />

            <div className="mb-xs-20 px-xs-20 text-center">
              {fields.QRCodeImage?.value?.src && [
                <p className="hero-qr hidden-xs" key="qr">
                  <Picture
                    className="img-responsive qr-code"
                    field={fields.QRCodeImage}
                    loading="lazy"
                  />
                </p>,
                <p className="visible-xs p-10" key="link">
                  <Link
                    field={mobileButtonLink}
                    data-label="start_free_trial"
                    className="btn btn-primary"
                  >
                    <Text field={fields.MobileButtonText} />
                  </Link>
                </p>,
              ]}

              {fields.TermsLink?.value.href !== '' || null ? (
                <small>
                  <Link className="popup-offer terms" field={fields.TermsLink} />
                </small>
              ) : (
                <small>
                  <Link className="popup-offer terms" field={pricing.fields.StandardTermsLink} />
                </small>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withSitecoreContext()(CampaignPromoWithQRCodeComponent);
