import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import loadable from '@loadable/component';
import Picture from '../../lib/Picture';
const InitializeSlick = loadable(() => import('./client'), { ssr: false });

const ThreeColumnComponent = ({ fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const hideOnMobile = rendering?.params?.HideOnMobile === '1' ? 'd-xs-none' : '';
  const hideOnDesktop = rendering?.params?.HideOnMobile === '1' ? 'd-lg-none' : '';
  const useCarousel = rendering?.params?.UseCarouselOnMobile === '1';

  const components = [];
  if (useCarousel) {
    components.push(<InitializeSlick key="initslick" />);
  }

  components.push(
    <Container
      fluid
      data-component={rendering.componentName}
      data-source-id={rendering.dataSource}
      className={`pb-sm-100 px-xs-0 ${hideOnMobile} ${hideOnDesktop}`}
    >
      <Row as="section" className={`center-block text-center ${useCarousel ? 'hidden-xs' : ''}`}>
        <Col xs={12} className="col-xs-12 center-block d-flex flex-wrap">
          <Col xs={12} className="col-xs-12 px-xs-0 width860 text-center mx-auto">
            <h2>
              <RichText field={fields.Title} />
            </h2>
            {fields.SubTitle?.value && (
              <div className="lead mb-xs-0 mx-auto">
                <RichText field={fields.SubTitle} />
              </div>
            )}
          </Col>
          <Cards cards={fields.CardList} />
        </Col>
      </Row>
      {useCarousel && (
        <Row as="section" className={'row center-block text-center visible-xs'}>
          <div className="col-xs-12 center-block">
            <h2 className="size20">
              <RichText field={fields.Title} />
            </h2>
          </div>
          <div className="js-carousel-cards">
            <CarouselCards cards={fields.CardList} />
          </div>
        </Row>
      )}
    </Container>
  );

  return components;
};

const Cards = (fields) => {
  return fields.cards?.map((c) => (
    <Col
      key={c.id}
      xs={12}
      sm={4}
      className="col-xs-12 px-xs-0 px-sm-40 d-flex flex-column align-content-center mb-xs-40"
    >
      <Picture className="mx-auto" field={c.fields.Image} loading="lazy" />
      <p className="h4">
        <strong>
          <RichText field={c.fields.Title} />
        </strong>
      </p>

      {c.fields.SubTitle?.value && (
        <div className="lead">
          <RichText field={c.fields.SubTitle} />
        </div>
      )}
    </Col>
  ));
  return cards || null;
};

const CarouselCards = ({ cards }) => {
  return cards?.slice(0, 3).map((c) => (
    <div className="px-10" key={c.id}>
      <div className="card-box p-15" style={{ height: '360px' }}>
        <Picture className="mx-auto lazy" field={c.fields.Image} loading="lazy" />
        <p className="h4 mt-xs-0">
          <RichText tag="strong" field={c.fields.Title} />
        </p>
        <RichText className="lead" field={c.fields.SubTitle} />
      </div>
    </div>
  ));
};

export default ThreeColumnComponent;
