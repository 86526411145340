import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { Container, Row, Col, Button } from '../../../node_modules/react-bootstrap';
import { useQuery, gql } from '@apollo/client';
import { loader as gqlLoader } from 'graphql.macro';

const query = gqlLoader('./query.graphql');

const HalfWidthPromo = (props) => {
  var { loading, error, data } = useQuery(query, {
    variables: {
      item: props.item,
    },
  });

  let firstCol = 'colOne';
  let secondCol = 'colTwo';
  // console.log(loading);
  // console.log(data);
  // console.log(error);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  // console.log(data);
  // console.log(error);
  // console.log(loading);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
    <Container fluid className="fullWidthPromo" aria-label="Promotion">
      <Row className="fullWidthPromo__row">
        <Col className={firstCol} md={6}>
          {/* <PromoDetails /> */}
          <h5 className="" aria-label={data.item.subhead.jss.value}>
            {data.item.subhead.jss.value}
          </h5>
          <h4 className="" aria-label={data.item.headline.jss.value}>
            {data.item.headline.jss.value}
          </h4>
          <a
            href={data.item.promoLink.jss.value.href}
            target={data.item.promoLink.jss.value.target}
            aria-label="Button"
            className="btn"
          >
            {data.item.promoLink.jss.value.text}
          </a>
        </Col>
        <Col className={secondCol} md={6}>
          <img
            src={data.item.image.jss.value.src}
            alt={data.item.image.jss.value.alt}
            style={{ maxWidth: '100%', backgroundColor: '#00000000' }}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default HalfWidthPromo;
