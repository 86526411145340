import React, { Fragment, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import loadable from '@loadable/component';

// Client code
const CompetitorComparisonChartComponentClient = loadable(() => import('./client'), { ssr: false });

const CompetitorComparisonChartComponent = ({ sitecoreContext, fields, rendering }) => {
  // Rendering Info
  if (!fields) {
    return null;
  }
  const componentName = rendering.componentName;
  const uid = rendering.uid;
  const dataSource = rendering.dataSource;
  const roll = fields.data.chart.roll.targetItem.competitorFeatureMatrix.targetItems;
  const competitors = fields.data.chart.otherCompetitors.targetItems;

  const [competitor, setCompetitor] = useState(competitors[0]);
  const onChangeHandler_selectric = (value) => {
    const newCompetitor = competitors.find((o) => o.competitorName.jss.value === value);
    setCompetitor(newCompetitor);
  };
  const onChangeHandler = (e) => {
    onChangeHandler_selectric(e.target.value);
  };

  return [
    <CompetitorComparisonChartComponentClient
      onChangeHandler={onChangeHandler_selectric}
      key="client-side"
    />,
    <Fragment key="comp-list">
      <Container
        data-source-id={dataSource}
        data-component={componentName}
        data-uid={uid}
        fluid
        className={`text-center ${componentName}`}
      >
        <div className="mx-auto text-left comp-row width1126 px-0 d-flex flex-row flex-wrap justify-content-center align-content-end">
          <div className="d-xs-none d-sm-block left-col"></div>
          <div className="text-center bg-purple-4300D5 middle-col">
            <img
              alt={'Roll logo'}
              src={
                '/-/media/microsites/rollbyadp/images/experianlandingpage/logo_roll_white.svg?rev=a45dd14c006341f08a174b827a4c2ec1&hash=4F18EB6D6A83CD4A54FF385497280577'
              }
            />
          </div>
          <div className={'text-center align-middle right-col'}>
            <select
              id="competitorSelect"
              className="competitor-dropdown form-control text-box single-line"
              tabIndex="-1"
              onChange={onChangeHandler}
            >
              {competitors.map((competitor, index) => {
                return (
                  <option value={competitor.competitorName.jss.value} key={`opt-${index}`}>
                    {competitor.competitorName.jss.value}
                  </option>
                );
              })}
            </select>
          </div>
          {roll.map((row, index) => {
            const id = row.comparisonFacet.targetItem.id;
            const competitorRow = competitor.competitorFeatureMatrix.targetItems.find(
              (o) => o.comparisonFacet.targetItem.id === id
            );
            const lastPrefix = roll.length - 1 === index ? 'last' : '';
            return (
              <Fragment key={index}>
                <div
                  className={`d-xs-none d-sm-block  left-col-features index-${index} ${lastPrefix}`}
                >
                  <div className="comparison-card">
                    <strong>{row.comparisonFacet.targetItem.title.jss.value}</strong>
                    <br />
                    {row.comparisonFacet.targetItem.subTitle.jss.value}
                  </div>
                </div>
                <div className={`m-0 middle-col-features ${lastPrefix}`}>
                  <div className="comparison-card">
                    <div className="d-xs-block d-sm-none text-center pb-25 ">
                      <strong>{row.comparisonFacet.targetItem.title.jss.value}</strong>
                      <br />
                      {row.comparisonFacet.targetItem.subTitle.jss.value}
                    </div>
                    <div className={'text-center'}>
                      <svg
                        width="28"
                        height="20"
                        viewBox="0 0 28 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.92507 20L0.444824 10.5198L2.81489 8.14969L9.92507 15.2599L25.1849 0L27.555 2.37006L9.92507 20Z"
                          fill="#4300D5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={`right-col-features text-center index-${index} ${lastPrefix}`}>
                  <div className="comparison-card">
                    {competitorRow && (
                      <>
                        <svg
                          width="28"
                          height="20"
                          viewBox="0 0 28 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2581 20L0.777832 10.5198L3.14789 8.14969L10.2581 15.2599L25.518 0L27.888 2.37006L10.2581 20Z"
                            fill="#7B7C7E"
                          />
                        </svg>
                        <sup>{competitorRow.footnoteCharacter.jss.value}</sup>
                      </>
                    )}
                    {!competitorRow && (
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.33301 20L0.333008 18L8.33301 10L0.333008 2L2.33301 0L10.333 8L18.333 0L20.333 2L12.333 10L20.333 18L18.333 20L10.333 12L2.33301 20Z"
                          fill="#7B7C7E"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </Fragment>
            );
          })}
          <div className={'footnotes'}>
            <div>
              Information based on ADP internal research and is accurate as of December 1, 2023, and
              is subject to change.
            </div>
            {competitor.competitorFeatureMatrix.targetItems.map((row, index) => {
              return (
                <>
                  {row.footnoteRichText.jss.value && (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: row.footnoteRichText.jss.value }}
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
      </Container>
    </Fragment>,
  ];
};

export default withSitecoreContext()(CompetitorComparisonChartComponent);
