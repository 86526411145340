import React from 'react';
import SelectLanguage from './Components/SelectLanguage';
import loadable from '@loadable/component';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
const NavigationBar = loadable(
  () => import(/* webpackPrefetch: true */ './Components/NavigationBar'),
  { ssr: false }
);

import './css/nav.css';

const PrimaryNav = (props) => {
  return (
    <>
      {props.sitecoreContext.language === 'en' && <SelectLanguage key="nav-sticky" />}
      <NavigationBar navData={props} key="navbar" />
    </>
  );
};

export default withSitecoreContext()(PrimaryNav);
