import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const Picture = (props) => {
  const src = props.field?.src || props.field?.value?.src;
  let webpSrc;
  let showwebp = false;
  if (
    process.env.SERVER_RENDERED ||
    (typeof window !== 'undefined' && window?.location?.host !== 'localhost:3000')
  ) {
    if (src && !src.includes('.svg')) {
      // TODO: inject /webp into the src more elegantly.
      webpSrc = src.replace(process.env.JSS_HOST_NAME, '');
      // temp
      showwebp = true;
    }
  }
  return (
    <picture className={props.className}>
      {showwebp && <source type="image/webp" srcSet={`/webp${webpSrc}`} />}
      <Image {...props} loading="lazy"></Image>
    </picture>
  );
};

export default Picture;
