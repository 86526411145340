import React from 'react';

const SchemaComponent = ({ sitecoreContext, fields }) => {
  if (!fields) {
    return null;
  }

  const {
    SchemaOrgName,
    SchemaOrgUrl,
    SchemaOrgLogo,
    SchemaOrgDescription,
    SchemaOrgSameAsFacebook,
    SchemaOrgSameAsTwitter,
    SchemaOrgSameAsInstagram,
    SchemaOrgSameAsYoutube,
    SchemaOrgAddressLocality,
    SchemaOrgAddressCountry,
    SchemaOrgPostalCode,
    SchemaOrgStreetAddress,
  } = sitecoreContext.pricing.sitecore.route.fields;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    '@id': SchemaOrgUrl?.value + '#corporation' || '',
    name: SchemaOrgName?.value || '',
    url: SchemaOrgUrl?.value || '',
    logo: SchemaOrgLogo?.value || '',
    description: SchemaOrgDescription?.value || '',
    address: {
      '@type': 'PostalAddress',
      addressLocality: SchemaOrgAddressLocality?.value || '',
      addressCountry: SchemaOrgAddressCountry?.value || '',
      postalCode: SchemaOrgPostalCode?.value || '',
      streetAddress: SchemaOrgStreetAddress?.value || '',
    },
    sameAs: [
      SchemaOrgSameAsFacebook?.value || '',
      SchemaOrgSameAsTwitter?.value || '',
      SchemaOrgSameAsInstagram?.value || '',
      SchemaOrgSameAsYoutube?.value || '',
    ],
  };

  const schemaString = JSON.stringify(schema);

  return (
    <>
      <script
        key="schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: schemaString }}
      />
    </>
  );
};

export default SchemaComponent;
