import React, { useEffect } from 'react';
import { Text, Image, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Col';
import loadable from '@loadable/component';
import './style/style.css';
const InitializeContainer = loadable(() => import('./client'), {
  ssr: false,
});
const ContainerComponent = (props) => {
  const { fields } = props;
  // console.log(fields);

  let classNames = 'pt-0 pb-xs-0';
  const placeholderName = fields.PlaceholderName?.value ?? 'container-placeholder';
  switch (fields.ContainerStyle?.value) {
    case 'Homepage White Blob Background':
      classNames = 'white-bg-lg mb-xs-0';
      break;
    case 'Why Roll White Blob Background':
      classNames = 'white-bg-lg-flipped mb-xs-0';
      break;
    case 'SMB Payroll Grey BG':
      classNames = 'grey-bg-lg mb-xs-0';
      break;
    case 'Dark Blue FAQ BG Features':
      classNames = 'drk-blue-faq-bg-features';
      break;
    case 'Light Blue FAQ BG Features':
      classNames = 'light-blue-faq-bg-features';
      break;
    case 'Amz Bg BG PartnerPgs':
      classNames = 'amz-bg-large';
      break;

    default:
      classNames = 'pt-0 pb-xs-0';
  }

  return [
    <InitializeContainer fields={fields} key="initContainer" />,
    <Container className={classNames} data-component={placeholderName} key="container"></Container>,
  ];
};

export default ContainerComponent;
