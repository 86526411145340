import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SchemaOrgComponent = ({ rendering }) => {
  const stripHTMLTags = (txt) => {
    if (!txt) return '';
    return txt.replace(/<\/?[^>]+(>|$)/g, '' && /&nbsp;/g, ' ' && /&rsquo;/g, '’');
  };

  // Loop over page components looking for FAQcomponents.
  const components = rendering?.placeholders?.['rollbyadp_jss-main'];
  let mainEntity = [];
  components.map((component) => {
    if (component.componentName === 'FAQsComponent') {
      const faqItems = component?.fields?.FAQItems;
      const items = faqItems
        ? faqItems.map((t) => ({
            '@type': 'Question',
            name: stripHTMLTags(t.fields?.Title?.value) || '',
            acceptedAnswer: {
              '@type': 'Answer',
              text: stripHTMLTags(t.fields?.SubTitle?.value) || '',
            },
          }))
        : {};
      mainEntity.push(items[0]);
    }
  });

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  };

  const schemaString = JSON.stringify(schema);

  return [
    <script
      key="schema"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: schemaString }}
    />,
  ];
};

export default withSitecoreContext()(SchemaOrgComponent);
