import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { Card, Container, Link, Col, Row } from 'react-bootstrap';
import moment from 'moment';

const BigCard = (props) => {
  // var now = moment().format('MMM DD, YYYY');
  // var later = moment('2010-01-01T05:06:07').format('MMM DD, YYYY'); // props.post.item.postDate.jss.value
  var date = moment.utc(props.post.item.postDate.jss.value).format('MMM DD, YYYY'); // props.post.item.postDate.jss.value
  // console.log(now);
  // console.log(later);
  // console.log(date);
  // console.log(props);
  return (
    <Card className="bigcard" aria-label="Card">
      <Card.Body className="topicHeroCardBody" aria-label="Card body">
        <Row className="align-items-center bigcardTopicHeroRow" aria-label="Card container">
          <Col
            xs={12}
            md={6}
            className="col-xs-12 topicheroCol"
            style={{
              backgroundImage: 'url(' + props.post.item.postImage.jss.value.src + ')',
            }}
            aria-label={`${props.post.item.headline.jss.value}`}
          >
            {/* <Card.Img
              fluid
              className="topicheroPic"
              variant="left"
              src={props.post.item.postImage.jss.value.src}
              alt={props.post.item.postImage.jss.value.alt}
            /> */}
          </Col>
          <Col xs={12} md={6} className="col-xs-12 text-align-left topicheroColforbody">
            <Card.Title aria-label="Title" role="heading">
              <a href={props.post.item.url} className="topicHeroHeadline">
                {props.post.item.headline.jss.value}
              </a>
            </Card.Title>
            <Card.Text className="topicHeroteaser" aria-label="Card text" role="textbox">
              {props.post.item.teaser.jss.value}
            </Card.Text>
            <Card.Text className="topicHerodate" aria-label="Post date" role="textbox">
              <span className="category">{props.post.item.category.categoryName.jss.value}</span>
              <span className="px-2"> • </span>
              <span aria-label="Date posted" role="textbox">
                {date}
              </span>
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BigCard;
