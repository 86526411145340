import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Card, Container, Link, Col, Row } from 'react-bootstrap';
import Newsletter from '../Newsletter/index';
import moment from 'moment';
import loadable from '@loadable/component';
import SmallCard from '../Cards/SmallCard';
import BigCard from '../Cards/BigCard';

const LoadMore = loadable(() => import('./LoadMore'), { ssr: false });
// import { useQuery, gql } from 'react-apollo';
const Carousel = loadable(() => import('./Carousel'), { ssr: false });

/* TODO:
        this component is styled but dose not have booleans to show and hide Title, Thumnail and View All
        Small cards are loading from list but not ahowing up on 1 row and 3 cols */
let SectionTopic = (props) => {
  const topicTitleIcon = props.fields.data.datasource.titleThumbnailImage.jss.value.src;

  // const { fields } = props;

  // Template Name
  const BCP = 'BlogRollByADP_BlogCategory';
  const HP = 'BlogRollByADP_AppRoute';
  const BP = 'BlogRollByADP_BlogPost';

  const templateName = props.sitecoreContext.route.templateName;
  const useCarousel = props.fields.data.datasource.useCarouselOnMobile.jss.value;

  const pageClassName =
    templateName === BCP
      ? 'blog-category-page'
      : templateName === HP
      ? 'blog-home-page'
      : templateName === BP
      ? 'blog-post-page'
      : '';

  const allowPaging = props.fields.data.datasource.allowPaging.jss.value;

  const posts = props.fields.data.blogPosts.results.items.filter(
    (p) => p && p.item && p.item?.id !== '525828B5F2FE45909EA9120122902F81'
  ); // filter out standard values
  // console.log(posts);
  return (
    <Container fluid className={`sectiontopiccontainer ${pageClassName} `}>
      <Row
        className={`sectiontopic align-items-center ${!topicTitleIcon && 'no-icon'} ${
          allowPaging && 'paging-row'
        } `}
      >
        {!topicTitleIcon ? (
          ''
        ) : (
          <Col xs={3} sm={2} md={2} lg={2} xl={1} className="title-thumbnail" tabindex="0">
            <Image field={props.fields.data.datasource.titleThumbnailImage.jss} />
          </Col>
        )}
        <Col xs={topicTitleIcon ? 8 : 12} className="">
          <h3 className="topicTitle">
            <Text field={props.fields.data.datasource.title.jss} />
          </h3>
        </Col>

        {!topicTitleIcon ? (
          ''
        ) : (
          <Col xs={2} xl={3} className="viewall">
            <CategoryLink
              className="viewall"
              categoryLink={props.fields.data.datasource.categoryLink}
              t={props.t}
            />
          </Col>
        )}
      </Row>

      {!useCarousel ? (
        <Row>
          <PostList
            useCarousel={useCarousel}
            posts={posts}
            maxlength={props.fields.data.datasource.initialPageSize.jss.value}
            injectNewsletter={props.fields.data.datasource.injectNewsletter.jss.value}
            allowPaging={props.fields.data.datasource.allowPaging.jss.value}
            loadMoreButtonText={props.fields.data.datasource.loadMoreButtonText.jss.value}
            datasource={props.fields.data.datasource?.id}
            contextItem={props.sitecoreContext.route?.itemId}
          ></PostList>
        </Row>
      ) : (
        useCarousel && (
          <Row>
            <PostList
              useCarousel={useCarousel}
              posts={posts}
              maxlength={props.fields.data.datasource.initialPageSize.jss.value}
              injectNewsletter={props.fields.data.datasource.injectNewsletter.jss.value}
              allowPaging={props.fields.data.datasource.allowPaging.jss.value}
              loadMoreButtonText={props.fields.data.datasource.loadMoreButtonText.jss.value}
              datasource={props.fields.data.datasource.id}
              contextItem={props.sitecoreContext.route.itemId}
            ></PostList>
            <Container fluid className="section__topic__carousel">
              <Carousel
                className="section__topic__carousel__owl"
                posts={props.fields.data.blogPosts.results.items}
                maxlength={props.fields.data.datasource.initialPageSize.jss.value}
              />
            </Container>
            <Container className="viewall view__all__container">
              <CategoryLink
                className="viewall"
                categoryLink={props.fields.data.datasource.categoryLink}
                t={props.t}
              />
            </Container>
          </Row>
        )
      )}
    </Container>
  );
};

const CategoryLink = (props) => {
  if (props.categoryLink !== null && props.categoryLink.jss !== null) {
    return (
      <a className="viewallfix" href={props.categoryLink.jss.url}>
        View All
      </a>
    );
  }
  return null;
};

const PostList = (props) => {
  const { useCarousel } = props;
  // console.log(props);
  if (props.posts && props.posts.length > 0) {
    return (
      <div className={`section__topic ${useCarousel && 'section__topic__with__carousel'}`}>
        <BigCard post={props.posts[0]} />
        <Row className="align-items-center post-list">
          <NextThree
            posts={props.posts.slice(1, props.maxlength)}
            injectNewsletter={props.injectNewsletter}
          />

          {props.allowPaging ? (
            <LoadMore
              loadMoreButtonText={props.loadMoreButtonText.toUpperCase()}
              datasource={props.datasource}
              contextItem={props.contextItem}
            ></LoadMore>
          ) : (
            ''
          )}
        </Row>
      </div>
    );
  } else {
    return null;
  }
};

const NextThree = (props) => {
  return props.posts.map((post, idx) => {
    if (idx === 1 && props.injectNewsletter) {
      return [
        <SmallCard post={post} key={idx} />,
        <Col md={4} className="small__card__col newsletter" key={idx}>
          <Card className="smallcard mt-4 mb-4 h-100">
            <Card.Body className="topicsmallcardBody card-body">
              <Newsletter />
            </Card.Body>
          </Card>
        </Col>,
      ];
    } else {
      return <SmallCard post={post} key={idx} />;
    }
  });
};
export default withSitecoreContext()(SectionTopic);
