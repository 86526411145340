import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Col from 'react-bootstrap/Col';
import { v4 as uuidv4 } from 'uuid';
import LogoAppleStore from '../../../assets/logo_app_store.svg';
import LogoPlayStore from '../../../assets/logo_google_play.svg';

const FormComponentInternational = ({ sitecoreContext, fields }) => {
  const { BackgroundColor, Title, SubTitle, FormTitle, FormSubTitle } = fields;

  const className = fields.SubTitle?.value ? 'mb-0' : 'mb-20';
  const qrCode =
    sitecoreContext.route.fields.QRCodeImage?.value?.src ??
    sitecoreContext.pricing.sitecore.route.fields.QRCodeImage?.value?.src;
  console.info(qrCode);
  return (
    <>
      <Col xs={12} sm={6} className="col-xs-12 mb-60 card-box" key="form">
        <div
          className="hero-bg visible-xs"
          style={{
            top: '-9%',
            left: '-50%',
            transform: 'rotate(45deg) scale(.62)',
            zIndex: '-1',
          }}
        >
          &nbsp;
        </div>
        <div className="thanks-box d-none">
          <Col xs={12} className="col-xs-12 py-100">
            {!sitecoreContext.pricing.sitecore.route.fields?.thank_you_message?.value && (
              <>
                <p className="h3">Success!</p>
                <p className="lead">
                  Thanks for reaching out.
                  <br />
                  Someone from <strong>Team Roll</strong> will reach out shortly.
                </p>
                <p>You will receive a confirmation email momentarily.</p>
              </>
            )}
            {!!sitecoreContext.pricing.sitecore.route.fields?.thank_you_message?.value && (
              <>
                <RichText
                  className="text-center"
                  field={sitecoreContext.pricing.sitecore.route.fields.thank_you_message}
                />
                <img
                  alt=""
                  style={{ maxWidth: '202px' }}
                  src={qrCode}
                  data-heightmissing=""
                  data-widthmissing=""
                  className="mx-auto img-responsive mb-30 qr-code"
                />
                <Col
                  as="a"
                  xs={6}
                  className="col-xs-6 px-5 text-right apple-app-store-link"
                  href={sitecoreContext.pricing.sitecore.route.fields.AppleAppStoreLink.value.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={LogoAppleStore}
                    alt="App Store"
                    data-heightmissing=""
                    data-widthmissing=""
                    data-lazy={LogoAppleStore}
                    className="img-responsive"
                  />
                </Col>
                <Col
                  as="a"
                  xs={6}
                  className="col-xs-6 px-5 text-left android-app-store-link"
                  href={sitecoreContext.pricing.sitecore.route.fields.GoogleAppStoreLink.value.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={LogoPlayStore}
                    alt="Play Store"
                    data-heightmissing=""
                    data-widthmissing=""
                    data-lazy={LogoPlayStore}
                    className="img-responsive"
                  />
                </Col>
              </>
            )}
          </Col>
        </div>
        <div id="form-container" className="pt-30 px-sm-30">
          <form id="roll-demo-form-hf" name="leadform" noValidate="novalidate">
            <input type="hidden" id="hiddensitename" name="hiddensitename" value="olp" />
            <input
              type="hidden"
              id="hiddendestsysid"
              name="hiddendestsysid"
              value="EloquaDestinationSysId"
            />
            <input type="hidden" id="elqSiteID" name="elqSiteID" value="937" />
            <input type="hidden" id="elqFormName" name="elqFormName" value="COM-ESI-EMEA-Lead" />
            <input type="hidden" id="Vendor_Name" name="Vendor_Name" value="In-house" />
            <input type="hidden" id="Lead_Source" name="Lead_Source" value="Web Form" />
            <input type="hidden" id="Lead_Source_New" name="Lead_Source_New" value="Web Form" />
            <input type="hidden" id="Lead_Status_New" name="Lead_Status_New" value="Raw Lead" />
            <input
              type="hidden"
              id="Lead_Source_Detail"
              name="Lead_Source_Detail"
              value="Contact Me"
            />
            <input type="hidden" id="ADP_Client" name="ADP_Client" value="Prospect" />
            <input type="hidden" id="Contact_Me" name="Contact_Me" value="Yes" />
            <input type="hidden" id="elqCookieWrite" name="elqCookieWrite" value="0" />
            <input
              type="hidden"
              id="GES_Country_Code"
              name="GES_Country_Code"
              value="United Kingdom"
            />
            <input
              type="hidden"
              id="GES_ADP_Country_Generator"
              name="GES_ADP_Country_Generator"
              value="United Kingdom"
            />
            <input type="hidden" id="elqCampaignId" name="elqCampaignId" value="44115" />
            <input type="hidden" id="PromoID" name="PromoID" value="IE24Roll" />
            <input type="hidden" id="Email_ID" name="Email_ID" value="226999" />
            <input type="hidden" id="Shared_List_ID" name="Shared_List_ID" value="95767" />
            <input type="hidden" id="form" name="form" value="bottom" />
            <input type="hidden" id="Call_to_action" name="Call_to_action" value="Get Started" />
            <input type="hidden" id="GDPRConsent" name="GDPRConsent" value="on" />
            <input type="hidden" id="Language" name="Language" value="EN" />
            <input type="hidden" id="sc_site" name="sc_site" value={sitecoreContext.site.name} />
            <input type="hidden" id="Referring_Source" name="Referring_Source" value="" />
            <input type="hidden" id="Channel" name="Natural/Organic" value="" />
            <input type="hidden" id="Unique_ID" name="Unique_ID" value={uuidv4()} />
            <input type="hidden" id="Fullfillment" name="Fullfillment" value="Quote" />
            <input type="hidden" name="SFDC_Owner_ID" value="00B400000066qPoEAI" />

            <span className="form-error-field input-error help-block field-validation-error"></span>
            <div
              className="center-block form-group mb-xs-5"
              style={{ maxWidth: '400px', marginBottom: '30px' }}
            >
              <RichText className="text-center" field={FormTitle}></RichText>
              <RichText className="text-center" field={FormSubTitle}></RichText>
            </div>
            <div className="required-field form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="firstName" className="py-xs-5">
                  First Name
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="firstName"
                  className="form-control  name-field"
                  maxLength="30"
                  name="First_Name"
                  size="20"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="lastName" className="py-xs-5">
                  {sitecoreContext.language !== 'en-IE' ? 'Last Name' : 'Surname'}
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="lastName"
                  className="form-control name-field"
                  maxLength="30"
                  name="Last_Name"
                  size="20"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field field-email form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="email" className="py-xs-5">
                  Email
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="email"
                  className="form-control text-box single-line "
                  maxLength="50"
                  name="email"
                  size="20"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field field-phone form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="phone" className="py-xs-5">
                  Phone Number
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="phone"
                  className="form-control text-box single-line "
                  maxLength="16"
                  name="Phone"
                  size="16"
                  type="tel"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field field-company form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="country" className="py-xs-5">
                  Company
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="company"
                  className="form-control text-box single-line "
                  maxLength="16"
                  name="company"
                  size="16"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div id="countryOptin" className="form-group d-none">
              <label htmlFor="mobileSMSOptIn">
                <input
                  className="d-inline mb-0 mr-10"
                  type="checkbox"
                  id="mobileSMSOptIn"
                  name="mobileSMSOptIn"
                  value="true"
                />
                <p className="d-inline text-muted specter-regular">
                  ADP may contact me about its products, services, and offers. Options to
                  unsubscribe and manage your communication preferences will be provided to you in
                  these communications.
                </p>
              </label>
            </div>
            <Col xs={12} className="col-xs-12 px-0 mt-30">
              <p style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <button
                  id="lead-submitHF"
                  type="submit"
                  className="btn btn-primary cta-click"
                  data-label="talk_to_sales"
                  data-section="demo_above_footer"
                  data-header="have_questions_about_roll"
                >
                  {fields.CTAButtonText?.value ? fields.CTAButtonText.value : 'CONTACT ME'}
                </button>
              </p>
              <div>
                <p style={{ fontSize: '14px' }}>
                  By submitting this form you are informed that ADP may contact you about its
                  products, services, and offers, according to our Privacy statement for Business
                  contacts.
                </p>
              </div>
              <div
                className="light-blue-bg hidden-xs"
                style={{ zIndex: '-1', top: '-25px', left: '-120px' }}
              >
                &nbsp;
              </div>
            </Col>
            <div>
              <RichText field={fields.DisclaimerText} />
            </div>
          </form>
        </div>
      </Col>
    </>
  );
};

export default FormComponentInternational;
