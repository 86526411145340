import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import loadable from '@loadable/component';
const HeroFormclient = loadable(() => import(/* webpackPrefetch: true */ '../client'), {
  ssr: false,
});
const FormLogic = loadable(() => import(/* webpackPrefetch: true */ '../js/FormLogic'), {
  ssr: false,
});
import FormComponent from './FormComponent';
import FormComponentInternational from './FormComponentInternational';

const HeroFormComponent = ({ sitecoreContext, route, fields }) => {
  // use the form from the bottom of the page on the homepage, etc. make sure that the client-side code is in a client.js file and loaded via loadable.
  return [
    <FormLogic key="form-logic" />,
    <HeroFormclient key="client-side" lang={sitecoreContext.language} />,
    <>
      {sitecoreContext.language === 'en' && (
        <FormComponent sitecoreContext={sitecoreContext} fields={fields}></FormComponent>
      )}
    </>,
    <>
      {sitecoreContext.language !== 'en' && (
        <FormComponentInternational
          sitecoreContext={sitecoreContext}
          fields={fields}
        ></FormComponentInternational>
      )}
    </>,
  ];
};

export default withSitecoreContext()(HeroFormComponent);
