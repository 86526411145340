import React from 'react';
import { Link, Text, Image } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import loadable from '@loadable/component';

const OwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });

const SecondaryNav = (props) => {
  return (
    <Container fluid className="nav2row" aria-label="Secondary navigation" role="navigation">
      {/* xs={1} md={3} className="g-4" */}
      <Row className="cardsDeskTopView" aria-label="Container">
        <Col className="text-center" aria-label="Card Container">
          <a href={props.fields.card1Link.value.href} aria-label="Card link" role="button">
            <Card className="card2ndnav" aria-label="Card">
              <Card.Body aria-label="Card body">
                <Card.Img
                  variant="top"
                  src={props.fields.card1image.value.src}
                  alt={props.fields.card1image.value.alt}
                  className="img-fluid cardIcons"
                />
                <Card.Title className="pt-3 nav2cardTitles" aria-label="Card title" role="textbox">
                  <Text field={props.fields.heading1} aria-label={props.fields.heading1} />
                </Card.Title>
                {/* <Card.Text>
                <Text field={props.fields.heading} />
              </Card.Text> */}
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col className="text-center" aria-label="Card container">
          <a href={props.fields.card2Link.value.href} aria-label="Card link">
            <Card className="card2ndnav" aria-label="Card">
              <Card.Body aria-label="Card body">
                <Card.Img
                  variant="top"
                  src={props.fields.card2image.value.src}
                  alt={props.fields.card2image.value.alt}
                  fluid="true"
                  className="img-fluid cardIcons"
                />
                <Card.Title
                  className="specialPaddingIamgeNotSameHeight nav2cardTitles"
                  aria-label="Card title"
                  role="textbox"
                >
                  <Text field={props.fields.heading2} aria-label={props.fields.heading2} />
                </Card.Title>
                {/* <Card.Text>
                <Text field={props.fields.heading} />
              </Card.Text> */}
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col className="text-center" aria-label="Card container">
          <a href={props.fields.card3Link.value.href} aria-label="Card link">
            <Card className="card2ndnav " aria-label="Card ">
              <Card.Body aria-label="Card body">
                <Card.Img
                  variant="top"
                  src={props.fields.card3image.value.src}
                  alt={props.fields.card3image.value.alt}
                  fluid="true"
                  className="img-fluid cardIcons"
                />
                <Card.Title className="pt-3 nav2cardTitles" aria-label="Card title" role="textbox">
                  <Text field={props.fields.heading3} aria-label={props.fields.heading3} />
                </Card.Title>
                {/* <Card.Text>
                <Text field={props.fields.heading} />
              </Card.Text>
              <Card.Footer className="card2navfooter mt-auto">
                <Text field={props.fields.heading} />
              </Card.Footer> */}
              </Card.Body>
            </Card>
          </a>
        </Col>
      </Row>
      <Container fluid className="MobileCardView">
        {/* <OwlCarouselHero /> */}
        <OwlCarousel
          className="owl-carousel owl__carousel"
          loop={true}
          center={true}
          margin={3}
          nav={false}
          dots={true}
          dotsEach={true}
          dotContainer={'#dots'}
          dotData={true}
          items={1.225}
          aria-label="Mobile Carousel"
        >
          <div className="text-center" aria-label="Card container">
            <a href={props.fields.card1Link.value.href} aria-label="Card link">
              <Card className="card2ndnav " aria-label="Card">
                <Card.Body aria-label="Card body">
                  <Card.Img
                    variant="top"
                    src={props.fields.card1image.value.src}
                    alt={props.fields.card1image.value.alt}
                    className="img-fluid cardIcons"
                  />
                  <Card.Title
                    className="pt-3 nav2cardTitles"
                    aria-label="Card title"
                    role="textbox"
                  >
                    <Text field={props.fields.heading1} aria-label={props.fields.heading1} />
                  </Card.Title>
                </Card.Body>
              </Card>
            </a>
          </div>
          <div className="text-center" aria-label="Card container">
            <a href={props.fields.card2Link.value.href} aria-label="Card link">
              <Card className="card2ndnav " aria-label="Card ">
                <Card.Body aria-label="Card body">
                  <Card.Img
                    variant="top"
                    src={props.fields.card2image.value.src}
                    alt={props.fields.card2image.value.alt}
                    fluid="true"
                    className="img-fluid cardIcons"
                  />
                  <Card.Title
                    className="specialPaddingIamgeNotSameHeight nav2cardTitles"
                    aria-label="Card title"
                    role="textbox"
                  >
                    <Text field={props.fields.heading2} aria-label={props.fields.heading2} />
                  </Card.Title>
                </Card.Body>
              </Card>
            </a>
          </div>
          <div className="text-center" aria-label="Card container">
            <a href={props.fields.card3Link.value.href} aria-label="Card link">
              <Card className="card2ndnav " aria-label="Card">
                <Card.Body aria-label="Card body">
                  <Card.Img
                    variant="top"
                    src={props.fields.card3image.value.src}
                    alt={props.fields.card3image.value.alt}
                    fluid="true"
                    className="img-fluid cardIcons"
                  />
                  <Card.Title
                    className="pt-3 nav2cardTitles"
                    aria-label="Card title"
                    role="textbox"
                  >
                    <Text field={props.fields.heading3} aria-label={props.fields.heading3} />
                  </Card.Title>
                </Card.Body>
              </Card>
            </a>
          </div>
        </OwlCarousel>
      </Container>
    </Container>
  );
};

export default SecondaryNav;
