import React from 'react';
import { Text, RichText, withSitecoreContext, Image } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import loadable from '@loadable/component';
import CTAArea from '../../lib/CTAArea';
import Picture from '../../lib/Picture';

const FeatureHeroClient = loadable(() => import('./client'), { ssr: false });
import './css/style.css';

const FeatureHeroComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields || !fields?.data || !fields?.data?.item) {
    return null;
  }
  const breadcrumbSchema = fields.data.breadcrumb;
  const hostname = process.env.JSS_HOST_NAME ?? '';
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: breadcrumbSchema.parent.breadcrumbName.value ?? breadcrumbSchema.parent.name,
        item: hostname + breadcrumbSchema.parent.url,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: breadcrumbSchema.breadcrumbName.value ?? breadcrumbSchema.name,
      },
    ],
  };

  const schemaString = JSON.stringify(schema);

  return [
    <Container
      fluid
      className="pt-0"
      key="Feature-Hero-Component"
      data-component={rendering.componentName}
      data-source-id={rendering.dataSource}
      data-uid={rendering.uid}
    >
      <script
        key="schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: schemaString }}
      />
      <section>
        <Row className=" center-block d-flex flex-wrap flex-xs-column">
          <Col
            xs={12}
            sm={6}
            className="col-xs-12 d-flex justify-content-center flex-column feature-industry-hero"
          >
            <Breadcrumbs breadcrumbs={fields.data.breadcrumb}></Breadcrumbs>
            <h1>
              <RichText field={fields.data.item.title} />
            </h1>
            <div className="pb-10">
              <RichText field={fields.data.item.subTitle} />
            </div>
            <div>
              <CTAArea
                pricingData={sitecoreContext.pricing.sitecore.route.fields}
                fields={fields.data.item}
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            className="col-xs-12 d-flex flex-column feature-industry-hero-rightcol"
          >
            <Picture
              className="mx-auto img-responsive feat-hero-mr-override hidden-xs"
              field={fields.data.item.image.jss}
              loading="lazy"
            />
          </Col>
        </Row>
      </section>
    </Container>,
    <FeatureHeroClient fields={fields} key="Feature-Hero-Client" />,
  ];
};

const Breadcrumbs = ({ breadcrumbs }) => {
  const bc1 = { name: breadcrumbs.breadcrumbName.value ?? breadcrumbs.name, url: breadcrumbs.url };
  const parent = {
    name: breadcrumbs.parent.breadcrumbName.value ?? breadcrumbs.parent.name,
    url: breadcrumbs.parent.url,
  };

  return (
    <p className="roll-breadcrumb">
      <span className="bread-start pr-5">
        <a href={parent.url}>
          <strong>{parent.name}</strong>
        </a>
      </span>{' '}
      &gt;
      <span className="bread-active pl-5">
        <strong>{bc1.name}</strong>
      </span>
    </p>
  );
};
export default withSitecoreContext()(FeatureHeroComponent);
