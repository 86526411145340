import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Col from 'react-bootstrap/Col';

const FormComponent = ({ sitecoreContext, fields }) => {
  const { BackgroundColor, Title, SubTitle, FormTitle, FormSubTitle } = fields;

  const className = fields.SubTitle?.value ? 'mb-0' : 'mb-20';
  return (
    <>
      <Col xs={12} sm={6} className="col-xs-12 mb-60 card-box" key="form">
        <div
          className="hero-bg visible-xs"
          style={{
            top: '-9%',
            left: '-50%',
            transform: 'rotate(45deg) scale(.62)',
            zIndex: '-1',
          }}
        >
          &nbsp;
        </div>
        <div className="thanks-box d-none">
          <Col xs={12} className="col-xs-12 py-100">
            {!sitecoreContext.pricing.sitecore.route.fields?.thank_you_message?.value && (
              <>
                <p className="h3">Success!</p>
                <p className="lead">
                  Thanks for reaching out.
                  <br />
                  Someone from <strong>Team Roll</strong> will reach out shortly.
                </p>
                <p>You will receive a confirmation email momentarily.</p>
              </>
            )}
            {!!sitecoreContext.pricing.sitecore.route.fields?.thank_you_message?.value && (
              <RichText
                className="text-center"
                field={sitecoreContext.pricing.sitecore.route.fields.thank_you_message}
              />
            )}
          </Col>
        </div>
        <div id="form-container" className="pt-30">
          <form id="roll-demo-form-hf" name="leadform" noValidate="novalidate">
            <input type="hidden" id="hiddensitename" name="hiddensitename" value="olp" />
            <input type="hidden" id="sc_site" name="sc_site" value={sitecoreContext.site.name} />
            <input
              type="hidden"
              id="hiddendestsysid"
              name="hiddendestsysid"
              value="SFDCDestinationSysId"
            />
            <input type="hidden" id="formId" name="formId" value="Demo Form" />

            <span className="form-error-field input-error help-block field-validation-error"></span>
            <div
              className="center-block form-group mb-xs-5"
              style={{ maxWidth: '400px', marginBottom: '30px' }}
            >
              <RichText className="text-center" field={FormTitle}></RichText>
              <RichText className="text-center" field={FormSubTitle}></RichText>
            </div>
            <div className="required-field form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="firstName" className="py-xs-5">
                  First Name
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="firstName"
                  className="form-control  name-field"
                  maxLength="30"
                  name="firstName"
                  size="20"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="lastName" className="py-xs-5">
                  {sitecoreContext.language !== 'en-IE' ? 'Last Name' : 'Surname'}
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="lastName"
                  className="form-control name-field"
                  maxLength="30"
                  name="lastName"
                  size="20"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field field-email form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="email" className="py-xs-5">
                  Email
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="email"
                  className="form-control text-box single-line "
                  maxLength="50"
                  name="email"
                  size="20"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field field-phone form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="mobileNumber" className="py-xs-5">
                  Phone Number
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="mobileNumber"
                  className="form-control text-box single-line "
                  maxLength="16"
                  name="mobileNumber"
                  size="16"
                  type="tel"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div className="required-field field-company form-group d-flex flex-wrap mb-xs-5">
              <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                <label htmlFor="country" className="py-xs-5">
                  Company
                </label>
              </Col>
              <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                <input
                  id="company"
                  className="form-control text-box single-line "
                  maxLength="16"
                  name="company"
                  size="16"
                  type="text"
                  required=""
                />
                <span className="input-error help-block field-validation-error"></span>
              </Col>
            </div>
            <div id="countryOptin" className="form-group d-none">
              <label htmlFor="mobileSMSOptIn">
                <input
                  className="d-inline mb-0 mr-10"
                  type="checkbox"
                  id="mobileSMSOptIn"
                  name="mobileSMSOptIn"
                  value="true"
                />
                <p className="d-inline text-muted specter-regular">
                  ADP may contact me about its products, services, and offers. Options to
                  unsubscribe and manage your communication preferences will be provided to you in
                  these communications.
                </p>
              </label>
            </div>
            <Col xs={12} className="col-xs-12 px-0 mt-30">
              <p style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <button
                  id="lead-submitHF"
                  type="submit"
                  className="btn btn-primary cta-click"
                  data-label="talk_to_sales"
                  data-section="demo_above_footer"
                  data-header="have_questions_about_roll"
                >
                  {fields.CTAButtonText?.value ? fields.CTAButtonText.value : 'CONTACT ME'}
                </button>
              </p>
              <div>
                <p style={{ fontSize: '14px' }}>
                  By submitting this form you are informed that ADP may contact you about its
                  products, services, and offers, according to our Privacy statement for Business
                  contacts.
                </p>
              </div>
              <div
                className="light-blue-bg hidden-xs"
                style={{ zIndex: '-1', top: '-25px', left: '-120px' }}
              >
                &nbsp;
              </div>
            </Col>
            <div>
              <RichText field={fields.DisclaimerText} />
            </div>
          </form>
        </div>
      </Col>
    </>
  );
};

export default FormComponent;
