import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import RowContainer from './components/RowContainer';

import loadable from '@loadable/component';
const OwlCarou = loadable(() => import('./components/OwlCarousel'), { ssr: false });

const YouMightAlsoLike = (props) => {
  const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1 };

  // console.log(props);
  return (
    <Container fluid className="might__like">
      <Container fluid className="might__like__container">
        <h4>You Might Also Like</h4>

        <RowContainer className="RowContainer" posts={props.fields.data.blogPosts.results.items} />

        <OwlCarou posts={props.fields.data.blogPosts.results.items} />
      </Container>
    </Container>
  );
};

export default withSitecoreContext()(YouMightAlsoLike);
