import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { withSitecoreContext, Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
// import FullWidthPromo from './FullWidthPromoExtracted';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FullWidthPromo from '../../FullWidthPromo/index';
import Newsletter from '../../Newsletter';
import HalfWidthPromo from '../../HalfWidthPromo';
import { useQuery, gql } from '@apollo/client';
import { loader as gqlLoader } from 'graphql.macro';
import { post } from 'jquery';

const PostBodyContent = ({
  sitecoreContext: {
    route: { fields },
    props,
  },
}) => {
  let postValue = fields.PostBody.value;

  const promoregex = /{{promo}}|{{newsletter}}/gim;
  const promos = postValue.match(promoregex);

  let chunks = [];
  let idx = 0;
  let promoidx = 0;
  if (promos) {
    promos.forEach((p) => {
      // get everything before the promo and put it in the chunks
      const endpoint = postValue.indexOf(p);

      chunks.push(
        <div
          key={idx}
          className="post__body__content"
          dangerouslySetInnerHTML={{ __html: postValue.substring(0, endpoint) }}
        />
      );
      idx++;
      if (p.startsWith('{{promo')) {
        chunks.push(<HalfWidthPromo key={idx} item={fields.Promo[promoidx].id} />);
        idx++;
        promoidx++;
      } else if (p.startsWith('{{newsletter')) {
        chunks.push(<Newsletter key={idx} />);
        idx++;
      }
      postValue = postValue.substring(endpoint + p.length);
    });
    // console.log(chunks);
  }
  chunks.push(
    <div
      className="post__body__content"
      dangerouslySetInnerHTML={{ __html: postValue }}
      key={idx}
    />
  );

  return chunks;
};

export default withSitecoreContext()(PostBodyContent);
