import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SelectLanguage = () => {
  useEffect(() => {
    const origin = window.location.origin;
    const link = document.querySelector('#languageDiv .user-locale span');
    if (origin.indexOf('//es.') > 0) {
      link.innerHTML = 'English';
    } else {
      link.innerHTML = 'Español';
    }
  });

  const chooser = (e) => {
    let url = window.location.href;
    if (url.indexOf('//es.') > 0) {
      window.location.href = url.replace('es.', 'www.');
    } else {
      window.location.href = url.replace('www.', 'es.');
    }
    return false;
  };

  return (
    <Container className="pt-10 pb-10 mb-xs-0 mb-sm-10 mb-md-0 bb-gray-e9" fluid>
      <Row as="section" className="hero-wrap center-block">
        <Col xs={4} className="col-xs-4">
          &nbsp;
        </Col>
        <Col xs={8} className="col-xs-8">
          <div id="languageDiv">
            <span className="header-phone">
              <a
                className="user-locale mr-20 mr-xs-0"
                onClick={chooser}
                title="Global Switch"
                style={{ textAlign: 'right' }}
              >
                <span
                  data-header="language_chooser"
                  data-label="Select Language"
                  data-section="nav"
                  className="cta-click"
                  style={{ fontSize: '12px' }}
                >
                  Select Language
                </span>
              </a>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SelectLanguage;
