import React, { Fragment } from 'react';
import { Image, RichText, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Picture from '../../lib/Picture';

const CampaignAlternatePromoWithQRCodeComponent = ({ rendering, sitecoreContext, fields }) => {
  // Sitecore Fields
  if (!fields) {
    return null;
  }
  const sitecorePricingFields = sitecoreContext.pricing.sitecore.route.fields;

  // Rendering Info
  const uid = rendering.uid;
  const dataSource = rendering.dataSource;
  const componentName = rendering.componentName;

  // App Stores
  const playStoreURL = sitecorePricingFields.AppleAppStoreLink;
  const appStoreURL = sitecorePricingFields.GoogleAppStoreLink;
  const playStore = sitecorePricingFields.AppleStoreButtonImage;
  const appStore = sitecorePricingFields.GooglePlayStoreButtonImage;
  const mobilePlayStore = sitecorePricingFields.AppleStoreButtonMobileImage;
  const mobileAppStore = sitecorePricingFields.GooglePlayStoreButtonMobileImage;

  const qrCode = sitecoreContext.route.fields.QRCodeImage;
  const mobileButtonLink = sitecoreContext.route.fields.FirebaseLink ?? fields.MobileButtonLink;

  return (
    <Fragment>
      <style>
        {`
          .${componentName}-${uid} .card-box {
            padding:2rem;
            width:50%;
          }

          .${componentName}-${uid} .btn-default {
            background-color:#fff;
            border-color:#fff;
            color:#121c4e;

          }
          
          .${componentName}-${uid} .btn-default:hover {
            background-color:#fce3df;
            border-color:#fce3df;
            color:#121c4e;

          }
          
          .${componentName}-${uid} .btn {
            font-family: Specter_regular, Arial, sans-serif;
          }
          
          .${componentName}-${uid} .mobile-app-store-img {
            max-width:100%;
          }
    `}
      </style>
      <Container
        data-uid={uid}
        data-component={componentName}
        data-source={dataSource}
        className={`${componentName}-${uid} bg-purple-4300D5 py-sm-60 pb-xs-100 pt-xs-60`}
        fluid
      >
        <Row className="mx-auto no-gutters align-items-center justify-content-center width1050">
          <Col sm={6} className="col-xs-12 text-xs-center text-sm-left align-self-center">
            <div className="mx-auto">
              <RichText tag="h3" className="font48 white" field={fields.Title} />
              <RichText className="p white" field={fields.SubTitle} />

              <Link className="btn btn-default py-15 d-xs-block d-sm-none" field={mobileButtonLink}>
                {fields.MobileButtonText.value}
              </Link>

              <RichText className="p white pt-xs-20" field={fields.Disclaimer} />
            </div>

            {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
              <Row className="pt-xs-10 mx-xs-auto">
                <Col sm={4} className="col-xs-6">
                  <Link className={'android-app-store-link'} target="_blank" field={playStoreURL}>
                    <Picture className="d-xs-none d-sm-block img-fluid mx-auto" field={playStore} />
                  </Link>
                  <Link className={'android-app-store-link'} target="_blank" field={playStoreURL}>
                    <Picture
                      className="d-xs-block d-sm-none img-fluid mx-auto mobile-app-store-img"
                      field={mobilePlayStore}
                    />
                  </Link>
                </Col>
                <Col sm={4} className="col-xs-6">
                  <Link className={'apple-app-store-link'} target="_blank" field={appStoreURL}>
                    <Picture className="d-xs-none d-sm-block img-fluid mx-auto" field={appStore} />
                  </Link>
                  <Link className={'apple-app-store-link'} target="_blank" field={appStoreURL}>
                    <Picture
                      className="d-xs-block d-sm-none img-fluid mx-auto mobile-app-store-img"
                      field={mobileAppStore}
                    />
                  </Link>
                </Col>
              </Row>
            )}
          </Col>
          <Col sm={6} className="col-xs-12 align-self-center">
            <div className={'qr-code-wrapper'}>
              <Picture
                className="img-fluid mx-auto card-box d-xs-none d-sm-block qr-code"
                field={qrCode}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default withSitecoreContext()(CampaignAlternatePromoWithQRCodeComponent);
