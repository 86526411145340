import React from 'react';
import { Link, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavLinks from '../../lib/NavLinks';
import Picture from '../../lib/Picture';
import loadable from '@loadable/component';

const ModalButton = loadable(() => import('../TalkToSalesForm/components/ModalButton'), {
  ssr: false,
});

import './css/style.css';

const FooterNavigation = ({ sitecoreContext, fields }) => {
  const { footer } = fields.data;

  return (
    <Container fluid className="bg-white pt-90" key="content">
      <footer role="contentinfo">
        <Row as="nav" className="center-block" role="navigation">
          <Row className="center-block mb-50">
            <Col xs={12} sm={6} className="col-xs-12">
              <Link field={footer.logoLink.jss} className="mx-sm-auto mx-xs-auto mx-md-0 mb-xs-24">
                <Picture className="img-responsive" field={footer.logo.jss} loading="lazy" />
              </Link>
            </Col>
            {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
              <>
                <div className="hidden-xs">
                  <Col
                    xs={12}
                    sm={6}
                    className="col-xs-12 d-flex"
                    style={{ flexFlow: 'row-reverse' }}
                  >
                    <a
                      href={footer.googlePlayStoreLink.jss.value.href}
                      target="_blank"
                      rel="noreferrer"
                      className="ml-20 cta-click"
                      data-label="get_it_on_google_play"
                      data-section="footer"
                      data-header="footer"
                    >
                      <Picture
                        className="img-responsive"
                        field={footer.googlePlayStoreImage.jss}
                        loading="lazy"
                      />
                    </a>
                    <a
                      href={footer.appleAppStoreLink.jss.value.href}
                      target="_blank"
                      rel="noreferrer"
                      className="cta-click"
                      data-label="download_on_the_app_store"
                      data-section="footer"
                      data-header="footer"
                    >
                      <Picture
                        className="img-responsive"
                        field={footer.appleAppStoreImage.jss}
                        loading="lazy"
                      />
                    </a>
                  </Col>
                </div>
                <div className="visible-xs">
                  <Col xs={12} sm={6} className="col-xs-12 d-flex">
                    <a
                      href={footer.appleAppStoreLink.jss.value.href}
                      target="_blank"
                      rel="noreferrer"
                      className="mr-20 cta-click"
                      data-label="download_on_the_app_store"
                      data-section="footer"
                      data-header="footer"
                    >
                      <Picture
                        className="img-responsive"
                        field={footer.appleAppStoreImage.jss}
                        loading="lazy"
                      />
                    </a>
                    <a
                      href={footer.googlePlayStoreLink.jss.value.href}
                      target="_blank"
                      rel="noreferrer"
                      className="cta-click"
                      data-label="get_it_on_google_play"
                      data-section="footer"
                      data-header="footer"
                    >
                      <Picture
                        className="img-responsive"
                        field={footer.googlePlayStoreImage.jss}
                        loading="lazy"
                      />
                    </a>
                  </Col>
                </div>
              </>
            )}
          </Row>
          <Row className="center-block">
            <Col xs={12} md={3} className="col-xs-12">
              <ul className="list-unstyled dot-border">
                <li className="relative drop-footer-menu">
                  <a
                    className="dropbtnfooter has-sub-menu bold hidden-md"
                    aria-controls="ft-resourceDropdown-1"
                  >
                    {footer.column1Content.targetItem.name}
                  </a>
                  <a
                    className="dropbtnfooter has-sub-menu bold visible-md"
                    aria-controls="ft-resourceDropdown-1"
                    {...(footer.column1Content.targetItem.link.jss.value.href && {
                      href: footer.column1Content.targetItem.link?.jss?.value?.href,
                    })}
                  >
                    {footer.column1Content.targetItem.title.jss.value === ''
                      ? footer.column1Content.targetItem.name
                      : footer.column1Content.targetItem.title.jss.value}
                  </a>
                  <div
                    className="footer-dropmenu-content ft-resourceDrpdn"
                    id="ft-resourceDropdown-1"
                    tabIndex="-1"
                  >
                    <NavLinks navLinks={footer.column1Content.targetItem.children} />
                  </div>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={2} className="col-xs-12">
              <ul className="list-unstyled dot-border">
                <li className="relative drop-footer-menu">
                  <a
                    className="dropbtnfooter has-sub-menu bold hidden-md"
                    aria-controls="ft-resourceDropdown-2"
                  >
                    {footer.column2Content.targetItem.name}
                  </a>
                  <a
                    className="dropbtnfooter has-sub-menu bold visible-md"
                    aria-controls="ft-resourceDropdown-2"
                    {...(footer.column2Content.targetItem.link.jss.value.href && {
                      href: footer.column2Content.targetItem.link?.jss?.value?.href,
                    })}
                  >
                    {footer.column2Content.targetItem.title.jss.value === ''
                      ? footer.column2Content.targetItem.name
                      : footer.column2Content.targetItem.title.jss.value}
                  </a>
                  <div
                    className="footer-dropmenu-content ft-resourceDrpdn"
                    id="ft-resourceDropdown-2"
                    role="region"
                    tabIndex="-1"
                  >
                    {/* <LinkList links={props.targetItem} /> */}
                    <NavLinks navLinks={footer.column2Content.targetItem.children} />
                  </div>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={2} className="col-xs-12">
              <ul className="list-unstyled dot-border">
                {footer.column3Content.targetItem ? (
                  <li className="relative drop-footer-menu">
                    <a
                      className="dropbtnfooter has-sub-menu bold hidden-md"
                      aria-controls="ft-resourceDropdown-3"
                    >
                      {footer.column3Content.targetItem.name}
                    </a>
                    <a
                      className="dropbtnfooter has-sub-menu bold visible-md"
                      aria-controls="ft-resourceDropdown-3"
                      {...(footer.column3Content.targetItem.link.jss.value.href && {
                        href: footer.column3Content.targetItem.link?.jss?.value?.href,
                      })}
                    >
                      {footer.column3Content.targetItem.title.jss.value === ''
                        ? footer.column3Content.targetItem.name
                        : footer.column3Content.targetItem.title.jss.value}
                    </a>
                    <div
                      className="footer-dropmenu-content ft-resourceDrpdn"
                      id="ft-resourceDropdown-3"
                      role="region"
                      tabIndex="-1"
                    >
                      <NavLinks navLinks={footer.column3Content.targetItem.children} />
                    </div>
                  </li>
                ) : null}
              </ul>
            </Col>
            <Col xs={12} md={2} className="col-xs-12">
              <ul className="list-unstyled dot-border dot-border-bottom pb-10">
                {footer.column4Content.targetItem ? (
                  <li className="relative drop-footer-menu">
                    <a
                      className="dropbtnfooter has-sub-menu bold hidden-md"
                      aria-controls="ft-resourceDropdown-4"
                    >
                      {footer.column4Content.targetItem.name}
                    </a>
                    <a
                      className="dropbtnfooter has-sub-menu bold visible-md"
                      aria-controls="ft-resourceDropdown-4"
                      {...(footer.column4Content.targetItem.link.jss.value.href && {
                        href: footer.column4Content.targetItem.link?.jss?.value?.href,
                      })}
                    >
                      {footer.column4Content.targetItem.title.jss.value === ''
                        ? footer.column4Content.targetItem.name
                        : footer.column4Content.targetItem.title.jss.value}
                    </a>
                    <div
                      className="footer-dropmenu-content ft-resourceDrpdn"
                      id="ft-resourceDropdown-4"
                      role="region"
                      tabIndex="-1"
                    >
                      <NavLinks navLinks={footer.column4Content.targetItem.children} />
                    </div>
                  </li>
                ) : null}
              </ul>
            </Col>
            <Col xs={12} md={3} className="col-xs-12 hidden-sm">
              {footer.column5Content.targetItem ? (
                <ul className="list-unstyled hidden-sm">
                  <li>
                    <strong>
                      {footer.column5Content.targetItem.title.jss.value === ''
                        ? footer.column5Content.targetItem.name
                        : footer.column5Content.targetItem.title.jss.value}
                    </strong>
                    <br />
                  </li>
                  <li>
                    <NavLinks navLinks={footer.column5Content.targetItem.children} />
                  </li>
                  <ModalButton />
                </ul>
              ) : null}
            </Col>
          </Row>
          <Row className="center-block">
            <Col xs={12} sm={4} className="col-xs-12 visible-sm">
              {footer.column5Content.targetItem ? (
                <ul className="list-unstyled">
                  <li>
                    <strong>{footer.column5Content.targetItem.name}</strong>
                    <br />
                    <NavLinks noBorder navLinks={footer.column5Content.targetItem.children} />
                  </li>
                  <ModalButton />
                </ul>
              ) : null}
            </Col>
            {footer.column3Content.targetItem &&
            footer.column4Content.targetItem &&
            footer.column5Content.targetItem ? (
              <Col xs={12} sm={8} md={12} className="col-xs-12 hidden-sm">
                <div className="text-center">
                  <ul className="list-inline height-card-img-20 mt-xs-0">
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.facebookLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.facebookImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.twitterLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.twitterImage.jss}
                        />
                      </a>
                    </li>
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.instagramLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.instagramImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.linkedinLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.linkedinImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="pr-xs-0 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.youtubeLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.youtubeImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            ) : (
              <Row className="center-block">
                <div className="hidden-xs" style={{ height: 200 + 'px' }}></div>
              </Row>
            )}
            {footer.column3Content.targetItem &&
            footer.column4Content.targetItem &&
            footer.column5Content.targetItem ? (
              <Col xs={12} sm={8} md={12} className="col-xs-12 hidden-xs d-md-none ">
                <div className="float-right">
                  <ul className="list-inline height-card-img-20 mt-xs-0 mt-md-50">
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.facebookLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.facebookImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.twitterLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.twitterImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.instagramLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.instagramImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="pr-xs-25 pr-40">
                      <a
                        rel="noopener noreferrer"
                        href={footer.linkedinLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.linkedinImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                    <li className="pr-xs-0">
                      <a
                        rel="noopener noreferrer"
                        href={footer.youtubeLink.jss.value.href}
                        target="_blank"
                      >
                        <Picture
                          className="img-responsive mx-auto card-img mb-0"
                          field={footer.youtubeImage.jss}
                          loading="lazy"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            ) : null}
          </Row>
          <Row className="center-block">
            <Col sm={12} className=" px-xs-0">
              <Col xs={12} sm={9} className="col-xs-12 text-center center-block">
                <div>
                  <RichText field={footer.copyright.jss} />
                </div>
                <ul className="legal-links">
                  {footer.footerLinks.targetItem.children.map((i, k) => {
                    return (
                      <li key={k}>
                        <Link field={i.link.jss} />
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Col>
          </Row>
        </Row>
      </footer>
    </Container>
  );
};

export default withSitecoreContext()(FooterNavigation);
