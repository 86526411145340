import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

const Newsletter = (props) => (
  <Container
    fluid
    className="search__form h5"
    aria-label="Newsletter form container"
    role="combobox"
  >
    {/* <Text field={props.fields.heading} /> */}
    <Form aria-label="Sign up for our newsletter - form" role="form">
      <h4 className="newsletter-h4">Subscribe to our newsletter</h4>
      <input type="email" placeholder="Your email" aria-label="Your email" alt="Your email"></input>
      <Button aria-label="Subscribe button">SUBSCRIBE</Button>
    </Form>
    {/* <Button text={props.fields.buttonText.value}></Button> */}
  </Container>
);

export default Newsletter;
