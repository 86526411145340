import React, { Fragment } from 'react';
import { RichText, Text, Link, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';
import CTAArea from '../../lib/CTAArea';
import Picture from '../../lib/Picture';

// Code Splitted imports
const InitializeLity = loadable(() => import('../../lib/InitLity'), { ssr: false });
const RefFormLogic = loadable(() => import('./RefFormLogic'), { ssr: false });
const InitializeRefGenFormComponent = loadable(() => import('./client'), { ssr: false });

const SEMHeroComponent = ({ sitecoreContext, fields, rendering }) => {
  // QR Code
  if (!fields) {
    return null;
  }
  const qrCode = fields.QRCode.value?.src
    ? fields.QRCode
    : sitecoreContext.route.fields.QRCodeImage;

  // Terms of use URL
  const termsOfUse = sitecoreContext.pricing.sitecore.route.fields.StandardTermsLink;

  // Rendering Info
  let appName = sitecoreContext.site.name;

  const componentName = rendering.componentName;
  const uid = rendering.uid;
  const dataSource = rendering.dataSource;

  // App stores
  const LogoPlayStore = sitecoreContext.pricing.sitecore.route.fields.GooglePlayStoreButtonImage;
  const LogoAppleStore = sitecoreContext.pricing.sitecore.route.fields.AppleStoreButtonImage;

  // Pricing Info
  const perEmployeePricee = sitecoreContext.pricing.sitecore.route.fields.PerEmployeePrice.value;
  const pricePer = sitecoreContext.pricing.sitecore.route.fields.PricePer.value;
  const basePrice = sitecoreContext.pricing.sitecore.route.fields.BasePrice.value;

  return [
    <InitializeLity key="init-video-client" />,
    <InitializeRefGenFormComponent key="ref-client" lang={sitecoreContext.language} />,
    <RefFormLogic key="form-logic" />,
    <Fragment key="hero">
      <style>
        {`
        .${componentName}-${uid}{
            background: url(${fields.MobileBackgroundImage.value.src})  no-repeat ;
            background-size: contain;
            background-position:center top ;

          }

        @media (min-width:768px){
          .${componentName}-${uid}{
            background: url(${fields.BackgroundImage.value.src})  no-repeat ;
            background-size: contain;
            background-position:center top ;

          }
        }

        .${componentName}-${uid} .card-box{
          box-shadow:0px 0px 15px rgba(0 0 0 / 20%)
        }
        
        @media (max-width:767px){
          .${componentName}-${uid} #form-container{
            max-width:80%;
            margin:0 auto;
          }
          
          .${componentName}-${uid} .col-one{
            max-width:80%;
            margin:0 auto;
          }
        }

        @media (min-width:360px){
          .sem-partner-hero-bottom-half {
            margin-top:50px
          }
        }
        
        @media (min-width:415px){
          .sem-partner-hero-bottom-half {
            margin-top:175px
          }
        }
        
        @media (min-width:575px){
          .sem-partner-hero-bottom-half {
            margin-top:325px
          }
        }
        
        @media (min-width:768px){
          .sem-partner-hero-bottom-half {
            margin-top:25px
          }
        }
        @media (min-width:1219px){
          .sem-partner-hero-bottom-half {
            margin-top:75px
          }
        }
         
        @media (min-width:1350px){
          .sem-partner-hero-bottom-half {
            margin-top:200px
          }
        }
        
        @media (min-width:1832px){
          .sem-partner-hero-bottom-half {
            margin-top:300px
          }
        }        
        
        @media (min-width:2165px){
          .sem-partner-hero-bottom-half {
            margin-top:400px
          }
        }

      }`}
      </style>
      <Container
        data-source-id={dataSource}
        data-component={componentName}
        data-uid={uid}
        fluid
        className={`p-0 text-center ${componentName}-${uid}`}
      >
        <Container className="text-left p-sm-0  sem-hero-logo-container">
          <Picture className="img-fluid d-xs-none d-sm-block" field={fields.HeroLogo} />
          <Picture className="img-fluid d-xs-block d-sm-none" field={fields.MobileHeroLogo} />
        </Container>
        <Container className="text-center white py-0 sem-partner-hero-top-half">
          <RichText tag="h1" className="font48 mt-0" field={fields.Title} />
          <RichText className="p" field={fields.SubTitle} />
          <p className="white  d-flex justify-content-center align-items-center">
            <Text field={fields.Tout1} />
            <span className="px-20">
              <Link field={fields.VideoURL} data-lity>
                <Picture field={fields.PlayImage} />
              </Link>
            </span>
            <Text field={fields.Tout2} />
          </p>
        </Container>
        <Container className="sem-partner-hero-bottom-half">
          <div className="roll-link form-container text-left round-box" aria-label="Container">
            <Container className="center-block card-box h-auto text-center">
              <FormHeader FormHeading={fields.FormHeading} />
              <Row className="mx-0 px-sm-50">
                <ColOne
                  qrCode={qrCode}
                  fields={fields}
                  basePrice={basePrice}
                  pricePer={pricePer}
                  perEmployeePricee={perEmployeePricee}
                  sitecoreContext={sitecoreContext}
                  MobileFormHeading={fields.MobileFormHeading}
                  LogoPlayStore={LogoPlayStore}
                  LogoAppleStore={LogoAppleStore}
                />
                <ColTwo
                  appName={appName}
                  termsOfUse={termsOfUse}
                  LogoPlayStore={LogoPlayStore}
                  LogoAppleStore={LogoAppleStore}
                />
              </Row>
            </Container>
          </div>
          <AsSeenIn AsSeenInHeading={fields.AsSeenInHeading} Images={fields.Images} />
        </Container>
      </Container>
    </Fragment>,
  ];
};

const FormHeader = ({ FormHeading }) => {
  return (
    <div className="pb-sm-40 d-xs-none d-sm-block">
      <RichText tag="h3" field={FormHeading} />
    </div>
  );
};

const FormFooter = ({ termsLink }) => {
  return (
    <Fragment>
      <Col xs={12} className="col-xs-12 pt-15 text-right text-xs-center ">
        <small>
          {termsLink ? (
            <Link className="popup-offer terms" field={termsLink} />
          ) : (
            <a className="popup-offer terms" href="/terms">
              *See the terms and conditions
            </a>
          )}
        </small>
      </Col>
      <div className="close-icn" aria-label="Close icon" role="icon">
        &nbsp;
      </div>
    </Fragment>
  );
};

const ColTwo = ({ appName, termsOfUse, LogoAppleStore, LogoPlayStore }) => {
  return (
    <Col xs={12} sm={7} className="col-xs-12 px-sm-50 pt-xs-20 pb-xs-0 pl-xs-0 pr-xs-0 pr-0 ">
      <ThankYouMsg />
      <div id="form-container">
        <p className="lead mb-20 text-left">
          <strong>Email me a download link</strong>
        </p>
        <Form appName={appName} termsOfUse={termsOfUse} />
        <Col
          as="a"
          xs={6}
          className="col-xs-6 px-5 text-right d-xs-block d-sm-none"
          href="https://ad.doubleclick.net/ddm/clk/504530174;312010110;z"
          target="_blank"
          rel="noreferrer"
        >
          <Picture
            field={LogoAppleStore}
            alt="App Store"
            data-heightmissing=""
            data-widthmissing=""
            data-lazy={LogoAppleStore}
            className="img-responsive"
          />
        </Col>
        <Col
          as="a"
          xs={6}
          className="col-xs-6 px-5 text-left d-xs-block d-sm-none"
          href="https://ad.doubleclick.net/ddm/clk/504821806;312010110;e"
          target="_blank"
          rel="noreferrer"
        >
          <Picture
            field={LogoPlayStore}
            alt="Play Store"
            data-heightmissing=""
            data-widthmissing=""
            data-lazy={LogoPlayStore}
            className="img-responsive"
          />
        </Col>
      </div>
    </Col>
  );
};

const Form = ({ appName, termsOfUse }) => {
  return (
    <form id="roll-ref-form" name="roll-ref-form" noValidate="novalidate">
      {/* <!-- Required Inputs --> */}

      <input type="hidden" id="hiddensitename" name="hiddensitename" value="olp" />
      <input
        type="hidden"
        id="hiddendestsysid"
        name="hiddendestsysid"
        value="SFDCDestinationSysId"
      />
      <input type="hidden" id="formId" name="formId" value="Demo Form" />
      <input type="hidden" id="sc_site" name="sc_site" value={appName} />
      {/* <!-- End Required Inputs --> */}
      <span className="form-error-field input-error help-block field-validation-error"></span>
      <Row className="required-field field-email form-group  mx-0">
        <Col sm={2} className="px-0 col-xs-3 text-left col-xs-3 ">
          <label htmlFor="email" className="specter-regular">
            Email
          </label>
        </Col>
        <Col sm={10} className="px-0 col-xs-9 text-left col-xs-9 ">
          <input
            id="email"
            className="form-control text-box single-line "
            maxLength="50"
            name="email"
            size="20"
            type="text"
          />
          <span className="input-error help-block field-validation-error"></span>
        </Col>
      </Row>
      <Row className="field-country form-group mx-0 mb-30">
        <Col sm={2} className="px-0 text-left col-xs-3">
          <label htmlFor="country" className="specter-regular">
            Country
          </label>
        </Col>
        <Col sm={10} className="px-0 col-xs-9 ">
          <div className="selectric-wrapper selectric-country-dropdown selectric-form-control selectric-text-box selectric-single-line selectric-below text-left">
            <CountriesSelect />
          </div>
        </Col>
      </Row>

      <span className="input-error help-block field-validation-error"></span>

      <div id="countryOptin" className="form-group d-none">
        <label htmlFor="mobileSMSOptIn">
          <input
            className="d-inline mb-0 mr-10"
            type="checkbox"
            id="mobileSMSOptIn"
            name="mobileSMSOptIn"
            value="true"
          />
        </label>
        <p className="d-inline text-muted specter-regular">
          ADP may contact me about its products, services, and offers. Options to unsubscribe and
          manage your communication preferences will be provided to you in these communications.
        </p>
      </div>
      <Col sm={12} className="px-0 mb-30 text-left">
        <div className="send-now-btn-container">
          <button
            id="lead-submit"
            type="submit"
            className="btn btn-primary btn-sm px-60 cta-click "
            data-label="send_now"
            data-section="get_link_modal"
            data-header="email_me_a_download_link"
          >
            SEND NOW
          </button>
        </div>
      </Col>
      <p className="mb-20 text-left  terms-no-underline">
        By clicking Send Now, you acknowledge that you have read our{' '}
        <a
          rel="noopener noreferrer"
          className=" terms-no-underline"
          target="_blank"
          href="https://www.adp.com/privacy.aspx"
        >
          Privacy Statement
        </a>{' '}
        and agree to our{' '}
        <Link className=" terms-no-underline" target="_blank" field={termsOfUse}>
          Terms of Use
        </Link>
        .
      </p>
    </form>
  );
};

const ThankYouMsg = () => (
  <div className="thanks-box d-none">
    <Col xs={12} className="col-xs-12 py-60 mb-60 px-0">
      <p className="h3">Success!</p>
      <p className="lead">You will receive an email with a download link shortly.</p>
    </Col>
  </div>
);

const CountriesSelect = () => (
  <select
    id="LeadGenCountry"
    className="country-dropdown form-control text-box single-line cta-click"
    tabIndex="-1"
  ></select>
);

const AsSeenIn = ({ AsSeenInHeading, Images }) => {
  return (
    <Container className="py-sm-60 pt-xs-20 pb-xs-40 px-90 text-center">
      <Row className="mx-0 justify-content-center align-items-center align-middle">
        <Col xs={12} sm={12} md={2} className="py-25">
          <p className="lead text-nowrap pr-sm-10 align-middle mb-0 ">
            <Text field={AsSeenInHeading} />
          </p>
        </Col>
        <Col xs={12} sm={12} md={10} className="px-0">
          <Row className="mx-auto justify-content-center align-items-center align-middle">
            {Images.map((v, k) => {
              return (
                <Col xs={6} sm={6} md={3} className="p-0 col-xs-6" key={k}>
                  <Picture className="img-fluid" field={v.fields.Image} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const ColOne = (props) => {
  const {
    qrCode,
    basePrice,
    pricePer,
    perEmployeePricee,
    MobileFormHeading,
    fields,
    sitecoreContext,
    LogoAppleStore,
    LogoPlayStore,
  } = props;

  const currencyValue = sitecoreContext.pricing.sitecore.route.fields.Currency?.value ?? '$';

  return (
    <Col xs={12} sm={5} className=" pr-sm-40 pl-xs-0 pt-xs-0 pr-xs-0 pb-xs-20 pl-0 vr col-xs-12">
      <div className="text-center col-one">
        <div className="d-xs-none d-sm-block">
          <p className="lead mb-20 text-center">
            <strong>Download from app store</strong>
          </p>
          <p className="mb-5">Scan with your phone</p>
          <Picture className="mx-auto img-responsive mb-30 img-fluid qr-code" field={qrCode} />

          {
            // app stores
          }
          <Col
            as="a"
            xs={6}
            className="col-xs-6 px-5 text-right"
            href="https://ad.doubleclick.net/ddm/clk/504530174;312010110;z"
            target="_blank"
            rel="noreferrer"
          >
            <Picture
              field={LogoAppleStore}
              alt="App Store"
              data-heightmissing=""
              data-widthmissing=""
              data-lazy={LogoAppleStore}
              className="img-responsive"
            />
          </Col>
          <Col
            as="a"
            xs={6}
            className="col-xs-6 px-5 text-left"
            href="https://ad.doubleclick.net/ddm/clk/504821806;312010110;e"
            target="_blank"
            rel="noreferrer"
          >
            <Picture
              field={LogoPlayStore}
              alt="Play Store"
              data-heightmissing=""
              data-widthmissing=""
              data-lazy={LogoPlayStore}
              className="img-responsive"
            />
          </Col>
        </div>
        <div className="d-xs-block d-sm-none">
          <RichText tag="h3" className="font48" field={MobileFormHeading} />
          <p className="lead">{`then just ${currencyValue}${basePrice}/${pricePer} + ${currencyValue}${perEmployeePricee} per employee`}</p>
          {fields.ButtonText?.value && (
            <CTAArea
              pricingData={sitecoreContext.pricing.sitecore.route.fields}
              fields={fields}
            ></CTAArea>
          )}
        </div>
      </div>
    </Col>
  );
};

export default withSitecoreContext()(SEMHeroComponent);
