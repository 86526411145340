import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Breadcrumbs = ({ breadcrumbs }) => {
  const thisPage = breadcrumbs;
  console.log('thispage', thisPage);
  const parent = thisPage?.parent;
  if (thisPage && parent) {
    return [
      <p className="roll-breadcrumb ml-15 hidden-xs" key="desktop">
        <span className="bread-start pr-5">
          <a href={parent.url}>
            <strong>{parent.breadcrumbName?.value}</strong>
          </a>
        </span>
        &gt;
        <span className="bread-active pl-5">
          <strong>{thisPage.breadcrumbName?.value}</strong>
        </span>
      </p>,
      <p className="roll-breadcrumb visible-xs" key="mobile">
        <span className="bread-start pr-5">
          <a href={parent.url}>
            <strong>{parent.breadcrumbName?.value}</strong>
          </a>
        </span>
        &gt;
        <span className="bread-active pl-5">
          <strong>{thisPage.breadcrumbName?.value}</strong>
        </span>
      </p>,
    ];
  }
  return null;
};

export default withSitecoreContext()(Breadcrumbs);
