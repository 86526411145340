import React from 'react';
import { withSitecoreContext, RichText, Text, Image } from '@sitecore-jss/sitecore-jss-react';
import CTAArea from '../../lib/CTAArea';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './css/style.css';
import Picture from '../../lib/Picture';

const ThreeColumnComponentWithCTA = ({ sitecoreContext, fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const ShowWhiteBox = fields.ShowWhiteBox.value ? 'card-box-whitebg' : 'card-box-nobg';
  const isHidden =
    rendering?.params?.HideOnMobile === '1' ? 'd-sm-none d-xs-none' : 'd-xs-block d-sm-block';
  const isHiddenDesktop = rendering?.params?.HideOnDesktop === '1' ? 'd-md-none' : 'd-md-block';
  const useCarousel = rendering?.params?.UseCarouselOnMobile === '1';

  return (
    <Container
      fluid
      className={`purple-bg-bottom px-xs-30 ${isHidden} ${isHiddenDesktop}`}
      data-component={rendering.componentName}
      data-source-id={rendering.dataSource}
      data-uid={rendering.uid}
      key="content"
    >
      <Row
        as="section"
        className={`center-block text-center ${ShowWhiteBox} py-100 py-xs-40 px-xs-24 ${
          useCarousel ? 'hidden-xs' : ''
        }`}
      >
        <Col sm={12} className="center-block d-flex flex-wrap px-xs-0 mb-30">
          <Row>
            <Col xs={12} className="col-xs-12 mb-40 px-xs-0 ">
              <h2>
                <RichText field={fields.Title} />
              </h2>
              {fields.SubTitle?.value && (
                <div className="lead">
                  <RichText field={fields.SubTitle} />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Cards cards={fields.CardList} />
          </Row>
        </Col>
        {fields.ButtonText?.value && (
          <CTAArea
            pricingData={sitecoreContext.pricing.sitecore.route.fields}
            fields={fields}
          ></CTAArea>
        )}
      </Row>
      {useCarousel && (
        <Row as="section" className={'row center-block text-center visible-xs'}>
          <div className="col-xs-12 center-block">
            <h2 className="size20">
              <RichText field={fields.Title} />
            </h2>
          </div>
          <div className="js-carousel-cards">
            <CarouselCards cards={fields.CardList} />
          </div>
        </Row>
      )}
    </Container>
  );
};

const Cards = ({ cards }) => {
  return cards?.slice(0, 3).map((c, k) => (
    <Col xs={12} sm={4} className="col-xs-12 px-xs-0" key={c.id}>
      <Picture className="d-inline" field={c.fields.Image} loading="lazy" />

      {k === 0 ? (
        <a
          data-label="start_free_trial"
          data-section="home_ready_to_roll"
          data-header="ready_to_roll"
          className="trigger-modal trigger-info black cta-click d-inline-block"
          data-modalsource="free-trial-button"
        >
          <span className="lead">
            <strong>
              <RichText field={c.fields.Title} />
            </strong>
          </span>
        </a>
      ) : (
        <span className="lead d-inline-block px-5 black">
          <strong>
            <RichText field={c.fields.Title} />
          </strong>
        </span>
      )}

      {c.fields.SubTitle?.value && (
        <div className="pt-30 pt-xs-20 mb-xs-40 lead">
          <RichText field={c.fields.SubTitle} />
        </div>
      )}
    </Col>
  ));
};

const CarouselCards = ({ cards }) => {
  return cards?.slice(0, 3).map((c) => (
    <div className="px-10" key={c.id}>
      <div className="card-box p-15" style="height:360px">
        <Picture className="mx-auto lazy" field={c.fields.Image} loading="lazy" />
        <p className="h4 mt-xs-0">
          <RichText tag="strong" field={c.fields.Title} />
        </p>
        <RichText className="lead" field={c.fields.SubTitle} />
      </div>
    </div>
  ));
};

export default withSitecoreContext()(ThreeColumnComponentWithCTA);
