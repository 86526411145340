import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Picture from '../../../lib/Picture';

const FormComponent = ({ sitecoreContext, fields }) => {
  const className = fields.SubTitle?.value ? 'mb-0' : 'mb-20';
  return (
    <Container className="bg-white pb-0" fluid key="form">
      <Row as="section" className="center-block">
        <Col xs={12} sm={5} className="col-xs-12 d-xs-flex flex-xs-column-reverse">
          <h2 className={`${className}`}>
            <RichText field={fields.Title} />
          </h2>
          {fields.SubTitle?.value && <RichText className="mb-20" field={fields.SubTitle} />}
          <Picture className="img-logo" field={fields.Image} loading="lazy" />
        </Col>
        <Col sm={1} className="hidden-xs"></Col>
        <Col xs={12} sm={6} className="col-xs-12 mb-60">
          <div className="thanks-box d-none">
            <Col xs={12} className="col-xs-12 py-100">
              <p className="h3">Success!</p>
              <p className="lead">
                Thanks for reaching out.
                <br />
                Someone from <strong>Team Roll</strong> will reach out shortly.
              </p>
              <p>You will receive a confirmation email momentarily.</p>
            </Col>
          </div>
          <div id="form-container" className="">
            <form id="roll-demo-form-footer" name="leadform" noValidate="novalidate">
              <input type="hidden" id="hiddensitename" name="hiddensitename" value="olp" />
              <input type="hidden" id="sc_site" name="sc_site" value={sitecoreContext.site.name} />
              <input
                type="hidden"
                id="hiddendestsysid"
                name="hiddendestsysid"
                value="SFDCDestinationSysId"
              />
              <input type="hidden" id="formId" name="formId" value="Demo Form" />

              <span className="form-error-field input-error help-block field-validation-error"></span>
              <div className="required-field form-group d-flex flex-wrap mb-xs-5">
                <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                  <label htmlFor="firstName" className="py-xs-5">
                    First Name
                  </label>
                </Col>
                <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                  <input
                    id="firstName"
                    className="form-control  name-field"
                    maxLength="30"
                    name="firstName"
                    size="20"
                    type="text"
                    required=""
                  />
                  <span className="input-error help-block field-validation-error"></span>
                </Col>
              </div>
              <div className="required-field form-group d-flex flex-wrap mb-xs-5">
                <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                  <label htmlFor="lastName" className="py-xs-5">
                    Last Name
                  </label>
                </Col>
                <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                  <input
                    id="lastName"
                    className="form-control name-field"
                    maxLength="30"
                    name="lastName"
                    size="20"
                    type="text"
                    required=""
                  />
                  <span className="input-error help-block field-validation-error"></span>
                </Col>
              </div>
              <div className="required-field field-email form-group d-flex flex-wrap mb-xs-5">
                <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                  <label htmlFor="email" className="py-xs-5">
                    Email
                  </label>
                </Col>
                <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                  <input
                    id="email"
                    className="form-control text-box single-line "
                    maxLength="50"
                    name="email"
                    size="20"
                    type="text"
                    required=""
                  />
                  <span className="input-error help-block field-validation-error"></span>
                </Col>
              </div>
              <div className="required-field field-phone form-group d-flex flex-wrap mb-xs-5">
                <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                  <label htmlFor="mobileNumber" className="py-xs-5">
                    Phone Number
                  </label>
                </Col>
                <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                  <input
                    id="mobileNumber"
                    className="form-control text-box single-line "
                    maxLength="16"
                    name="mobileNumber"
                    size="16"
                    type="tel"
                    required=""
                  />
                  <span className="input-error help-block field-validation-error"></span>
                </Col>
              </div>
              <div className="field-country form-group ">
                <label className="d-flex flex-wrap">
                  <Col xs={12} sm={4} className="col-xs-12 px-0 d-flex flex-wrap">
                    <label htmlFor="country" className="py-xs-5">
                      Country
                    </label>
                  </Col>
                  <Col xs={12} sm={8} className="col-xs-12 px-0 d-flex flex-wrap">
                    <select
                      placeholder="United States"
                      id="country"
                      value="United States"
                      className="country-dropdown form-control text-box single-line"
                      required=""
                    >
                      <option selected="selected">United States</option>
                    </select>
                    <span className="input-error help-block field-validation-error"></span>
                  </Col>
                </label>
              </div>
              <div id="countryOptin" className="form-group d-none">
                <label htmlFor="mobileSMSOptIn">
                  <input
                    className="d-inline mb-0 mr-10"
                    type="checkbox"
                    id="mobileSMSOptIn"
                    name="mobileSMSOptIn"
                    value="true"
                  />
                  <p className="d-inline text-muted specter-regular">
                    ADP may contact me about its products, services, and offers. Options to
                    unsubscribe and manage your communication preferences will be provided to you in
                    these communications.
                  </p>
                </label>
              </div>
              <Col xs={12} className="col-xs-12 px-0">
                <p>
                  <button
                    id="lead-submit"
                    type="submit"
                    className="btn btn-primary cta-click"
                    data-label="talk_to_sales"
                    data-section="demo_above_footer"
                    data-header="have_questions_about_roll"
                  >
                    {fields.CTAButtonText?.value ? fields.CTAButtonText.value : 'TALK TO SALES'}
                  </button>
                </p>
              </Col>
              <div>
                <RichText field={fields.DisclaimerText} />
              </div>
            </form>
          </div>
        </Col>

        <hr className="mb-0 footer-grey" />
      </Row>
    </Container>
  );
};

export default FormComponent;
