import React from 'react';
import { RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CTAArea from '../../lib/CTAArea';
import './css/style.css';
import Picture from '../../lib/Picture';

const IconListWithImageComponent = ({ sitecoreContext, rendering, fields }) => {
  const isAPromo = rendering?.params?.IsAPromo === '1';
  const useAltBg =
    rendering?.params?.UseAlternateBackground === '1' ? ' amz-bg-large ' : 'pb-sm-45';
  if (!fields) {
    return null;
  }
  return (
    <div fluid className={`bg-white ${useAltBg}`}>
      <section className="row center-block py-100 py-xs-0 px-xs-20">
        <div className="col-xs-12 mb-sm-40 text-center px-xs-0">
          <RichText tag="h2" className="mx-auto h2" field={fields.Title} />
          <div className="lead">
            <RichText field={fields.SubTitle} />
          </div>
        </div>

        <div className="col-xs-12 col-sm-11 card-box bg-grey-F7F6F4 center-block d-flex flex-wrap p-sm-0 px-xs-0 pb-xs-0 text-xs-center">
          <div className="col-xs-12 col-sm-6 count-list p-sm-50 pb-sm-40 pt-sm-60">
            <Cards cards={fields.CardList} />
            {fields?.ButtonText?.value && (
              <div className="col-xs-12 text-center pt-20 pb-xs-40">
                <CTAArea
                  pricingData={sitecoreContext.pricing.sitecore.route.fields}
                  fields={fields}
                  routeFields={sitecoreContext.route.fields}
                />
              </div>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 px-0 text-right">
            <Picture className="img-responsive img-radius" field={fields.Image} loading="lazy" />
          </div>
        </div>
      </section>
    </div>
  );
};

const Cards = ({ cards }) => {
  return cards?.map((c) => (
    <div key={c.id}>
      <div className="d-xs-inline-block col-sm-2 px-0 text-right text-xs-left">
        <Picture className="img-responsive" field={c.fields.Image} loading="lazy" />
      </div>
      <div className="d-xs-inline col-sm-10 pl-10 pr-0">
        <div className="d-xs-inline pt-20 pt-xs-20 mb-xs-40 lead text-xs-center ">
          <strong>
            <RichText className="lead d-inline-block mb-10 font25" field={c.fields.Title} />
          </strong>
          <br />
          <RichText className="d-xs-inline-block pt-xs-20 pb-xs-30" field={c.fields.SubTitle} />
        </div>
      </div>
    </div>
  ));

  return cards || null;
};

export default withSitecoreContext()(IconListWithImageComponent);
