import React, { Fragment } from 'react';
import { Image, RichText, Text, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CTAArea from '../../lib/CTAArea';

import customBullet from '../../assets/images/home-global/custom_bullet.png';
import Picture from '../../lib/Picture';

const REFHeroComponent = ({ rendering, sitecoreContext, fields }) => {
  // Sitecore Fields
  if (!fields) {
    return null;
  }
  const sitecorePricingFields = sitecoreContext.pricing.sitecore.route.fields;

  const desktopBg = fields.Image.value?.src;
  const mobileBg = fields.MobileImage.value?.src;

  // Rendering Info
  const uid = rendering.uid;
  const dataSource = rendering.dataSource;
  const componentName = rendering.componentName;

  // App Stores
  const playStoreURL = sitecorePricingFields.AppleAppStoreLink;
  const appStoreURL = sitecorePricingFields.GoogleAppStoreLink;
  const playStore = sitecorePricingFields.AppleStoreButtonImage;
  const appStore = sitecorePricingFields.GooglePlayStoreButtonImage;

  // Roll Logo
  const rollLogo = sitecorePricingFields.RollLogo;

  // QR Code
  const qrCode = sitecoreContext.route.fields.QRCodeImage;

  return (
    <Fragment>
      <style>{`
      .${componentName}-${uid}{
          background:url(${mobileBg});
          background-size:contain;
          background-position:center top;
          background-repeat:no-repeat;
          margin:0 0; 
          padding:1.5rem 3rem 1.5rem 3rem;

        }
      
      .${componentName}-${uid} .font40 {
        font-size: 40px;
      }
 
      .${componentName}-${uid} .p{
        margin:0 0 24px;
      }

      .${componentName}-${uid} .btn {
        background-color:#fff;
        border-color:#fff;
        color:#121c4e;
        font-family: Specter_regular, Arial, sans-serif;
          }
          
      .${componentName}-${uid} .btn:hover {
        background-color:#fce3df;
        border-color:#fce3df;
        color:#121c4e;
          }

    .${componentName}-${uid} ul  {
        list-style:none;
    }    
    
    .${componentName}-${uid} ul li {
        list-style:none;
        margin-bottom:1rem;
    }

    @media (max-width: 767px) {
        .${componentName}-${uid} .font42 {
          font-size: 33px;
        }

        /* Approximate margin - will need to be corrected  */
        .${componentName}-${uid} .second-text-container{
          margin-top:19rem;
        }
      }

    @media (min-width:768px){
      .${componentName}-${uid} ul li:before {
          content: url(${customBullet});
          width:1.5rem;
          display: inline-block;
      }
      
      .${componentName}-${uid}{
          background:url(${desktopBg});
          background-size:contain;
          background-position:center top;
          background-repeat:no-repeat;
          margin:0 0; 
          padding:1.5rem 3rem 1.5rem 3rem;
        }
    }
    `}</style>
      <Container
        data-source={dataSource}
        data-uid={uid}
        data-component={componentName}
        fluid
        className={`${componentName}-${uid} px-sm-60`}
      >
        <Row>
          <Col sm={5} className="align-self-start col-xs-12 px-35">
            <Container className="px-0 py-25">
              <Picture className="img-responsive" field={rollLogo} />
            </Container>
            <div className="py-15">
              <RichText tag="h1" className="font48" field={fields.Title} />
              <RichText field={fields.SubTitle} />
            </div>
            <div className="py-45">
              <div className="card-box d-xs-none d-sm-block">
                <div className={'qr-code-wrapper'}>
                  <Picture className="mx-auto img-responsive qr-code" field={qrCode} />
                </div>
                <Container></Container>
                {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
                  <Row className="mx-auto align-content-center justify-content-center">
                    <Col sm={6} className="col-xs-6">
                      <Link className={'android-app-store-link'} field={playStoreURL}>
                        <Picture className="img-responsive" field={playStore} />{' '}
                      </Link>
                    </Col>
                    <Col sm={6} className="col-xs-6">
                      <Link className={'apple-app-store-link'} field={appStoreURL}>
                        <Picture className="img-responsive" field={appStore} />{' '}
                      </Link>
                    </Col>
                  </Row>
                )}
              </div>
              {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
                <Row className="mx-auto align-content-center justify-content-center d-xs-block d-sm-none">
                  <Col sm={6} className="col-xs-6">
                    <Link className={'android-app-store-link'} field={playStoreURL}>
                      <Picture className="img-responsive" field={playStore} />{' '}
                    </Link>
                  </Col>
                  <Col sm={6} className="col-xs-6">
                    <Link className={'apple-app-store-link'} field={appStoreURL}>
                      <Picture className="img-responsive" field={appStore} />{' '}
                    </Link>
                  </Col>
                </Row>
              )}
            </div>
            <div className="py-15 second-text-container">
              <Container>
                <RichText tag="h2" className="font40" field={fields.SecondHeading} />
                <RichText className="p" field={fields.SecondSubTitle} />
              </Container>
              <ul className="text-xs-center text-sm-left">
                {fields.CardList.map((i, k) => (
                  <li key={k}>
                    <span className="d-xs-block d-sm-none py-25">
                      <Picture field={i.fields.Image} />
                    </span>
                    <Text field={i.fields.Title} />
                  </li>
                ))}
              </ul>
              <div className="text-xs-center text-sm-left pt-30">
                {fields.ButtonText?.value && (
                  <CTAArea
                    pricingData={sitecoreContext.pricing.sitecore.route.fields}
                    fields={fields}
                  ></CTAArea>
                )}
              </div>
            </div>
          </Col>
          <Col sm={7} className="d-xs-none d-sm-block" />
        </Row>
      </Container>
    </Fragment>
  );
};

export default withSitecoreContext()(REFHeroComponent);
