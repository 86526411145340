import React from 'react';
import { Text, RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CTAArea from '../../lib/CTAArea';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Breadcrumbs from '../Breadcrumbs';
import './css/style.css';

const TextHeroComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields || !fields?.data || !fields?.data?.item) {
    return null;
  }
  const breadcrumbSchema = fields.data.breadcrumb;
  const hostname = process.env.JSS_HOST_NAME ?? '';
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: breadcrumbSchema.parent.breadcrumbName.value ?? breadcrumbSchema.parent.name,
        item: hostname + breadcrumbSchema.parent.url,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: breadcrumbSchema.breadcrumbName.value ?? breadcrumbSchema.name,
      },
    ],
  };

  const schemaString = JSON.stringify(schema);
  let spacingClasses = '';

  let showBreadcrumbs = false;
  const reduceWidthClass = rendering?.params?.ReduceWidth ? 'width810 margin-auto' : '';
  if (rendering.params) {
    showBreadcrumbs = rendering.params.ShowBreadcrumb === '1';
    if (rendering.params.Padding) {
      const paddingParam = rendering.params.Padding;

      if (rendering.params) {
        switch (paddingParam) {
          case 'None':
            spacingClasses = 'pt-40';
            break;
          case 'Small':
            spacingClasses = 'pt-50';
            break;
          case 'Medium':
            spacingClasses = 'pt-60';
            break;
          case 'Large':
            spacingClasses = 'py-70';
            break;
          default:
            spacingClasses = 'pt-40';
        }
      }
    }
  }

  // console.log('te');
  // console.log(fields.data.item);

  return (
    <Container fluid>
      <Row
        as="section"
        className={`hero-img center-block text-center relative ${spacingClasses} text-hero-component`}
        data-component={rendering.componentName}
      >
        {showBreadcrumbs && (
          <script
            key="schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: schemaString }}
          />
        )}
        {showBreadcrumbs && <Breadcrumbs breadcrumbs={fields.data.breadcrumb}></Breadcrumbs>}

        <Background backgroundSelector={rendering?.params?.BackgroundSelector} />

        <Col xs={12} className="col-xs-12">
          <div className={reduceWidthClass}>
            <h1>
              <RichText field={fields.data.item.title} />
            </h1>
            <div className="lead">
              <RichText field={fields.data.item.subTitle} />
            </div>
            <div>
              {(fields.data.item.buttonText?.value !== '' || null) && (
                <CTAArea
                  pricingData={sitecoreContext.pricing.sitecore.route.fields}
                  fields={fields.data.item}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const Background = ({ backgroundSelector }) => {
  switch (backgroundSelector) {
    case 'Teal Blob Top Right': {
      return [
        <div className="purple-bg-features-alt" key="blob1"></div>,
        <div className="blue-bg-features-alt" key="blob2"></div>,
      ];
    }
    case 'Teal Blob Top Left': {
      return <div className="blue-bg-industries"></div>;
    }
    default:
      return [
        <div className="purple-bg-features" key="blob1"></div>,
        <div className="blue-bg-features" key="blob2"></div>,
      ];
  }
};

export default withSitecoreContext()(TextHeroComponent);
