import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Col';
/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const HtmlBlockComponent = ({ fields, rendering }) => (
  <>
    {fields?.Content && (
      <Container
        key="container"
        data-component={rendering.componentName}
        data-source-id={rendering.dataSource}
        data-uid={rendering.uid}
      >
        <RichText className="contentDescription" field={fields?.Content} />
      </Container>
    )}
  </>
);

export default HtmlBlockComponent;
