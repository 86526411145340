import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { Col, Container, Row } from 'react-bootstrap';

const ThreeColumnLayout = (props) => (
  <Container className="three__column__layout">
    <Row>
      <Col md={1}>
        &nbsp;
        <Placeholder
          name="blogrollbyadp_jss-threecolumn-left"
          rendering={props.rendering}
        ></Placeholder>
      </Col>
      <Col md={8}>
        &nbsp;
        <Placeholder
          name="blogrollbyadp_jss-threecolumn-center"
          rendering={props.rendering}
        ></Placeholder>
      </Col>
      <Col md={3} style={{ padding: '4.842% 0 0 0' }}>
        &nbsp;
        <Placeholder
          name="blogrollbyadp_jss-threecolumn-right"
          rendering={props.rendering}
        ></Placeholder>
      </Col>
    </Row>
  </Container>
);

export default ThreeColumnLayout;
