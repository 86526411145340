import React from 'react';
import { Text, withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import CTAArea from '../../lib/CTAArea';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const StoryTextComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const useWhiteBg = rendering?.params?.UseAlternateBackground === '1' ? 'bg-white' : '';
  const HeaderTag = fields.HeaderTag?.value?.toLowerCase() ?? h2;
  const paddingParam = rendering.params && rendering.params.Padding;
  // This relates to the subtitle not the whole component.
  const hideOnMobile = rendering.params?.HideOnMobile ? 'hidden-xs' : '';
  let spacingClasses;

  if (rendering.params) {
    switch (paddingParam) {
      case 'None':
        spacingClasses = 'pb-0';
        break;
      case 'Small':
        spacingClasses = 'pb-sm-40 pb-xs-20 pt-md-40 mx-auto ';
        break;
      case 'Medium':
        spacingClasses = 'pb-50 pt-xs-0 mx-auto';
        break;
      case 'Large':
        spacingClasses = 'center-block my-100 mb-xs-10 mx-auto';
        break;
      default:
        spacingClasses = 'container-fluid';
    }
  }

  return (
    <Container
      fluid
      data-component={rendering.componentName}
      data-source-id={rendering.dataSource}
      data-uid={rendering.uid}
      className={`${spacingClasses} ${useWhiteBg}`}
    >
      <Row as="section" className="center-block text-center">
        <Row as="div" className="anchor-wrap center-block">
          {fields.Title?.value && (
            <HeaderTag>
              <RichText field={fields.Title} />
            </HeaderTag>
          )}
          {fields.SubTitle?.value && (
            <RichText
              className={`width730 center-block size20 ${hideOnMobile}`}
              field={fields.SubTitle}
            />
          )}
          {fields.ShowCTAButton?.value && (
            <CTAArea pricingData={sitecoreContext.pricing.sitecore.route.fields} fields={fields} />
          )}
        </Row>
      </Row>
    </Container>
  );
};

export default withSitecoreContext()(StoryTextComponent);
