import React, { Fragment } from 'react';
import { Text, RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CTAArea from '../../lib/CTAArea';
import './css/style.css';
import Picture from '../../lib/Picture';

const SideBySideComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const HeaderTag = fields.HeaderTag?.value?.toLowerCase() ?? h2;

  const sideBySideBg = fields.ShowWhiteBox.value ? 'sidebysideimgrightwhite' : 'sidebysideimgright';

  const sideBySideAlignment = fields.AlignImageLeft?.value ? 'align-right' : 'align-left';

  const sideBySideVertAlignment = fields.CenterVertically?.value
    ? 'center-vertically'
    : 'top-align';

  const centerVerticallyClasses = fields.CenterVertically?.value ? 'justify-content-center' : '';
  const hideOnMobile = rendering.params?.HideOnMobile ? 'hidden-xs' : '';
  const showWhiteBoxClasses = fields.ShowWhiteBox?.value === true ? 'pb-50 pt-xs-0' : '';

  const paddingParam = rendering?.params && rendering?.params?.Padding;
  // This relates to the subtitle not the whole component.
  let spacingClasses;

  if (rendering.params) {
    switch (paddingParam) {
      case 'None':
        spacingClasses = 'py-0';
        break;
      case 'Small':
        spacingClasses = 'py-25';
        break;
      case 'Medium':
        spacingClasses = 'padding-override';
        break;
      case 'Large':
        spacingClasses = 'py-60';
        break;
      default:
        spacingClasses = 'container-fluid';
    }
  }

  return (
    <Fragment>
      <style>
        {`
      #${rendering.componentName}-${rendering.uid} ul{
        margin-left: 30px;
      }
      `}
      </style>
      <Container
        id={`${rendering.componentName}-${rendering.uid}`}
        className={`${showWhiteBoxClasses} ${hideOnMobile} side-by-side`}
        data-component={rendering.componentName}
        data-uid={rendering.uid}
        data-source-id={rendering.dataSource}
        fluid
      >
        <section>
          <Row
            as="div"
            className={`center-block d-flex flex-wrap  ${sideBySideAlignment} ${spacingClasses} ${sideBySideBg}`}
          >
            <Col
              xs={12}
              sm={6}
              className={`col-xs-12 d-flex ${sideBySideVertAlignment}  flex-column`}
            >
              {fields.Image?.value?.src && (
                <Picture className="mx-auto img-responsive" field={fields.Image} loading="lazy" />
              )}
            </Col>

            <Col className="col-xs-1 hidden-xs"></Col>
            <Col
              xs={12}
              sm={5}
              className={`col-xs-12 d-flex flex-column ${centerVerticallyClasses} `}
            >
              {fields.Title?.value && (
                <HeaderTag>
                  <RichText field={fields.Title} />
                </HeaderTag>
              )}
              {fields.SubTitle?.value && (
                <div>
                  <RichText field={fields.SubTitle} />
                </div>
              )}
              {fields.ButtonText?.value && (
                <div className="text-left pt-24">
                  <CTAArea
                    pricingData={sitecoreContext.pricing.sitecore.route.fields}
                    fields={fields}
                    style={{ alignItems: 'center' }}
                  ></CTAArea>
                </div>
              )}
            </Col>
          </Row>
        </section>
      </Container>
    </Fragment>
  );
};

export default withSitecoreContext()(SideBySideComponent);
