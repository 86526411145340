import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';
import Picture from '../../lib/Picture';
const InitializePressQuotes = loadable(() => import('./client'), { ssr: false });

const PressLogosComponent = ({ fields }) => {
  if (!fields) {
    return null;
  }
  return [
    <InitializePressQuotes key="init-press-logos-client" />,
    <Container fluid className="pt-0 px-xs-0 mb-50 mx-xs-20" key="press-logos">
      <Row as="section" className="center-block text-center card-box logo-v2">
        <Col
          xs={12}
          className="col-xs-12 center-block align-items-center justify-content-center trustMarkerV2 js-carousel"
        >
          <PressLogos items={fields.PressLogos} />
        </Col>

        <Col
          xs={12}
          id="press-logos-only-quotes"
          className="col-xs-12 align-content-center hidden-xs"
          style={{ display: 'block' }}
        >
          <div className="px-xs-20">
            <QuotesOnly items={fields.PressLogos} />
          </div>
        </Col>
      </Row>
      <div className="trustMarkerV2-nav"></div>
    </Container>,
  ];
};
const PressLogos = ({ items }) => {
  return items?.map((t, idx) => (
    <Col
      xs={6}
      sm={2}
      className="col-xs-6 px-xs-0 px-sm-0 align-content-center trustMarkerV2Item quote-control"
      style={{ borderTop: 'solid 5px ' + (idx === 0 ? '#4e00dc' : 'transparent') }}
      key={t.name}
      id={`quote_${idx}`}
    >
      <div>
        <Picture
          className="cursor-pointer 2img-responsive mx-auto controls"
          field={t.fields.Image}
          loading="lazy"
        />
      </div>
      <div className="d-none d-xs-block dont-break-out">
        <RichText className="lead pt-20" tag="div" field={t.fields.Quote} />
        <p className="h4 pt-30">
          <strong>
            <Text field={t.fields.SiteURL} />
          </strong>
        </p>
      </div>
    </Col>
  ));
};

const QuotesOnly = ({ items }) => {
  return items?.map((t, idx) => (
    <div
      className="trustMarkerV2Quote dont-break-out quote-body"
      key={idx}
      id={`quote_${idx}_body`}
      style={{ display: idx === 0 ? 'block' : 'none' }}
    >
      <RichText tag="div" className="lead pt-20" field={t.fields.Quote} />
      <p className="h4 pt-30">
        <Text field={t.fields.SiteURL} />
      </p>

      {/*
      <div id="bizinsider-quote" class="trustMarkerV2Quote dont-break-out" style="display: block">
        <p class="lead pt-20">
          “Backed by the long-standing payroll expertise and data security of ADP, Roll offers small
          business owners the ability to run payroll anywhere, anytime with no experience needed.”
        </p>
        <p class="h4 pt-30">
          <strong>BusinessInsider.com</strong>
        </p>
      </div>
      */}
    </div>
  ));
};

export default PressLogosComponent;
