import React from 'react';
import { withSitecoreContext, Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import appStoreLogo from '../../assets/images/ui/logo_app_store_mini.svg';
import playStoreLogo from '../../assets/images/ui/logo_google_play_mini.svg';
import './css/style.css';
import CTAArea from '../../lib/CTAArea';

const PricingPromoComponent = ({ sitecoreContext, fields, rendering }) => {
  if (!fields) {
    return null;
  }
  const pricing = sitecoreContext.pricing.sitecore.route;
  let basePrice = pricing.fields.BasePrice;
  let perEmployeePrice = pricing.fields.PerEmployeePrice;
  let pricePer = pricing.fields.PricePer;
  let termsLink = pricing.fields.StandardTermsLink;
  if (fields.CustomPricingItem) {
    basePrice = fields.CustomPricingItem.fields.BasePrice;
    perEmployeePrice = fields.CustomPricingItem.fields.PerEmployeePrice;
    pricePer = fields.CustomPricingItem.fields.PricePer;
    termsLink = fields.CustomPricingItem.fields.CustomTermsLink;
  }

  const currencyValue = sitecoreContext.pricing.sitecore.route.fields.Currency?.value ?? '$';

  return [
    <Container
      fluid
      key="pricing-promo-content"
      data-component-name={rendering.componentName}
      data-source-id={rendering.dataSource}
    >
      <Row as="section" className="center-block relative">
        <div className="pricing-bg-hero-blue">&nbsp;</div>
        <div className="pricing-bg-hero-purple">&nbsp;</div>
        <Col xs={12} className="col-xs-12 text-center mb-40">
          <h1>
            <RichText field={fields.Title} />
          </h1>
        </Col>
        <Col xs={12} className="col-xs-12">
          <div className="card-box text-center pt-60 pt-xs-90">
            <p className="pricing-splash pt-10">
              <strong className="h1 size80 pb-5">
                {currencyValue}
                <Text field={basePrice} />
              </strong>
              {'/'}
              <Text field={pricePer} />
              <br />+ {currencyValue}
              <Text field={perEmployeePrice} /> per employee
            </p>
            <ul className="list-inline list-check-inline lead text-center text-xs-left specter-bold ml-md-70">
              <li className="pr-sm-10 pr-md-40">
                <span>
                  <Text field={pricing.fields.ModalHeaderBullet1} />
                </span>
              </li>
              <li className="pr-sm-10 pr-md-40">
                <span>
                  <Text field={pricing.fields.ModalHeaderBullet2} />
                </span>
              </li>
              <li className="pr-sm-10 pr-md-40">
                <span>
                  <Text field={pricing.fields.ModalHeaderBullet3} />
                </span>
              </li>
            </ul>
            <p className="mb-xs-10">
              <CTAArea
                pricingData={sitecoreContext.pricing.sitecore.route.fields}
                fields={fields}
              ></CTAArea>
            </p>
            {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
              <div>
                <ul className="app-links hidden-xs">
                  <li>
                    <img alt="" src={appStoreLogo} />
                    <Link
                      className={'apple-app-store-link'}
                      field={pricing.fields.AppleAppStoreLink}
                    />
                  </li>
                  <li>
                    <img alt="" src={playStoreLogo} />
                    <Link
                      className={'android-app-store-link'}
                      field={pricing.fields.GoogleAppStoreLink}
                    />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Col>

        {termsLink?.value && (
          <Col xs={12} className="col-xs-12 pt-15 text-right text-xs-center">
            <small>
              <Link className="popup-offer terms" field={termsLink} />
            </small>
          </Col>
        )}
      </Row>
    </Container>,
  ];
};

export default withSitecoreContext()(PricingPromoComponent);
