import React, { Fragment } from 'react';
import { Text, Image, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CTAArea from '../../lib/CTAArea';
import './css/style.css';
import Picture from '../../lib/Picture';

const PartnerHeroWhiteBarComponent = ({ sitecoreContext, fields, rendering }) => {
  const pricing = sitecoreContext.pricing.sitecore.route;
  if (!fields) {
    return null;
  }

  const mobileButtonLink = {
    value: {
      href: null,
    },
  };
  mobileButtonLink.value.href = sitecoreContext.route.fields?.FirebaseLink?.value
    ? sitecoreContext.route.fields.FirebaseLink.value
    : fields.MobileButtonLink.value.href;

  return [
    <div
      className="container-fluid bg-white pt-25 pb-15"
      key="header"
      id={`${rendering.componentName}-header-${rendering.uid}`}
    >
      <section className="center-block row">
        <div className="px-5">
          <ul className="app-links app-links-20 d-flex">
            {fields.LogoImage?.value?.src ? (
              <li>
                <Picture field={fields.LogoImage} loading="lazy" />
              </li>
            ) : (
              <li>
                <Picture field={pricing.fields.RollLogo} loading="lazy" />
              </li>
            )}

            {fields.PartnerLogoLockupImage?.value?.src && (
              <li>
                <Picture field={fields.PartnerLogoLockupImage} loading="lazy" />
              </li>
            )}
          </ul>
        </div>
      </section>
    </div>,
    <Fragment key="PartnerHeroWhiteBarComponent">
      <style>
        {` #${rendering.componentName}-${rendering.uid} { background: url(${fields.Image.value.src}) top right 20% no-repeat;} 
           @media (min-width: 1620px) {#${rendering.componentName}-${rendering.uid} { background-size: auto 100%;}}
           @media (min-width: 1520px) and (max-width: 1619px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top right 15% no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 1420px) and (max-width: 1519px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top right 10% no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 1320px) and (max-width: 1419px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top right 5% no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 1220px) and (max-width: 1319px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top right -5% no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 1120px) and (max-width: 1219px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top right -23%
                no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 1110px) and (max-width: 1119px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top left
                130% no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 1100px) and (max-width: 1109px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top left
                135% no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 990px) and (max-width: 1099px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) top left
                140% no-repeat;
              background-size: auto 100%;
            }
          }
          @media (min-width: 768px) and (max-width: 989px) {
            #${rendering.componentName}-${rendering.uid} {
              background: url( background: url(${fields.Image.value.src}) no-repeat;
              background-size: auto 100%;
              background-position: calc(100px + 100%);
            }
          }
           @media (max-width: 767px) {#${rendering.componentName}-${rendering.uid} { background:none;}}
           #${rendering.componentName}-header-${rendering.uid} .app-links li{
              border-right: 1px solid #121c4e;
           }
           #${rendering.componentName}-header-${rendering.uid} .app-links li:last-of-type{
            border-right: none
          }
          #${rendering.componentName}-header-${rendering.uid} .app-links li img{
            margin-top:0;
            max-height:50px;
            width:auto;
          }          
        `}
      </style>

      <Container
        fluid
        className="container-fluid pt-0 pb-80 pb-xs-0 bg-blue-121C4E amz-hero sem-hero"
        data-uid={rendering.uid}
        data-component-name={rendering.componentName}
        id={`${rendering.componentName}-${rendering.uid}`}
      >
        <Row as="section" className="hero-wrap row center-block d-flex flex-wrap pt-20 pt-xs-0">
          <Col
            sm={6}
            className="col-sm-6 col-xs-12 pl-xs-0 pr-xs-0 d-flex justify-content-center flex-column text-xs-center"
          >
            <h1 className="text-xs-center white">
              <RichText field={fields.Title} />
            </h1>
            <div
              id="partnerHeroSubtitle"
              className="lead mb-36 text-xs-center white2 mt-0 mt-xs-15 white"
            >
              <RichText tag="strong" field={fields.SubTitle} />
            </div>
            <div className="card-box partner-hero-card-box p-20 text-center">
              <div>
                <h4 className="mb-10 pink-DE0A65 hidden-xs px-15">
                  <RichText tag="strong" field={fields.PromoText} />
                </h4>
                <h4 className="mb-10 visible-xs px-15">
                  <RichText tag="strong" field={fields.PromoText} />
                </h4>
                <RichText field={fields.PromoSubText} />
                <div className="hero-qr-redone">
                  <Picture
                    className="img-responsive mt-20 hidden-xs qr-code"
                    field={fields.QRCodeImage}
                    loading="lazy"
                  />
                </div>
                <Link
                  field={mobileButtonLink}
                  data-label="start_free_trial"
                  className="btn btn-primary visible-xs"
                >
                  <Text field={fields.MobileButtonText} />
                </Link>
              </div>

              {fields?.ButtonText?.value && (
                <div>
                  <CTAArea
                    pricingData={sitecoreContext.pricing.sitecore.route.fields}
                    fields={fields}
                    routeFields={sitecoreContext.route.fields}
                  />
                </div>
              )}
              <p className="mb-0 size12 mt-10">
                {fields.TermsLink?.value?.href ? (
                  <Link
                    id="partnerHeroPopupOffer"
                    className="popup-offer terms hidden-xs"
                    field={fields.TermsLink}
                  />
                ) : (
                  <Link
                    id="partnerHeroPopupOffer"
                    className="popup-offer terms hidden-xs"
                    field={pricing.fields.StandardTermsLink}
                  />
                )}
              </p>
              <div className="mb-0 mt-20 size12">
                {fields.TermsLink?.value?.href ? (
                  <Link className="popup-offer terms visible-xs" field={fields.TermsLink} />
                ) : (
                  <Link
                    className="popup-offer terms visible-xs"
                    field={pricing.fields.StandardTermsLink}
                  />
                )}
              </div>
            </div>
          </Col>

          <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
            <Picture
              className="img-responsive width510 pt-xs-30 visible-xs no-lazy"
              field={fields.MobileImage}
              loading="lazy"
            />
          </div>
        </Row>
      </Container>
    </Fragment>,
  ];
};

export default withSitecoreContext()(PartnerHeroWhiteBarComponent);
