import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import SmallCard from '../../Cards/SmallCard';

const RowContainer = (props) => {
  return (
    <Row className="might__like__row" aria-label="You might also like the following">
      <Columns posts={props.posts} />
    </Row>
  );
};

const Columns = (props) => {
  return props.posts.map((post, idx) => {
    return (
      <SmallCard post={post} key={idx} />
      // <Col
      //   className="might__like__row__col"
      //   aria-label={post.item.postImage.jss.value.alt}
      //   key={idx}
      // >
      //   <a href={post.item.url} aria-label={post.item.headline.jss.value}>
      //     <img src={post.item.postImage.jss.value.src} alt={post.item.postImage.jss.value.alt} />
      //   </a>
      //   <h5 aria-label={post.item.headline.jss.value}>
      //     <a href={post.item.url} aria-label={post.item.headline.jss.value}>
      //       {' '}
      //       {post.item.headline.jss.value}
      //     </a>
      //   </h5>
      //   <p>
      //     <span aria-label="Post category">
      //       <a href={post.item.category.url} aria-label={post.item.category.categoryName.jss.value}>
      //         {post.item.category.categoryName.jss.value}
      //       </a>
      //     </span>
      //     <span className="px-1 dot-middle"> • </span>
      //     <span aria-label="Date posted">
      //       {moment.utc(post.item.postDate.jss.value).format('MMM DD, YYYY')}
      //     </span>
      //   </p>
      // </Col>
    );
  });
};

export default RowContainer;
