import React from 'react';
import { Text, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CTAArea from '../../lib/CTAArea';

const CampaignPromoTextOnlyComponent = ({ sitecoreContext, fields, rendering }) => {
  const pricing = sitecoreContext.pricing.sitecore.route;
  if (!fields) {
    return null;
  }

  let TermsLink = fields.TermsLink ? fields.TermsLink : pricing.fields.StandardTermsLink;

  if (fields.CustomPricingItem?.fields) {
    pricing.fields.BasePrice.value = fields.CustomPricingItem?.fields?.BasePrice?.value;

    pricing.fields.PerEmployeePrice.value =
      fields.CustomPricingItem?.fields?.PerEmployeePrice?.value;

    pricing.fields.PricePer.value = fields.CustomPricingItem?.fields?.PricePer?.value;

    TermsLink = fields.CustomPricingItem?.fields?.CustomTermsLink;
  }

  const currencyValue = sitecoreContext.pricing.sitecore.route.fields.Currency?.value ?? '$';

  const pricingPromoBoxLayout =
    sitecoreContext.pricing.sitecore.route.fields.PricingPromoBoxLayout.value;
  let pricingPromoBoxLayoutContent;
  switch (pricingPromoBoxLayout) {
    case 'SingleCurrencyLayout':
      pricingPromoBoxLayoutContent = (
        <p className="pricing-splash pt-10">
          <strong className="h1 size48 pb-5">
            {currencyValue}
            <Text field={pricing.fields.BasePrice} />
          </strong>
          <br />
          <Text field={pricing.fields.PerEmployeePrice} />
          &nbsp;
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PerEmployeeText} />
          &nbsp;{'/'}&nbsp;
          <Text field={pricing.fields.PricePer} />
        </p>
      );
      break;
    default:
      pricingPromoBoxLayoutContent = (
        <p className="pricing-splash pt-10">
          <strong className="h1 size80 pb-5">
            {currencyValue}
            <Text field={pricing.fields.BasePrice} />
          </strong>
          {'/'}
          <Text field={pricing.fields.PricePer} />
          <br />+ {currencyValue}
          <Text field={pricing.fields.PerEmployeePrice} />
          &nbsp;
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PerEmployeeText} />
        </p>
      );
  }

  return (
    <Container fluid className="sem-bg-footer pt-xs-0" data-component={rendering.componentName}>
      <Row as="section" className="center-block relative">
        <Col xs={12} className="col-xs-12 ">
          <div className="card-box text-center pt-60 pt-xs-40">
            <h2>
              <RichText field={fields.Title} />
            </h2>
            <div className="lead mb-5 size2">
              <RichText field={fields.SubTitle} />
            </div>
            {pricingPromoBoxLayoutContent}
            <CTAArea fields={fields} pricingData={pricing} />
            <small>
              <Link field={TermsLink} className="popup-offer terms" />
            </small>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withSitecoreContext()(CampaignPromoTextOnlyComponent);
