import React from 'react';
import {
  Placeholder,
  VisitorIdentification,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import StaticNavigationPlaceholder from './StaticNavigationPlaceholder';
import SchemaOrgFaqs from './components/FAQsComponent/SchemaOrg';
import UTMOverride from './components/LeadGenForm/client/UTMOverride';
import AppsFlyer from './components/LeadGenForm/client/AppsFlyer';
import loadable from '@loadable/component';
import font1 from '../src/assets/fonts/SpecterApp-Bold.ttf';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
// import 'bootstrap/dist/css/bootstrap.css';
// import './assets/app.css';
import './assets/critical.css';
import './assets/from-rollbyadp.css';
import './assets/blog-components.css';

// import TalkToSalesForm from './components/TalkToSalesForm';
// import './assets/js/main-roll.js';
// import './assets/js/vendor-roll';
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// window.dataLayer = [{ continent: 'NA', country: 'US', state: 'NY' }];
const OpenDeviceAppStore = loadable(
  () => import(/* webpackPrefetch: true */ './lib/openDeviceAppStore'),
  { ssr: false }
);
const InitLayout = loadable(() => import('./lib/InitLayout'), { ssr: false });
const InitializeFAQs = loadable(
  () => import(/* webpackPrefetch: true */ './components/FAQsComponent/client'),
  { ssr: false }
);
const TalkToSalesForm = loadable(
  () => import(/* webpackPrefetch: true */ './components/TalkToSalesForm'),
  { ssr: false }
);
const SetHeadlineHeight = loadable(() => import('./lib/SetHeadlineHeight'), { ssr: true });

const AddCaption = loadable(() => import('./lib/AddCaption'), { ssr: true });

// this is suboptimal. clean up the script tags in server.js
const ScriptFragment = ({ content }) => <div dangerouslySetInnerHTML={{ __html: content }} />;

// ***** Start For Free Form *****
// import LeadGenForm from './components/LeadGenForm/index';
const LeadGenForm = loadable(
  () => import(/* webpackPrefetch: true */ './components/LeadGenForm/index'),
  { ssr: false }
);

const MetaItem = (props) => {
  let content = props.value && props.value.value;
  if (!content && props.fallbackValue && props.fallbackValue.value) {
    content = props.fallbackValue.value;
  }
  if (!content && props.secondfallbackValue && props.secondfallbackValue.value) {
    content = props.secondFallbackValue.value;
  }

  if (typeof content === 'object') {
    // must be an image. look for the src
    if (content.src) {
      content = content.src;
    } else {
      content = null;
    }
  }

  if (content) {
    return (
      <Helmet>
        <meta name={props.name} content={content} />
      </Helmet>
    );
  } else {
    return null;
  }
};

const convertDangerousHtmlToChildren = (node) => {
  if (node.props && node.props.dangerouslySetInnerHTML) {
    return {
      ...node,
      props: {
        ...node.props,
        dangerouslySetInnerHTML: undefined,
        children: node.props.dangerouslySetInnerHTML.__html,
      },
    };
  }
  return node;
};

const HeaderScripts = ({ scripts }) => {
  let scriptTags = [];
  if (scripts) {
    return scripts.map((s) => {
      const opts = {};
      if (s.fields.Src?.value) {
        opts.src = s.fields.Src.value;
      }
      if (s.fields.IsAsync?.value) {
        opts.async = 'async';
      }
      if (s.fields.Defer?.value) {
        opts.async = 'defer';
      }
      return (
        <Helmet key={s.id}>
          <script
            {...opts}
            data-header="header"
            type="text/javascript"
          >{`${s.fields.ScriptBody?.value}`}</script>
        </Helmet>
      );
    });
  }
  return scriptTags;
};
const BodyScripts = ({ scripts }) => {
  let scriptTags = [];
  if (scripts) {
    return scripts.map((s) => {
      const opts = {};
      if (s.fields.Src?.value) {
        opts.src = s.fields.Src.value;
      }
      if (s.fields.IsAsync?.value) {
        opts.async = 'async';
      }
      if (s.fields.Defer?.value) {
        opts.async = 'defer';
      }
      if (s.fields.HtmlId?.value) {
        opts.id = s.fields.HtmlId?.value;
      }
      return (
        <script
          key={s.id}
          {...opts}
          data-scriptid={s.id}
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: s.fields.ScriptBody?.value }}
        ></script>
      );
    });
  }
  return scriptTags;
};

const checkAppsFlyerVars = (sitecoreContext, route) => {
  // Check page level.
  if (sitecoreContext.pricing.sitecore.route.fields.enable_appsflyer?.value) {
    return true;
  }

  // Check global level.
  if (route.fields?.enable_appsflyer?.value) {
    return true;
  }

  return false;
};

const Layout = ({ route, sitecoreContext }) => {
  const bodyClass = route.fields.BodyClass?.value ?? '';
  const showAppsFlyer = checkAppsFlyerVars(sitecoreContext, route);

  let metaLang;
  if (sitecoreContext.language !== 'en') {
    metaLang = (
      <meta name="google-site-verification" content="pUzxGC4YIPZIoZwp5_Dd_9lcSeDhdBQk10-P3qJfXQI" />
    );
  } else {
    metaLang = (
      <meta name="google-site-verification" content="MwCZevbaMsh-BUg799Xl0hPXBfYQhKvgHpau4hf9d4c" />
    );
  }

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Page'}
        </title>
        <script src="https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js"></script>
      </Helmet>
      <Helmet>
        <style>{font1}</style>
        {metaLang}
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn-prod.securiti.ai/consent/cookie-consent.css"
        />
      </Helmet>
      <MetaItem name="description" value={route.fields.metaDescription} />
      <MetaItem name="og:type" value={route.fields.ogType} fallbackValue="article" />
      <MetaItem
        name="og:title"
        value={route.fields.ogTitle}
        fallbackValue={route.fields.pageTitle}
      />
      <MetaItem
        name="og:description"
        value={route.fields.ogDescription}
        fallbackValue={route.fields.metaDescription}
      />
      <MetaItem name="keywords" value={route.fields.metaKeywords} />
      <MetaItem name="og:image" value={route.fields.ogImage} />
      <MetaItem name="twitter:card" value={route.fields.twitterCard} />
      <MetaItem name="twitter:site" value={route.fields.twitterSite} />
      <MetaItem
        name="twitter:title"
        value={route.fields.twitterTitle}
        fallbackValue={route.fields.ogTitle}
        secondFallbackValue={route.fields.pageTitle}
      />
      <MetaItem
        name="twitterImage:title"
        value={route.fields.twitterImage}
        fallbackValue={route.fields.ogImage}
        secondFallbackValue={route.fields.PostImage}
      />
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <HeaderScripts scripts={sitecoreContext.pricing.sitecore.route?.fields?.HeadScripts} />
      <HeaderScripts scripts={route?.fields?.HeadScripts} />

      <BodyScripts scripts={sitecoreContext.pricing.sitecore.route?.fields?.TopBodyScripts} />
      <BodyScripts scripts={route.fields?.TopBodyScripts} />
      <ScriptFragment
        content={sitecoreContext.pricing.sitecore.route?.fields?.BodyScripts?.value}
      />
      <ScriptFragment content={route.fields?.BodyScripts?.value} />
      <VisitorIdentification />
      {/* <Navigation /> */}
      {/* root placeholder for the app, which we add components to using route data */}
      {route.layoutId === '1f077661-4bf1-449a-844d-d9594cb38ea3' && ( // regular layout
        <div id="navigation-container">
          <StaticNavigationPlaceholder name="rollbyadp_jss-nav" />
        </div>
      )}
      {route.fields.BodyClass?.value && (
        <Helmet>
          <body className={route.fields.BodyClass?.value} />
        </Helmet>
      )}
      <main className={`${bodyClass} ${sitecoreContext.language !== 'en' ? 'mt-0' : ''}`}>
        {route.name === 'faqs' && <SchemaOrgFaqs rendering={route} />}
        <Placeholder name="rollbyadp_jss-main" rendering={route} />
        <Placeholder name="blogrollbyadp_jss-main" rendering={route} />
      </main>
      {route.layoutId === '1f077661-4bf1-449a-844d-d9594cb38ea3' && (
        <div id="footer-container">
          <StaticNavigationPlaceholder name="rollbyadp_jss-footer" />
        </div>
      )}
      {/* {route.layoutId === 'd9a4a5d3-d75f-47c1-a70f-ade84b96671e' && (
      // <script
      //   async="async"
      //   crossOrigin=""
      //   src="//consent.truste.com/notice?domain=adp.com&amp;c=teconsent&amp;js=bb&amp;noticeType=bb&amp;oc=1&amp;px=50&amp;text=true&amp;gtm=1"
      //   id="truste_0.4133723106439411"
      // ></script>
    )} */}
      <TalkToSalesForm />
      <LeadGenForm />
      <InitializeFAQs />
      <OpenDeviceAppStore></OpenDeviceAppStore>
      <InitLayout></InitLayout>
      <AddCaption />
      <SetHeadlineHeight />
      <BodyScripts scripts={sitecoreContext.pricing.sitecore.route?.fields?.BottomOfPageScripts} />
      <BodyScripts scripts={route.fields?.BottomOfPageScripts} />
      <ScriptFragment
        content={sitecoreContext.pricing.sitecore.route?.fields?.FooterScripts?.value}
      />
      <ScriptFragment content={route.fields?.FooterScripts?.value} />
      {showAppsFlyer && <AppsFlyer />}
      <UTMOverride />
    </React.Fragment>
  );
};

export default withSitecoreContext()(Layout);
