import React from 'react';
import { Text, Image, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import CTAArea from '../../lib/CTAArea';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import appStoreLogo from '../../assets/images/ui/logo_app_store_mini.svg';
import playStoreLogo from '../../assets/images/ui/logo_google_play_mini.svg';
import Picture from '../../lib/Picture';

const HomepageHeroComponent = ({ sitecoreContext, fields }) => {
  if (!fields) {
    return null;
  }

  const {
    SchemaOrgName,
    SchemaOrgUrl,
    SchemaOrgLogo,
    SchemaOrgDescription,
    SchemaOrgSameAsFacebook,
    SchemaOrgSameAsTwitter,
    SchemaOrgSameAsInstagram,
    SchemaOrgSameAsYoutube,
    SchemaOrgAddressLocality,
    SchemaOrgAddressCountry,
    SchemaOrgPostalCode,
    SchemaOrgStreetAddress,
  } = sitecoreContext.pricing.sitecore.route.fields;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    '@id': SchemaOrgUrl?.value + '#corporation' || '',
    name: SchemaOrgName?.value || '',
    url: SchemaOrgUrl?.value || '',
    logo: SchemaOrgLogo?.value || '',
    description: SchemaOrgDescription?.value || '',
    address: {
      '@type': 'PostalAddress',
      addressLocality: SchemaOrgAddressLocality?.value || '',
      addressCountry: SchemaOrgAddressCountry?.value || '',
      postalCode: SchemaOrgPostalCode?.value || '',
      streetAddress: SchemaOrgStreetAddress?.value || '',
    },
    sameAs: [
      SchemaOrgSameAsFacebook?.value || '',
      SchemaOrgSameAsTwitter?.value || '',
      SchemaOrgSameAsInstagram?.value || '',
      SchemaOrgSameAsYoutube?.value || '',
    ],
  };

  const schemaString = JSON.stringify(schema);

  const currencyValue = sitecoreContext.pricing.sitecore.route.fields.Currency?.value ?? '$';

  const pricingPromoBoxLayout =
    sitecoreContext.pricing.sitecore.route.fields.PricingPromoBoxLayout.value;
  let pricingPromoBoxLayoutContent;
  switch (pricingPromoBoxLayout) {
    case 'SingleCurrencyLayout':
      pricingPromoBoxLayoutContent = (
        <p className="pricing-splash pt-10">
          <strong className="h1">
            {currencyValue}
            <Text field={sitecoreContext.pricing.sitecore.route.fields.BasePrice} />
          </strong>
          <br />
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PerEmployeeText} />
          {' / '}
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PricePer} />
        </p>
      );
      break;
    default:
      pricingPromoBoxLayoutContent = (
        <p className="pricing-splash pt-10">
          <strong className="h1">
            {currencyValue}
            <Text field={sitecoreContext.pricing.sitecore.route.fields.BasePrice} />
          </strong>
          &nbsp;{' / '}
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PricePer} />
          <br />+ {currencyValue}
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PerEmployeePrice} />
          &nbsp;
          <Text field={sitecoreContext.pricing.sitecore.route.fields.PerEmployeeText} />
        </p>
      );
  }

  return (
    <>
      <script
        key="schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: schemaString }}
      />
      <Container fluid className="pt-0 pb-xs-0">
        <Row as="section" className="hero-wrap center-block d-flex flex-wrap">
          <div className="hero-bg">&nbsp;</div>
          <Col
            xs={12}
            sm={5}
            className="col-xs-12 d-flex justify-content-center flex-column px-xs-0"
          >
            <h1 className="text-xs-center">
              <RichText field={fields.Title} />
            </h1>
            <div className="lead mb-36 text-xs-center">
              <RichText field={fields.SubTitle} />
            </div>
            <div className="light-blue-bg hidden-xs">&nbsp;</div>
            <div className="card-box p-20 col-xs-12 text-center">
              {pricingPromoBoxLayoutContent}
              <CTAArea
                suppressMargin={true}
                pricingData={sitecoreContext.pricing.sitecore.route.fields}
                fields={fields}
              />
              {!sitecoreContext.pricing.sitecore.route.fields?.disable_app_icons?.value && (
                <ul className="app-links ">
                  <li>
                    <img alt="Apple App Store Logo" src={appStoreLogo} />
                    <Link
                      className="size12"
                      target="_blank"
                      field={sitecoreContext.pricing.sitecore.route.fields.AppleAppStoreLink}
                    ></Link>
                  </li>
                  <li>
                    <img alt="Google Play Store Logo" src={playStoreLogo} />
                    <Link
                      className="size12"
                      target="_blank"
                      field={sitecoreContext.pricing.sitecore.route.fields.GoogleAppStoreLink}
                    ></Link>
                  </li>
                </ul>
              )}
            </div>
          </Col>
          <Col sm={7} xs={12} className="col-xs-12 text-right mb-xs-0 px-xs-0">
            <Picture
              className="img-responsive width510 no-lazy"
              field={fields.Image}
              loading="lazy"
            ></Picture>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withSitecoreContext()(HomepageHeroComponent);
