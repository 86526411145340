import React, { useEffect } from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import loadable from '@loadable/component';
const InitializeSlick = loadable(() => import(/* webpackPrefetch: true */ './client'), {
  ssr: false,
});
// CSS
import './css/style.css';
import Picture from '../../lib/Picture';

const TestimonialsComponent = ({ fields, rendering }) => {
  if (!fields) {
    return null;
  }

  return [
    <InitializeSlick key="init-testimonials-client" />,
    <Container
      fluid
      className="pb-sm-100 pt-60 px-xs-0"
      key="testimonials-component"
      data-component={rendering.componentName}
    >
      <Row as="section" className="testimonials center-block pt-xs-60 pb-xs-10">
        <div className="quote-bubble hidden-xs">
          <span className="h3 white text-center">
            <RichText field={fields.BubbleText} />
          </span>
        </div>
        <Row className="center-block">
          <Col xs={12} className="col-xs-12 text-center center-block width650">
            <div id="testimonial-slider" className="js-carousel-testimonials js-carousel">
              <Testimonials testimonials={fields.Testimonials} />
            </div>
          </Col>
        </Row>
      </Row>
    </Container>,
  ];
};

// if there is more than 1 testimonial, show a carousel. if there is no image, use blue quote image as default (see components sheet)
const Testimonials = ({ testimonials }) => {
  const defaultImage = {
    src:
      '/-/media/Microsites/rollbyadp/images/features/1099/2022/roll_testimonial_image_generic.ashx?h=242&w=242&hash=542EAA5C65F71281C02CE2011EF03B2D',
    alt: '',
    width: '242',
    height: '242',
  };

  return testimonials?.map((t) => {
    const testimonialImg = t.fields.Image?.value?.src ? t.fields.Image : defaultImage;

    return (
      <div key={t.id}>
        <Picture className="mx-auto width120" field={testimonialImg} loading="lazy" />
        <blockquote>
          <RichText field={t.fields.TestimonialLongText} />
          <footer>
            <p>
              <Text field={t.fields.Name} />
              {t.fields.Role && ', '} {t.fields.Role && <Text field={t.fields.Role} />}
            </p>
            <cite>
              <Text field={t.fields.CompanyName} />
            </cite>
          </footer>
        </blockquote>
      </div>
    );
  });

  return cards || null;
};

export default TestimonialsComponent;
