import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';

const NavLinks = (props) => {
  let htmlRegex = new RegExp('<[a-zA-Z]');

  let border = 'dot-border';
  if (props.noBorder) {
    border = '';
  }
  const params = [];
  if (props.linkAttributes) {
    params = getQueryStringParams(link.linkAttributes.jss.value);
  }
  return (
    <ul className={`list-unstyled ${border}`}>
      {props.navLinks.map((item, i) => {
        if (item.contentBlock !== null) {
          if (htmlRegex.test(item.contentBlock.jss.value) === true) {
            return (
              <li dangerouslySetInnerHTML={{ __html: item.contentBlock.jss.value }} key={i}></li>
            );
          }
        } else if (item.contentBlock === null) {
          let href = item.link.jss.value.href || item.link.jss.value.url;
          if (item.link.jss.value.url && item.link.jss.value.url.startsWith('tel:')) {
            href = item.link.jss.value.url;
          }
          return (
            <li key={i}>
              <a href={href} {...params}>
                {item.link.jss.value.text}
              </a>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default NavLinks;
