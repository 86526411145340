import React, { Fragment, useEffect } from 'react';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import loadable from '@loadable/component';

// Client code
const CompetitionComparisonListComponentClient = loadable(() => import('./client'), { ssr: false });

// Assets
import blueCheckMark from '../../assets/images/home-global/blue-checkmark.png';
import pinkX from '../../assets/images/home-global/pinkX.png';

const CompetitionComparisonListComponent = ({ sitecoreContext, fields, rendering }) => {
  // Rendering Info
  if (!fields) {
    return null;
  }
  const componentName = rendering.componentName;
  const uid = rendering.uid;
  const dataSource = rendering.dataSource;

  return [
    <CompetitionComparisonListComponentClient key="client-side" />,
    <Fragment key="comp-list">
      <style>
        {`
      .${componentName}-${uid} .rich-text{
          padding:1rem 2rem;
        }

      .${componentName}-${uid} .comparison-card{
        min-height:100%;
        min-width:100%;
      }
      
      .${componentName}-${uid} .comp-row{ 
        border-radius:1rem;
      }
     
     
      .terms{ 
        text-decoration:none;
        font-size:.7rem;
        color:#121c4e;
        font-weight:600;
        margin:0 auto;
        padding:0 2rem;
      }
      
      .${componentName}-${uid} ul{

        list-style-type:none;
        text-indent: -1.7em;
       }
      .${componentName}-${uid} .middle-col-features ul{ 
        padding:1rem 0 0 3.5rem ;
        
      }
      
      .${componentName}-${uid} .right-col-features ul{ 
        padding:1rem 0 0 1.5rem ;
      }
      
      .${componentName}-${uid} .middle-col-features li{ 
        line-height:2.2rem;

      }
      
      .${componentName}-${uid} .middle-col-features li::before{ 
        content:url(${blueCheckMark});
        margin-right:.5rem;

      }
      
      .${componentName}-${uid} .right-col-features li::before{ 
        content:url(${pinkX});
        margin-right:.5rem;

      }






      .${componentName}-${uid} .middle-col{ 
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
   
        align-items:center;     
      }

      .${componentName}-${uid} .middle-col, .${componentName}-${uid} .middle-col-features{
        width:60%;
        min-height:100%;
      }

      @media (min-width:768px){
        .${componentName}-${uid} .middle-col, .${componentName}-${uid} .middle-col-features{
          width:40%;
          min-height:100%;
        }
      } 
      
      



      .${componentName}-${uid} .left-col, .${componentName}-${uid} .right-col {
        margin-top:1.3rem
      }


      .${componentName}-${uid} .left-col{
        border-top-left-radius:1rem;
      }

      .${componentName}-${uid} .left-col-features,
      .${componentName}-${uid} .right-col-features,
      { 
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem; 

      }
      
      .${componentName}-${uid} .left-col, .${componentName}-${uid} .left-col-features{
        width:30%;
        min-height:100%;
        border-bottom:1px solid lightgray;
      }
      
      .${componentName}-${uid} .right-col, .${componentName}-${uid} .right-col-features{
        border-right:1px solid black;
      }

      .${componentName}-${uid} .right-col{
        border-top:1px solid black;
        border-top-right-radius:1rem;
      } 
      
      .${componentName}-${uid} .right-col p{
          font-family: Specter_ultra, Arial, sans-serif;
      } 
      
      .${componentName}-${uid} .right-col, .${componentName}-${uid} .right-col-features {
        width:40%;
        min-height:100%;
        border-bottom:1px solid lightgray;

        }

      @media (min-width:768px){
          .${componentName}-${uid} .right-col, 
          .${componentName}-${uid} .right-col-features {
            width:30%;
            min-height:100%;
          }
        }  

      .${componentName}-${uid} .middle-col-features, .${componentName}-${uid} .middle-col { 
        border-left:2px solid #4300D5;
        border-right:2px solid #4300D5;
        box-shadow: 0 -9px 0px 0px rgba(255, 255, 255,.00001), 0 -9px 0px 0px rgba(255, 255, 255,.00001), 12px 0 15px -4px rgba(0, 0, 0, 0.1), -12px 0 15px -4px rgba(0, 0, 0, 0.1);
        border-bottom:1px solid lightgray;

      }


      .left-col, .left-col-features, .right-col, .right-col-features{
        box-shadow: 0 -9px 0px 0px rgba(255, 255, 255,.00001), 0 -9px 0px 0px rgba(255, 255, 255,.00001), 12px 0 15px -4px rgba(0, 0, 0, 0.1), -12px 0 15px -4px rgba(0, 0, 0, 0.1);
      }

      
      /*
      .left-col, .left-col-features, .right-col, .right-col-features{
        box-shadow: -60px 0 100px -90px #000000, 60px 0px 100px -90px #000000;
      }
      */


      @media (max-width: 512px){
          .${componentName}-${uid} .h4 {
          font-size:16px;
          }
        }

    `}
      </style>
      <Container
        data-source-id={dataSource}
        data-component={componentName}
        data-uid={uid}
        fluid
        className={`text-center ${componentName} ${componentName}-${uid}`}
      >
        <Container>
          <RichText tag="h3" className="font48 d-xs-none" field={fields.Title} />

          {(fields.SubTitle.value !== '' || null) && (
            <RichText className="p d-xs-none" field={fields.SubTitle} />
          )}
        </Container>

        <div className="mx-auto text-left comp-row width810 px-0 d-flex flex-row flex-wrap justify-content-center align-content-end">
          <div className="d-xs-none d-sm-block left-col"></div>
          <div className="text-center bg-purple-4300D5 middle-col py-20">
            <RichText field={fields.RollColumnHeading} />
          </div>
          <RichText
            className="text-center align-middle right-col"
            field={fields.CompetitionColumnHeading}
          />

          {
            // Loop
          }
          {fields.ComparisonList.map((i, k) => (
            <Fragment key={k}>
              <div className="d-xs-none d-sm-block  left-col-features text-left">
                <div className="comparison-card">
                  <strong>
                    <RichText className="rich-text" field={i.fields.Title} />
                  </strong>
                </div>
              </div>
              <div className="m-0 middle-col-features">
                <div className="comparison-card">
                  <div className="d-xs-block d-sm-none text-center pt-25 ">
                    <strong>
                      <RichText className="rich-text py-0 my-0" field={i.fields.Title} />
                    </strong>
                  </div>
                  <RichText
                    className="mx-auto middle-rich-text"
                    field={i.fields.LeftColumnListContent}
                  />
                </div>
              </div>
              <div className=" right-col-features text-center">
                <div className="comparison-card">
                  <RichText field={i.fields.RightColumnListContent} />
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </Container>
    </Fragment>,
  ];
};

export default withSitecoreContext()(CompetitionComparisonListComponent);
