import React from 'react';
import { Text, Image, withSitecoreContext, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Picture from '../../lib/Picture';

const PartnerFooter = ({ fields, sitecoreContext }) => {
  if (!fields || !fields?.data || !fields?.data?.footer) {
    return null;
  }
  const footer = fields.data.footer;
  return (
    <Container
      fluid
      className="partnet-footer-component bg-purple-4300D5 pt-40 pt-xs-20"
      data-source-title="footer"
    >
      <footer role="contentinfo">
        <Row as={'nav'} className="center-block " role="navigation">
          <Row className="center-block d-xs-flex flex-xs-column-reverse pb-sm-80 pb-xs-30">
            <Col xs={12} sm={9} className="col-xs-12 px-xs-0">
              <Col xs={12} sm={3} className="col-xs-12 p-0">
                <Link
                  field={footer.logoLink.jss}
                  className="mx-sm-auto mx-xs-auto mx-md-0 mb-xs-24"
                >
                  <Picture className="img-logo width100" field={footer.logo.jss} loading="lazy" />
                </Link>
              </Col>
              <Col xs={12} sm={9} className="px-xs-0" />
            </Col>
            <Col xs={12} sm={3} className="col-xs-12 px-sm-0 mb-xs-50">
              <Col xs={6} className="col-xs-6">
                <Link field={sitecoreContext.pricing.sitecore.route.fields.AppleAppStoreLink}>
                  <Picture
                    field={footer.appleAppStoreImage.jss}
                    className="img-responsive hidden-xs"
                    loading="lazy"
                  />
                  <Picture
                    field={footer.appleAppStoreImage.jss}
                    className="img-responsive visible-xs pr-10 lazy"
                    loading="lazy"
                  />
                </Link>
              </Col>
              <Col xs={6} className="col-xs-6">
                <Link field={sitecoreContext.pricing.sitecore.route.fields.GoogleAppStoreLink}>
                  <Picture
                    field={footer.googlePlayStoreImage.jss}
                    className="img-responsive hidden-xs"
                    loading="lazy"
                  />
                  <Picture
                    field={footer.googlePlayStoreImage.jss}
                    className="img-responsive visible-xs lazy"
                    loading="lazy"
                  />
                </Link>
              </Col>
            </Col>
          </Row>
          <Row className="center-block d-xs-flex flex-xs-column-reverse">
            <Col xs={12} sm={8} className="white text-xs-center px-xs-15 size15">
              <Text field={footer.copyright.jss} />
            </Col>
            <Col xs={12} sm={4} className="white text-right text-xs-center pb-xs-30 px-xs-0">
              <ul className="legal-links white d-inline px-xs-0 size15">
                {footer.footerLinks.targetItem.children.map((i, k) => {
                  return (
                    <li key={k}>
                      <Link field={i.link.jss}>{i.link.jss.value.text}</Link>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Row>
      </footer>
    </Container>
  );
};

export default withSitecoreContext()(PartnerFooter);
